import {Typography} from "@mui/material";
import Box from "@mui/material/Box";
import React from "react";
import {useApplicationSuiteContext} from "../../../contexts/ApplicationSuiteProvider";
import {useCollectionData} from "react-firebase-hooks/firestore";
import {collection, query, where} from "firebase/firestore";
import {firebase_firestore} from "../../../common/firebaseConfig";
import ReportCardList from "../../../components/backtests/ReportCardList";
import {BookmarkAdded} from "@mui/icons-material";

function FavoriteBacktestsView() {
    const {
        usermeta,
        tradingHouse
    } = useApplicationSuiteContext();

    const [backtests] = useCollectionData(
        query(
            collection(firebase_firestore, `usermeta/${usermeta.gcid}/savedShowcase`),
            where("backtestSettings.tradingHouse", "==", tradingHouse),
        )
    );

    return <>
        <Box>
            <Box display={'flex'} alignItems={'center'} gap={1} color="white" px={1}>
                <BookmarkAdded fontSize={'medium'}/>
                <Typography variant={'h6'} >
                    Saved Backtests <Typography variant="body2" color="white" sx={{display: 'inline'}}>({backtests?.length || 0})</Typography>
                </Typography>
            </Box>
        </Box>

        <Box display={'flex'} flexDirection={'column'}
             px={.5}
             sx={{
                 overflowY: 'auto',
             }}>
            {!!(backtests && backtests.length) && <ReportCardList backtests={backtests} condensed={true}/>}
            {!(backtests && backtests.length) && <Typography variant="body2" color="white" textAlign={'center'}>No saved backtests</Typography>}
        </Box>
    </>

}

export default FavoriteBacktestsView;
