import BuildArea from "../BuildArea";
import Application from "../../../layout/Application";
import {PatternTreeProvider} from "../contexts/PatternTreeContext";
import {PatternProvider} from "../contexts/PatternContext";
import PatternBuilderSidebar from "./PatternBuilderSidebar";
import Box from "@mui/material/Box";

function PatternBuilderApp() {
    return <>
        <Box
            sx={{
                width: '100%',
                height: '100%',
                overflow: 'auto'
            }}
        >
            <BuildArea/>
        </Box>

    </>
}

const App = ({tab}: any) => <Application AppComponent={PatternBuilderApp} SidebarComponent={PatternBuilderSidebar}  ContextProviders={[ PatternTreeProvider, PatternProvider]} tab={tab}/>;
export default App;
