import React from "react";
import ActionChip from "./ActionChip";
import VideoSettingsIcon from "@mui/icons-material/VideoSettings";
import LockIcon from "@mui/icons-material/Lock";
import {Tabs} from "../../layout/MainLayout";
import {useApplicationSuiteContext} from "../../contexts/ApplicationSuiteProvider";
import useRecentlyViewedBacktests from "../../hooks/useRecentlyViewedBacktests";
import UpgradeDialog from "../UpgradeDialog";

function ViewBacktestSettingsActionChip({ onClick, backtest, ...otherProps }: any) {
    const {
        usermeta,
        setActiveTab,
        setBacktestSettingInputData,
        canShowcaseViewBacktestSettings,
    } = useApplicationSuiteContext();

    const [, setRecentlyViewedBacktests] = useRecentlyViewedBacktests();

    const [upgradeDialogOpen, setUpgradeDialogOpen] = React.useState(false);

    function traderOwnsBacktest() {
        return backtest.gcid === usermeta.gcid;
    }

    const locked = !canShowcaseViewBacktestSettings && !traderOwnsBacktest();

    function goToBacktester(event: any) {
        event.stopPropagation();

        if (locked) {
            setUpgradeDialogOpen(true);
        } else {
            if (!backtest.backtestSettings.gcid) {
                backtest.backtestSettings.gcid = backtest.gcid;
            }

            if (backtest.id) {
                setRecentlyViewedBacktests(backtest);
            }

            setBacktestSettingInputData(backtest.backtestSettings);
            setActiveTab(Tabs.Backtest);
        }

        if (onClick) {
            onClick();
        }

    }

    return (
        <>
            <ActionChip
                label="View Backtest Settings"
                icon={<VideoSettingsIcon />}
                hoveringIcon={locked && <LockIcon color={'warning'}/>}
                goToWidth="190px"
                onClick={goToBacktester}
                color={locked ? 'lightgray' : 'primary'}
                {...otherProps}
            />
            {upgradeDialogOpen && <UpgradeDialog open={upgradeDialogOpen} onClose={() => setUpgradeDialogOpen(false)} />}
        </>

    );
}

export default ViewBacktestSettingsActionChip;
