import React, { useEffect, useState } from "react";
import { Box, MenuItem, Select, TextField, Typography } from "@mui/material";
import FilterComponentParams from "./types/FilterComponentParams";
import {useTheme} from "@mui/material/styles";

function TriggerChartFilter(props: FilterComponentParams) {
    const theme = useTheme();
    const { backtestFilters, setBacktestFilters } = props;
    const [localTriggerChart, setLocalTriggerChart] = useState(backtestFilters.triggerChart || {timeframe: null, periodicity: null});

    useEffect(() => {
        setLocalTriggerChart(backtestFilters.triggerChart ||  {timeframe: null, periodicity: null});
    }, [backtestFilters.triggerChart]);

    const onValueChanges = (key: string, value?: any) => {

        const newTriggerChart = {
            ...localTriggerChart,
            [key]: value,
        };

        setLocalTriggerChart(newTriggerChart);

        let allKeysHaveValue = true;
        for (const keyInTriggerChart in localTriggerChart) {
            if (!localTriggerChart[keyInTriggerChart] && keyInTriggerChart !== key) {
                allKeysHaveValue = false;
            }
        }

        if (allKeysHaveValue) {
            setBacktestFilters((prev: any) => ({
                ...prev,
                'triggerChart': newTriggerChart,
            }));
        }
    }

    return (
        <Box p={2}>
            <Typography variant="body1" color={"textPrimary"} fontWeight={'bold'}>
                Trigger Chart
            </Typography>
            <Box display="flex" justifyContent="space-between" my={1}>
                <Typography variant="body1" color={"textSecondary"}>Periodicity</Typography>
                <Typography variant="body1" color={"textSecondary"}>Timeframe</Typography>
            </Box>

            <Box display="flex" alignItems="center" justifyContent="space-between" gap={2}>
                <Box flex={1}>
                    <TextField
                        type="number"
                        value={localTriggerChart?.periodicity ?? ''}
                        placeholder="No Periodicity"
                        onChange={(e) => {
                            const val = e.target.value !== '' ? Number(e.target.value) : undefined;
                            if (val && val >= 1) {
                                onValueChanges('periodicity', val);
                            }
                        }}
                        fullWidth
                        InputProps={{
                            inputProps: { min: 1 }
                        }}
                    />
                </Box>

                <Typography color="textPrimary">-</Typography>

                <Box flex={1}>
                    <Select
                        fullWidth
                        value={localTriggerChart?.timeframe ?? ''}
                        onChange={(e) => {
                            const newTimeframe = e.target.value === "" ? null : e.target.value;
                            onValueChanges('timeframe', newTimeframe);
                        }}
                        displayEmpty
                        sx={{
                            color: localTriggerChart?.timeframe ? 'textPrimary' : theme.palette.text.disabled
                        }}
                    >
                        <MenuItem value="">No Timeframe</MenuItem>
                        <MenuItem value="minute">Minute</MenuItem>
                        <MenuItem value="hour">Hour</MenuItem>
                    </Select>
                </Box>

            </Box>
        </Box>
    );
}

export default TriggerChartFilter;
