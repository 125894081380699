import React from 'react';
import Box from '@mui/material/Box';
import Application from './Application';
import { useApplicationSuiteContext } from '../contexts/ApplicationSuiteProvider';
import BacktestApp from '../app/backtest/app/BacktesterApp';
import PatternBuilderApp from '../app/builder/app/PatternBuilderApp';
import BacktestReportApp from '../app/reports/app/BacktestReportApp';
import TrainingApp from '../app/training/app/TrainingApp';
import ShowcaseApp from '../app/showcase/app/ShowcaseApp';
import './MainLayout.css';
import Dashboard from "../app/dashboard/app/Dashboard";
import SideNavigation from "./SideNavigation";
import {useTheme} from "@mui/material/styles";

export enum Tabs {
    PatternBuilder = 0,
    Backtest = 1,
    BacktestReport = 2,
    Training = 3,
    Showcase = 4,
    Dashboard = 5,
}

function MainLayout() {
    const theme = useTheme();

    const {
        activeTab,
    } = useApplicationSuiteContext();

    const tabComponents = {
        [Tabs.PatternBuilder]: <PatternBuilderApp tab={Tabs.PatternBuilder} />,
        [Tabs.Backtest]: <BacktestApp tab={Tabs.Backtest} />,
        [Tabs.BacktestReport]: <BacktestReportApp tab={Tabs.BacktestReport} />,
        [Tabs.Training]: <TrainingApp tab={Tabs.Training} />,
        [Tabs.Showcase]: <ShowcaseApp tab={Tabs.Showcase} />,
        [Tabs.Dashboard]: <Dashboard tab={Tabs.Dashboard} />,
    };

    return (
        <>

            <Box className="mainLayout" sx={{backgroundColor: theme.palette.background.custom1}}>
                <SideNavigation/>

                <Box sx={{width: '100%', height: "100%"}} pl={1}>
                    {Object.entries(tabComponents).map(([tabIndex, component]) => (
                        <Box key={tabIndex} className={activeTab === parseInt(tabIndex) ? 'activeTab' : 'inactiveTab'}>
                            {component}
                        </Box>
                    ))}
                    {/* Fallback for any other tab */}
                    {!(activeTab in tabComponents) && (
                        <Box className="activeTab">
                            <Application AppComponent={Box} />
                        </Box>
                    )}
                </Box>
            </Box>

        </>
    );
}

export default MainLayout;
