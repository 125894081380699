import React from "react";
import { Box, Typography } from "@mui/material";
import TextFieldSelectInput from "./components/TextFieldSelectInput";
import FilterComponentParams from "./types/FilterComponentParams";

function AbsoluteDrawdownFilter(props: FilterComponentParams) {

    const {
        backtestFilters,
        setBacktestFilters
    } = props;

    const getPredefinedValues = (boundaryValue: number, boundaryType: 'max' | 'min') => {
        const baseValue = boundaryType === 'max' ? Number.MAX_SAFE_INTEGER : Number.MIN_SAFE_INTEGER;
        const oppositeBoundaryValue = boundaryType === 'max' ? Number.MIN_SAFE_INTEGER : Number.MAX_SAFE_INTEGER;
        const baseLabel = boundaryType === 'max' ? 'No Max' : 'No Min';

        const values: Array<{ label: string, value: number }> = [
            { label: baseLabel, value: baseValue },
        ];

        if (boundaryValue === oppositeBoundaryValue) {
            values.push(
                { label: "0%", value: 0 },
                { label: "20%", value: 20 },
                { label: "40%", value: 40 },
                { label: "60%", value: 60 },
                { label: "80%", value: 80 },
                { label: "100%", value: 100 }
            )
        } else {
            const roundUpToNearest20 = (value: number) => {
                return Math.ceil(value / 20) * 20;
            }

            const roundDownToNearest20 = (value: number) => {
                return Math.floor(value / 20) * 20;
            }

            const roundingFunction = boundaryType === 'max' ? roundUpToNearest20 : roundDownToNearest20;
            const increment = boundaryType === 'max' ? 20 : -20;
            const endValue = boundaryType === 'max' ? 100 : 0;

            const nextValue = roundingFunction(boundaryValue);

            // starting at the boundary value, increment until the end value, inclusive
            for (let i = nextValue; i !== endValue + increment; i += increment) {
                values.push({ label: `${i}%`, value: i });
            }
        }

        return values.sort((a, b) => a.value - b.value);

    }

    // const handleApply = () => {
        // setBacktestFilters((prev: any) => ({
        //     ...prev,
        //     minAbsoluteDrawdownPercentage: backtestFilters.minAbsoluteDrawdownPercentage,
        //     maxAbsoluteDrawdownPercentage: backtestFilters.maxAbsoluteDrawdownPercentage,
        //     minRelativeDrawdownPercentage: backtestFilters.minRelativeDrawdownPercentage,
        //     maxRelativeDrawdownPercentage: backtestFilters.maxRelativeDrawdownPercentage
        // }));
        // handleClose();
    // };

    const onValueChanges = (key: string, value?: number) => {
        setBacktestFilters((prev: any) => ({
            ...prev,
            [key]: value
        }));
    }


    return (
        <>
            <Box p={2}>
                <Typography variant="body1" color={"textPrimary"} fontWeight={'bold'}>
                    Absolute Drawdown %
                </Typography>

                <Box display="flex" justifyContent="space-between" my={1}>
                    <Typography variant="body1" color={"textSecondary"}>Minimum</Typography>
                    <Typography variant="body1" color={"textSecondary"}>Maximum</Typography>
                </Box>

                <Box display="flex" alignItems="center" justifyContent="space-between" gap={2}>
                    <Box flexGrow={1}>
                        <TextFieldSelectInput
                            value={backtestFilters.minAbsoluteDrawdownPercentage === Number.MIN_SAFE_INTEGER ? '' : backtestFilters.minAbsoluteDrawdownPercentage}
                            type={'percentage'}
                            placeholder={'No Min'}
                            onChange={(val) => onValueChanges('minAbsoluteDrawdownPercentage', val)}
                            predefinedValues={getPredefinedValues(backtestFilters.maxAbsoluteDrawdownPercentage, 'min')}
                        />
                    </Box>

                    <Typography color={"textPrimary"}>-</Typography>

                    <Box flexGrow={1}>
                        <TextFieldSelectInput
                            value={backtestFilters.maxAbsoluteDrawdownPercentage === Number.MAX_SAFE_INTEGER ? '' : backtestFilters.maxAbsoluteDrawdownPercentage}
                            type={'percentage'}
                            placeholder={'No Max'}
                            onChange={(val) => onValueChanges('maxAbsoluteDrawdownPercentage', val)}
                            predefinedValues={getPredefinedValues(backtestFilters.minAbsoluteDrawdownPercentage, 'max')}
                        />
                    </Box>
                </Box>
            </Box>
        </>
    );
}

export default AbsoluteDrawdownFilter;
