//  !!! DUPLICATION WARNING !!!
//  The files within the symbolSelectDialog are duplicated between
//  gc_chartiq AND pattern-builder
//  When updating this file, the other should be updated as well

import React, {useState, useImperativeHandle, forwardRef, useEffect} from 'react';
import SymbolSelect, {ISymbol} from './SymbolSelect';
import useCallerFilterSymbols from "./hooks/manager/useCallerFilterSymbols";
import useRecentlySelectedSymbols from "./hooks/manager/useRecentlySelectedSymbols";
import {callerTitlesMap} from "./maps/callerTitlesMap";
import {callerFooterMap} from "./maps/callerFooterMap";

// Define the props for SymbolSelectManager
export interface ISymbolSelectManagerProps {
    caller: CallerType;
    symbolSelectCallback: (symbol: { name: string; type: string }) => void;
    symbols: ISymbol[],
    initialOpenState?: boolean;
}

export interface ISymbolSelectManagerRef {
    openDialog: () => void;
}

export type CallerType = 'lotSizeCalculator' | 'patternAlerts' | 'navigationBar' | 'tradingSimulator' | 'symbolSelect';

// Manages the symbol select dialog
// There are three levels of filtering
// 1. filter by who called the dialog
// 2. Type filter
// 3. Search filter
const SymbolSelectManager = forwardRef<ISymbolSelectManagerRef, ISymbolSelectManagerProps>(
    ({ caller, symbolSelectCallback, symbols, initialOpenState = false }, ref) => {
        // first filter, based on who called the dialog
        const getFilteredSymbols = useCallerFilterSymbols();
        let filteredSymbols = getFilteredSymbols(symbols, caller);
        const [isSymbolSelectOpen, setIsSymbolSelectOpen] = useState(initialOpenState);

        const { addRecentSymbol, recentSymbols } = useRecentlySelectedSymbols();

        const recreateContainer = () => {
            const container = document.getElementById('symbol-select-container');
            if (container) {
                // Remove the existing container
                container.parentNode?.removeChild(container);
                // Create a new container element
                const newContainer = document.createElement('div');
                newContainer.id = 'symbol-select-container';
                // Insert the new container at the same location
                document.body.appendChild(newContainer);
            }
        };

        useEffect(() => {
            if (!isSymbolSelectOpen) {
                recreateContainer();
            }
        }, [isSymbolSelectOpen]);

        const toggleSymbolSelectDialog = () => {
            setIsSymbolSelectOpen(!isSymbolSelectOpen);
        };

        const handleSymbolSelect = (selectedSymbol: any) => {
            symbolSelectCallback(selectedSymbol);
            addRecentSymbol(selectedSymbol);
            toggleSymbolSelectDialog(); // Close the dialog after selection
        };

        useImperativeHandle(ref, () => ({
            openDialog: () => {
                setIsSymbolSelectOpen(true);
                filteredSymbols = getFilteredSymbols(symbols, caller);
            }
        }));

        const extractTypes = (totalSymbols: any) => {
            const types = new Set(totalSymbols.map((symbol: any) => symbol.type));
            return ['All', ...Array.from(types)];
        };

        const types: any[] = extractTypes(symbols);
        const filteredTypes: any[] = extractTypes(filteredSymbols);
        return (
                <SymbolSelect
                    symbols={filteredSymbols}
                    onSymbolSelect={handleSymbolSelect}
                    types={types}
                    filteredTypes={filteredTypes}
                    isOpen={isSymbolSelectOpen}
                    toggleDialog={toggleSymbolSelectDialog}
                    recentSymbols={recentSymbols}
                    callerTitle={callerTitlesMap[caller]}
                    optionalFooter={callerFooterMap[caller]}
                />
        );
    });

export default SymbolSelectManager;
