import React from "react";
import { Box, Typography } from "@mui/material";
import {AdapterDayjs} from "@mui/x-date-pickers/AdapterDayjs";
import {DesktopDatePicker} from "@mui/x-date-pickers/DesktopDatePicker";
import dayjs from "dayjs";
import {LocalizationProvider} from "@mui/x-date-pickers/LocalizationProvider";
import FilterComponentParams from "./types/FilterComponentParams";

function DateRangeFilter(props: FilterComponentParams) {
    const {
        backtestFilters,
        setBacktestFilters
    } = props;

    // const handleApply = () => {
        // setBacktestFilters((prev: any) => ({
        //     ...prev,
        //     startDate: backtestFilters.minDateRange,
        //     endDate: backtestFilters.maxDateRange,
        // }));
        // handleClose();
    // };

    const onValueChanges = (key: string, value?: number) => {
        setBacktestFilters((prev: any) => ({
            ...prev,
            [key]: value
        }));
    }

    return (
        <>
            <Box p={2}>
                <Typography variant="body1" color={"textPrimary"} fontWeight={'bold'}>
                    Backtested Date Range
                </Typography>
                <Box display="flex" justifyContent="space-between" my={1}>
                    <Typography variant="body1" color={"textPrimary"}>Minimum</Typography>
                    <Typography variant="body1" color={"textPrimary"}>Maximum</Typography>
                </Box>

                <Box display="flex" alignItems="center" justifyContent="space-between" gap={2}>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DesktopDatePicker
                            sx={{
                                width: "100%",
                                height: 40,
                                '& .MuiInputBase-root': {height: 40},
                                '& .MuiInputBase-input': {color: 'rgba(255, 255, 255, 0.7)'},
                                '& .MuiOutlinedInput-notchedOutline': {borderColor: 'rgba(255, 255, 255, 0.23) !important'}
                            }}
                            slotProps={{
                                field: { clearable: true},
                            }}
                            value={dayjs(new Date(backtestFilters.minDateRange))}
                            maxDate={dayjs()}
                            onChange={(value: any) => onValueChanges("minDateRange", value?.$d?.getTime() ?? Number.MIN_SAFE_INTEGER)}
                        />

                    </LocalizationProvider>

                    <Typography color={"textPrimary"}>-</Typography>

                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DesktopDatePicker
                            sx={{
                                width: "100%",
                                height: 40,
                                '& .MuiInputBase-root': {height: 40},
                                '& .MuiInputBase-input': {color: 'rgba(255, 255, 255, 0.7)'},
                                '& .MuiOutlinedInput-notchedOutline': {borderColor: 'rgba(255, 255, 255, 0.23) !important'}
                            }}
                            slotProps={{
                                field: { clearable: true},
                            }}
                            value={dayjs(new Date(backtestFilters.maxDateRange))}
                            maxDate={dayjs()}
                            onChange={(value: any) => onValueChanges("maxDateRange", value?.$d?.getTime() ?? Number.MAX_SAFE_INTEGER)}
                        />

                    </LocalizationProvider>
                </Box>
            </Box>
        </>
    );
}

export default DateRangeFilter;
