import React, {useContext, useRef, useState} from "react";
import {Box, IconButton, Typography, Button} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";
import {AdapterDayjs} from "@mui/x-date-pickers/AdapterDayjs";
import {TimePicker} from "@mui/x-date-pickers";
import dayjs from "dayjs";
import {LocalizationProvider} from "@mui/x-date-pickers/LocalizationProvider";
import customParseFormat from 'dayjs/plugin/customParseFormat';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import {GreenChartTooltip} from "../../common/components/GreenChartTooltip";
import {SnackbarHelperContext} from "../../contexts/SnackbarHelperContext";
import {useApplicationSuiteContext} from "../../contexts/ApplicationSuiteProvider";

dayjs.extend(customParseFormat);
dayjs.extend(utc);
dayjs.extend(timezone);

type TimeRangeProps = {
    startTimeRange: string;
    endTimeRange: string;
    allowableCount?: number;
}

function TimeRangeSelect({ timeRanges, onTimeRangeChange }: { timeRanges: TimeRangeProps[], onTimeRangeChange: (timeRanges: TimeRangeProps[]) => void }) {
    const {
        canEditAlerts
    } = useApplicationSuiteContext()

    const addTimeRange = () => {
        if (timeRanges.length < 3) {
            const defaultTimeRange = new Date()
            defaultTimeRange.setHours(24, 0)
            const defaultTime = `${("0"+defaultTimeRange.getUTCHours()).slice(-2)}:${("0"+defaultTimeRange.getUTCMinutes()).slice(-2)}`
            onTimeRangeChange([...timeRanges, { startTimeRange: defaultTime, endTimeRange: defaultTime }]);
        }
    };

    const removeTimeRange = (index: number) => {
        const newRanges = timeRanges.filter((_, i) => i !== index);
        onTimeRangeChange(newRanges);
    };

    const handleTimeRangeChange = (index: number, timeRange: TimeRangeProps) => {
        const newRanges = [...timeRanges];
        newRanges[index] = timeRange;
        onTimeRangeChange(newRanges);
    }

    return (
        <Box id="notificationRanges">
            <Box display="flex" flexDirection="column" gap={1}>


                {timeRanges.map((timeRange, index) =>
                    <Box key={index} display={"flex"} gap={1} alignItems={'center'}>
                        {timeRanges.length > 1 && (
                            <Box>
                                <IconButton
                                    onClick={() => removeTimeRange(index)}
                                    disabled={!canEditAlerts}
                                    size="small"
                                    color="error"
                                >
                                    <RemoveIcon />
                                </IconButton>
                            </Box>
                        )}
                        <TimeRangeInput timeRange={timeRange} onTimeRangeChange={(timeRange) => handleTimeRangeChange(index, timeRange)} />
                    </Box>
                )}

                {timeRanges.length < 3 && (
                    <Button
                        fullWidth
                        variant="outlined"
                        color="primary"
                        onClick={addTimeRange}
                        startIcon={<AddIcon />}
                        disabled={!canEditAlerts}
                        sx={{ alignSelf: "flex-start", height: "40px" }}
                    />
                )}
            </Box>
        </Box>
    );
}


function TimeRangeInput({ timeRange, onTimeRangeChange }: { timeRange: TimeRangeProps, onTimeRangeChange: (timeRange: TimeRangeProps) => void }) {
    const {
        canEditAlerts
    } = useApplicationSuiteContext()

    const { setSnackbarSettings } = useContext(SnackbarHelperContext);

    const [pickerKey, setPickerKey] = useState<number>(Date.now());
    const [startTempTime, setStartTempTime] = useState<any>(dayjs.utc(timeRange.startTimeRange, "HH:mm").local());
    const startTempTimeRef = useRef(startTempTime);
    const [endTempTime, setEndTempTime] = useState<any>(dayjs.utc(timeRange.endTimeRange, "HH:mm").local());
    const endTempTimeRef = useRef(endTempTime);

    function handleTimeChange() {
        setPickerKey(Date.now());

        if (!startTempTimeRef.current || !endTempTimeRef.current) {
            return;
        }

        let currentStart = dayjs(startTempTimeRef.current);
        let currentEnd = dayjs(endTempTimeRef.current);

        if (currentStart.isAfter(currentEnd)) {
            currentEnd = currentStart;
            setSnackbarSettings({severity: 'warning', message: "Start time cannot be after end time.", autoHideDuration: 6000})
        } else if (currentEnd.isBefore(currentStart)) {
            currentStart = currentEnd;
            setSnackbarSettings({severity: 'warning', message: "End time cannot be before start time.", autoHideDuration: 6000})
        }

        const startTime = dayjs(currentStart).utc().format("HH:mm");
        const endTime = dayjs(currentEnd).utc().format("HH:mm");
        onTimeRangeChange({ ...timeRange, startTimeRange: startTime, endTimeRange: endTime });
    }

    return <>
        <Box display="flex" alignItems="center" gap={1} width={'100%'}>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
                <TimePicker
                    key={pickerKey}
                    sx={{width: "100%",
                        "& .MuiInputBase-root": {
                            height: "40px"
                        }
                    }}
                    label="Start Time"
                    value={dayjs.utc(timeRange.startTimeRange, "HH:mm").local()}
                    onSelectedSectionsChange={(value: any) => value ?? handleTimeChange()}
                    onChange={(value: any) => {
                        const newValue = value.valueOf();
                        setStartTempTime(newValue);
                        startTempTimeRef.current = newValue;
                    }}
                    onClose={() => handleTimeChange()}
                    disabled={!canEditAlerts}
                />
            </LocalizationProvider>
            {
                timeRange.startTimeRange === timeRange.endTimeRange && <Typography variant="caption">All day</Typography>
            }
            <LocalizationProvider dateAdapter={AdapterDayjs}>
                <TimePicker
                    sx={{
                        width: "100%",
                        "& .MuiInputBase-root": {
                            height: "40px"
                        }
                    }}
                    key={pickerKey}
                    label="End Time"
                    value={dayjs.utc(timeRange.endTimeRange, "HH:mm").local()}
                    onSelectedSectionsChange={(value: any) => value ?? handleTimeChange()}
                    onChange={(value: any) => {
                        const newValue = value.valueOf();
                        setEndTempTime(newValue);
                        endTempTimeRef.current = newValue;
                    }}
                    onClose={() => handleTimeChange()}
                    disabled={!canEditAlerts}
                />
            </LocalizationProvider>
            {
                timeRange.allowableCount && (
                    <Box sx={{ width: "40px", textAlign: "center" }}>
                        <GreenChartTooltip title={`${timeRange.allowableCount} notification allowed within time range`} arrow placement={'bottom-end'}>
                            <Typography sx={{ width: "40px", textAlign: "center" }}>
                                {timeRange.allowableCount}
                            </Typography>
                        </GreenChartTooltip>
                    </Box>
                )
            }
        </Box>
    </>
}

export default TimeRangeSelect;
