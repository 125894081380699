import {useApplicationSuiteContext} from "../contexts/ApplicationSuiteProvider";
import useLocalStorage from "./useLocalStorage";

function useRecentlyViewedBacktests() {

    const {
        usermeta,
        tradingHouse,
    } = useApplicationSuiteContext();

    const key = `recentlyViewedBacktests_${tradingHouse}_${usermeta.gcid}`;

    const [backtests, setBacktests] = useLocalStorage<any>(key, []);

    function addBacktest(backtest: any) {
        if (backtests) {
            // if already exists in list, move it to the front
            const index = backtests.findIndex((b: any) => b.backtest.id === backtest.id);
            if (index > -1) {
                backtests.splice(index, 1);
            }
            backtests.unshift({
                backtest,
                ttl: Date.now(),
            });

            const newBacktests = backtests
                .filter((b: any) => Date.now() - b.ttl < 1000 * 60 * 60 * 24 * 7)
                .slice(0, 20);

            setBacktests(newBacktests);
        } else {
            setBacktests([{
                backtest,
                ttl: Date.now(),
            }]);
        }
    }

    return [
        backtests.map((b: any) => b.backtest),
        addBacktest,
    ];
}

export default useRecentlyViewedBacktests;
