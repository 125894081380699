import Box from "@mui/material/Box";
import * as React from "react";
import ErrorIcon from "@mui/icons-material/Error";
import {CircularProgress, CircularProgressProps} from "@mui/material";
import Typography from "@mui/material/Typography";
import {forwardRef, useEffect, useRef, useState} from "react";
import {onValue, ref} from "firebase/database";
import AttachMoneyIcon from "@mui/icons-material/AttachMoney";
import MoneyOffIcon from '@mui/icons-material/MoneyOff';
import {useApplicationSuiteContext} from "../../contexts/ApplicationSuiteProvider";
import {firebase_realtime} from "../../common/firebaseConfig";
import {GreenChartTooltip} from "../../common/components/GreenChartTooltip";

function BacktestStatusIcon({backtest}: any) {
    const {
        usermeta,
    } = useApplicationSuiteContext();

    const [percentage, setPercentage] = useState<number>(0);
    const listenerRef: any = useRef(null);

    useEffect(() => {

        if (backtest.status === "running") {
            let backtestDBRef = null;

            const subStatus = backtest.subStatus;

            let basePercentage = 0;
            let rangePercentage = 50;

            if (subStatus === "signals") {
                backtestDBRef = ref(firebase_realtime, `usermeta/${usermeta.gcid}/backtests/${backtest.id}/findingSignalTasks`);
                basePercentage = 0;
            } else if (subStatus === "analyze") {
                backtestDBRef = ref(firebase_realtime, `usermeta/${usermeta.gcid}/backtests/${backtest.id}/analyzingRatioTasks`);
                basePercentage = 50;
            }

            if (backtestDBRef) {
                listenerRef.current = onValue(backtestDBRef, (snapshot) => {
                    const data = snapshot.val();
                    if (data && data.total > 0) {
                        const taskPercentage = ((data.total - data.remaining) / data.total) * 100;
                        const normalizedPercentage = basePercentage + (taskPercentage * rangePercentage / 100);
                        setPercentage(normalizedPercentage);
                    }
                });
            }
        }

        return () => {
            if (listenerRef.current) {
                listenerRef.current();
                listenerRef.current = null; // Clear the ref
            }
        }
    }, [backtest, usermeta.gcid])

    function StatusIcon({backtest, percentage}: any) {
        function getStatusColor(status: string) {
            switch (status) {
                case "running":
                    return "#00A1ff"
                case "complete":
                    return `#008000`
                case "timeout":
                case "error":
                    return `#ffa726`
                default:
            }
        }

        switch (backtest.status) {
            case "running":
                return <CircularProgressWithLabel value={percentage} sx={{ color: getStatusColor(backtest.status)}}  size={"2rem"}/>
            case "complete":
                return <ProfitableIcon backtest={backtest}/>
            case "error":
                return <GreenChartTooltip arrow title={"Error occurred, Contact support@greenchart.com for assistance."}><ErrorIcon fontSize={"small"} sx={{ color: getStatusColor(backtest.status)}}/></GreenChartTooltip>
            default:
                return null
        }
    }

    return <StatusIcon backtest={backtest} percentage={percentage}/>
}


function CircularProgressWithLabel(props: CircularProgressProps & { value: number }) {
    return (
        <Box sx={{ position: 'relative', display: 'inline-flex' }}>
            {/* Background circle for the outline */}
            <CircularProgress
                variant="determinate"
                size={props.size}
                sx={{
                    color: 'transparent',  // Hide the foreground color
                    position: 'absolute',
                    top: 0,
                    left: 0,
                    zIndex: 1, // Keep this behind the main circle
                    '& .MuiCircularProgress-circle': {
                        strokeWidth: '.5', // Set the thickness of the outline
                        stroke: 'lightgrey' // Define the color of the outline
                    }
                }}
                thickness={4} // Adjust thickness to make the outline more visible
                value={100}
            />
            {/* Main progress circle */}
            <CircularProgress
                variant="determinate"
                {...props}
                sx={{
                    '& .MuiCircularProgress-circle': {
                        strokeWidth: '3.5', // Ensure consistent stroke width
                        stroke: props.color || 'primary', // Customize the progress circle color
                    }
                }}
            />
            <Box
                sx={{
                    top: 0,
                    left: 0,
                    bottom: 0,
                    right: 0,
                    position: 'absolute',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                }}
            >
                <Typography variant="caption" component="div" color="text.secondary">
                    {`${Math.round(props.value)}%`}
                </Typography>
            </Box>
        </Box>
    );
}


function ProfitableIcon({backtest}: any) {


    function isProfitable(profits: any) {

        return profits && profits.length && profits.some((profit: any) => profit > 0);
    }
    function isAllProfitable(profits: any) {
        return profits && profits.length && profits.every((profit: any) => profit > 0);
    }
    function mostAreProfitable(profits: any) {
        return profits && profits.length && profits.filter((profit: any) => profit > 0).length > profits.filter((profit: any) => profit < 0).length;
    }

    const profits = (backtest.summaries || []).map((summary: any) => {
        if (!summary['Net Profit (%)']) {
            return 0;
        }

        let netProfitStr = summary['Net Profit (%)'].replace(/\$/, '').match(/-?[\d,]+(\.\d+)?/);

        if (netProfitStr) {
            netProfitStr = netProfitStr[0].replace(/,/g, '');
            if (netProfitStr) {
                return parseFloat(netProfitStr);
            }
        }

        return 0;
    })

    if (isAllProfitable(profits)) {
        return <GreenChartTooltip arrow title={"All ratios are profitable"}><MoneyIcons qty={3}/></GreenChartTooltip>
    } if (mostAreProfitable(profits)) {
        return <GreenChartTooltip arrow title={"Most ratios are profitable"}><MoneyIcons qty={2}/></GreenChartTooltip>
    } else if (isProfitable(profits)) {
        return <GreenChartTooltip arrow title={"At least 1 profitable ratio"}><MoneyIcons qty={1}/></GreenChartTooltip>
    } else {
        return <GreenChartTooltip arrow title={"Not Profitable"}><MoneyIcons qty={1} negative/></GreenChartTooltip>;
    }
}


const MoneyIcons = forwardRef((props: any, ref) => {
    const { qty, negative, ...otherProps } = props; // Destructure qty and collect other props

    let icons = [];
    for (let i = 0; i < qty; i++) {
        if (negative) {
            icons.push(<MoneyOffIcon key={i} sx={{ left: `${10 * i}px`, position: "absolute" }} />);
        } else {
            icons.push(<AttachMoneyIcon key={i} sx={{ left: `${10 * i}px`, position: "absolute" }} color={"success"} />);
        }
    }

    function calculateIconWidth(qty: number) {
        const iconWidth = 24;
        let totalWidth = 0;

        for (let i = 0; i < qty; i++) {
            totalWidth += iconWidth - (i * 10);
        }

        return totalWidth;
    }

    return (
        <Box ref={ref} position={"relative"} {...otherProps} height={25} display={'flex'} justifyContent={'center'}>
            <Box display={'flex'} position={'relative'} width={calculateIconWidth(qty)} height={'100%'} justifyContent={'center'}>
                {icons}
            </Box>
        </Box>
    );
});

export default BacktestStatusIcon;
