import React, {useEffect, useState} from "react";
import Box from "@mui/material/Box";
import {styled, useTheme} from "@mui/material/styles";
import DashboardIcon from '@mui/icons-material/Dashboard';
import IconButton from "@mui/material/IconButton";
import {Tabs} from "./MainLayout";
import {useApplicationSuiteContext} from "../contexts/ApplicationSuiteProvider";
import KeyboardDoubleArrowRightIcon from '@mui/icons-material/KeyboardDoubleArrowRight';
import KeyboardDoubleArrowLeftIcon from '@mui/icons-material/KeyboardDoubleArrowLeft';
import ConstructionIcon from '@mui/icons-material/Construction';
import VideoSettingsIcon from '@mui/icons-material/VideoSettings';
import SummarizeIcon from '@mui/icons-material/Summarize';
import HolidayVillageIcon from '@mui/icons-material/HolidayVillage';
import {
    FormControl,
    InputLabel,
    MenuItem,
    Select,
    Typography
} from "@mui/material";
import PublicIcon from '@mui/icons-material/Public';
import RunningBacktestsView from "./sideNavigation/runningBacktests/RunningBacktestsView";
import {collection, getCountFromServer, query, where} from "firebase/firestore";
import {firebase_firestore} from "../common/firebaseConfig";
import {NotificationAdd, Help} from "@mui/icons-material";
import FavoriteBacktestsView from "./sideNavigation/favoriteBacktests/FavoriteBacktestsView";
import RecentlyViewedBacktestsView from "./sideNavigation/recentlyViewedBacktests/RecentlyViewedBacktestsView";

function SideNavigation() {

    const theme = useTheme();

    const {
        setActiveTab,
        activeTab,
        tradingHouse,
        canToggleTradingHouse,
        setTradingHouse,
        runningBacktests,
        usermeta,
        setIsCreateAlertModalOpen,
    } = useApplicationSuiteContext();

    const [sideNavigationPanelOpen, setSideNavigationPanelOpen] = useState(false);
    const [hoveredIndex, setHoveredIndex] = useState<any>(null);
    const [hoveringTradingHouse, setHoveringTradingHouse] = useState(false);
    const [showCreateAlertIcon, setShowCreateAlertIcon] = useState(false);
    const [hoveringCreateAlertIcon, setHoveringCreateAlertIcon] = useState(false);

    useEffect(() => {
        getCountFromServer(
            query(
                collection(firebase_firestore, `tradingPlans`),
                where("user.gcid", "==", usermeta.gcid),
            )
        ).then((snapshot: any) => setShowCreateAlertIcon(snapshot.data().count === 0));
    }, [usermeta.gcid]);

    function goToApplication(tab: Tabs) {
        setActiveTab(tab);
    }

    useEffect(() => {
        if (runningBacktests) {
            setSideNavigationPanelOpen(true);
        }
    }, [runningBacktests]);

    function toggleSideNavigationPanel() {
        setSideNavigationPanelOpen(!sideNavigationPanelOpen);
    }

    const handleTradingHouseChange = (e: { target: { value: any; }; }) => {
        const newValue = e.target.value;
        localStorage.setItem("tradingHouse", newValue);
        setTradingHouse(newValue);
        setHoveringTradingHouse(false);
    };

    function openTicketDialog() {
        // @ts-ignore
        window.FreshworksWidget('open')
    }

    const applicationOptions = [
        {
            icon: <DashboardIcon fontSize={'large'}/>,
            label: 'Dashboard',
            description: 'Strategy Suite Overview',
            tab: Tabs.Dashboard
        },
        {
            icon: <ConstructionIcon fontSize={'large'}/>,
            label: 'Pattern Builder',
            description: 'Build a winning strategy',
            tab: Tabs.PatternBuilder
        },
        {
            icon: <VideoSettingsIcon fontSize={'large'}/>,
            label: 'Backtest',
            description: 'Simulate your strategy',
            tab: Tabs.Backtest
        },
        {
            icon: <Box position={'relative'} height={35}>
                <SummarizeIcon fontSize={'large'}/>
                {/*{runningBacktests && <DotIndicator/>}*/}
            </Box>,
            label: `Backtest Reports`,
            description: 'Analyze backtest results',
            tab: Tabs.BacktestReport
        },
        {
            icon: <PublicIcon fontSize={'large'}/>,
            label: 'Showcase',
            description: 'Publicly shared backtests',
            tab: Tabs.Showcase
        },
        // {
        //     icon: <SchoolIcon fontSize={'large'}/>,
        //     label: 'Training',
        //     description: 'Strategy Suite tutorials',
        //     tab: Tabs.Training,
        // }
    ]

    return (
        <Box py={2} pr={sideNavigationPanelOpen ? 1 : 0} sx={{
            backgroundColor: theme.palette.background.custom2,
            borderRadius: `${sideNavigationPanelOpen ? '8px' : '8px 0 0 8px'}`,
        }}>
            <Box height={"100%"} display={"flex"}>
                <Box display={"flex"} flexDirection={"column"} justifyContent={"space-between"} p={1}>
                    <Box display={'flex'} flexDirection={'column'} alignItems={'center'} gap={1}>
                        {applicationOptions.map((option, index) => (
                            <Box
                                key={index}
                                onMouseEnter={() => setHoveredIndex(index)}
                                onMouseLeave={() => setHoveredIndex(null)}
                                display={'flex'}
                                alignItems={'center'}
                                position="relative"
                            >
                                <IconButton
                                    onClick={() => goToApplication(option.tab)}
                                    className={`side-nav-icon ${option.tab === activeTab ? 'active' : ''}`}
                                >
                                    {option.icon}
                                </IconButton>

                                {hoveredIndex === index && (
                                    <Box
                                        position="absolute"
                                        display="flex"
                                        alignItems="center"
                                        justifyContent={'center'}
                                        bgcolor={theme.palette.background.custom4}
                                        height={62}
                                        width={170}
                                        left={'-4px'}
                                        px={2}
                                        pr={1}
                                        pl={9}
                                        borderRadius={2}
                                        boxShadow={3}
                                        zIndex={100}
                                        flexDirection={'column'}
                                    >
                                        <Typography variant="body1" color="textPrimary">
                                            {option.label}
                                        </Typography>
                                        <Typography variant="body2" color="textSecondary">
                                            {option.description}
                                        </Typography>
                                    </Box>
                                )}
                            </Box>
                        ))}
                    </Box>

                    <Box display={"flex"} flexDirection={"column"} alignItems={'center'} gap={1}>
                        {
                            showCreateAlertIcon && (
                                <Box
                                    display={'flex'}
                                    alignItems={'center'}
                                    position="relative"
                                    onMouseEnter={() => setHoveringCreateAlertIcon(true)}
                                    onMouseLeave={() => setHoveringCreateAlertIcon(false)}
                                >
                                    <IconButton
                                        className={'side-nav-icon'}
                                        color={'success'}
                                        onClick={() => setIsCreateAlertModalOpen(true)}
                                    >
                                        <NotificationAdd fontSize="medium" />
                                    </IconButton>

                                    {
                                        hoveringCreateAlertIcon && <Box
                                        position="absolute"
                                        display="flex"
                                        flexDirection={'column'}
                                        alignItems="center"
                                        justifyContent={'center'}
                                        bgcolor={theme.palette.background.custom4}
                                        height={62}
                                        width={130}
                                        left={'-4px'}
                                        px={2}
                                        pr={1}
                                        pl={9}
                                        borderRadius={2}
                                        boxShadow={3}
                                        zIndex={101}
                                      >
                                          <Typography variant="body1" color="textPrimary" textAlign={'center'}>
                                            Setup free Alert
                                          </Typography>
                                      </Box>
                                    }
                                </Box>
                            )
                        }
                        <IconButton className={'side-nav-icon'} onClick={() => openTicketDialog()}>
                            <Help fontSize="medium" />
                        </IconButton>
                        {
                            canToggleTradingHouse && (
                                <Box
                                    display={'flex'}
                                    alignItems={'center'}
                                    position="relative"
                                    onMouseEnter={() => setHoveringTradingHouse(true)}
                                    onMouseLeave={() => setHoveringTradingHouse(false)}
                                >
                                    <IconButton className={'side-nav-icon'}>
                                        <HolidayVillageIcon fontSize="medium" />
                                    </IconButton>

                                    {
                                        hoveringTradingHouse && <Box
                                            position="absolute"
                                            display="flex"
                                            flexDirection={'column'}
                                            alignItems="center"
                                            justifyContent={'center'}
                                            bgcolor={theme.palette.background.custom4}
                                            height={62}
                                            width={130}
                                            left={'-4px'}
                                            px={2}
                                            pr={1}
                                            pl={9}
                                            borderRadius={2}
                                            boxShadow={3}
                                            zIndex={101}
                                        >
                                        <StyledFormControl variant="outlined">
                                          <InputLabel id="symbol">Trading House</InputLabel>
                                          <Select
                                            sx={{height: 40}}
                                            labelId="trading-house-label"
                                            id="trading-house-select"
                                            value={tradingHouse}
                                            onChange={handleTradingHouseChange}
                                            label={"Trading House"}
                                          >
                                            <MenuItem value="greenchart">GreenChart</MenuItem>
                                            <MenuItem value="gtf">GTF</MenuItem>
                                          </Select>
                                        </StyledFormControl>

                                        </Box>
                                    }
                                </Box>
                            )
                        }

                        <Box
                            display={'flex'}
                            alignItems={'center'}
                            position="relative"
                        >
                            <IconButton
                                onClick={() => toggleSideNavigationPanel()}
                                className={'side-nav-icon'}
                            >
                                {
                                    sideNavigationPanelOpen ? <KeyboardDoubleArrowLeftIcon fontSize={'medium'}/> : <KeyboardDoubleArrowRightIcon fontSize={'medium'}/>
                                }
                            </IconButton>

                            {
                                sideNavigationPanelOpen && (
                                    <Box
                                        position="absolute"
                                        display="flex"
                                        alignItems="center"
                                        bgcolor={theme.palette.background.custom4}
                                        left={'-4px'}
                                        height={56}
                                        width={60}
                                        sx={{
                                            borderBottomLeftRadius: 8,
                                            borderTopLeftRadius: 8,
                                        }}
                                        zIndex={100}
                                    >
                                    </Box>
                                )
                            }
                        </Box>

                    </Box>

                </Box>

                {
                    sideNavigationPanelOpen && <SideNavigationPanel/>
                }

            </Box>

        </Box>
    )
}


function SideNavigationPanel() {

    const theme = useTheme();

    return (
        <Box
            position={'relative'} minWidth={250} maxWidth={300}
            py={2}
            sx={{
                backgroundColor: theme.palette.background.custom4,
                borderRadius: 2,
                borderBottomLeftRadius: 0,
            }}
        >
            {/*{*/}
            {/*    value === NavigationTabs.Filter && <Box p={2}>*/}
            {/*        <Typography variant={'h6'} color={"textPrimary"}>Filters</Typography>*/}
            {/*    </Box>*/}
            {/*}*/}

            <Box
                display={'grid'}
                gridTemplateColumns={'300px'}
                gridTemplateRows={{xs: '2fr 2fr 1fr', md: '1fr 1fr'}}
                gap={1}
                width={'100%'}
                height={`calc(100% - 60px)`}

            >
                <Box
                    display={'flex'}
                    flexDirection={'column'}
                    gap={1}
                    sx={{
                        overflowY: 'hidden',
                    }}
                    gridColumn={'1 / 2'}
                    gridRow={'1 / 2'}
                >
                    <FavoriteBacktestsView/>
                </Box>
                <Box
                    display={'flex'}
                    flexDirection={'column'}
                    gap={1}
                    sx={{
                        overflowY: 'hidden',
                    }}
                    gridColumn={'1 / 2'}
                    gridRow={'2 / 3'}
                >
                    <RecentlyViewedBacktestsView/>
                </Box>
                <Box
                    display={'flex'}
                    flexDirection={'column'}
                    gap={1}
                    sx={{
                        overflowY: 'hidden',
                    }}
                    gridColumn={'1 / 2'}
                    gridRow={'3 / 4'}
                >
                    <RunningBacktestsView/>
                </Box>
            </Box>
            {/*<Paper sx={{ position: 'absolute', bottom: 0, left: 0, right: 0}} elevation={3}>*/}
            {/*    <BottomNavigation*/}
            {/*        sx={{backgroundColor: theme.palette.background.custom4,}}*/}
            {/*        showLabels*/}
            {/*        value={value}*/}
            {/*        onChange={(event, newValue) => {*/}
            {/*            setValue(newValue);*/}
            {/*        }}*/}
            {/*    >*/}
            {/*        <BottomNavigationAction label="Filter" icon={<Tune />} />*/}
            {/*        /!*<BottomNavigationAction label="Favorites" icon={<FavoriteIcon />} />*!/*/}
            {/*        /!*<BottomNavigationAction label="Archive" icon={<ArchiveIcon />} />*!/*/}
            {/*    </BottomNavigation>*/}
            {/*</Paper>*/}
        </Box>
    )
}

const StyledFormControl = styled(FormControl)(({ theme }) => ({
    width: '100%',
    cursor: 'pointer',
    '.MuiOutlinedInput-root': {
        backgroundColor: theme.palette.background.custom4,
        '& fieldset': {
            borderColor: 'rgba(255, 255, 255, 0.23)',
        },
        '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
            borderColor: theme.palette.primary.main,
        }
    },
    '.MuiSelect-select': {
        '&:focus': {
            backgroundColor: theme.palette.background.custom4,
        },
        paddingRight: '36px',
    },
    '.MuiPaper-root': {
        backgroundColor: theme.palette.background.custom4,
    }
}));



// const DotIndicator = styled('span')(({ theme }) => ({
//     width: '8px',
//     height: '8px',
//     borderRadius: '50%',
//     backgroundColor: theme.palette.primary.main,
//     position: 'absolute',
//     top: 0,
//     right: 0,
//     animation: 'pulse 1.5s infinite',
//     '@keyframes pulse': {
//         '0%': {
//             transform: 'scale(1)',
//             opacity: 0.7,
//         },
//         '50%': {
//             transform: 'scale(1.2)',
//             opacity: 1,
//         },
//         '100%': {
//             transform: 'scale(1)',
//             opacity: 0.7,
//         },
//     },
// }));


export default SideNavigation;
