import React from "react";
import ReportCardList from "../../../components/backtests/ReportCardList";
import useRecentlyViewedBacktests from "../../../hooks/useRecentlyViewedBacktests";

function RecentlyViewedBacktests() {
    const [backtests] = useRecentlyViewedBacktests();
    return <ReportCardList backtests={backtests} condensed={true} emptyListText={{
        title: "No recently viewed backtests",
        subTitle: "Backtests you view will appear here"
    }}/>
}

export default RecentlyViewedBacktests;
