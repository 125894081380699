import {Typography} from "@mui/material";
import Box from "@mui/material/Box";
import React from "react";
import ReportCardList from "../../../components/backtests/ReportCardList";
import useRecentlyViewedBacktests from "../../../hooks/useRecentlyViewedBacktests";
import SummarizeIcon from "@mui/icons-material/Summarize";

function RecentlyViewedBacktestsView() {
    const [backtests] = useRecentlyViewedBacktests();

    return <>
        <Box display={'flex'} alignItems={'center'} gap={1} color="white" px={1}>
            <SummarizeIcon fontSize={'medium'}/>
            <Typography variant={'h6'} >
                Recently Viewed <Typography variant="body2" color="white" sx={{display: 'inline'}}>({backtests?.length || 0})</Typography>
            </Typography>
        </Box>
        <Box display={'flex'} flexDirection={'column'}
             px={.5}
             sx={{
                 overflowY: 'auto',
             }}>
            {!!(backtests && backtests.length) && <ReportCardList backtests={backtests} condensed={true}/>}
            {!(backtests && backtests.length) && <Typography variant="body2" color="white" textAlign={'center'}>No recently viewed backtests</Typography>}
        </Box>
    </>
}

export default RecentlyViewedBacktestsView;
