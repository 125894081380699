import React, {useRef} from "react";
import {FormControl, InputLabel, MenuItem, Select} from "@mui/material";
import { useApplicationSuiteContext } from "../../../../contexts/ApplicationSuiteProvider";
import SymbolSelectManager, {
    ISymbolSelectManagerRef
} from "../../../../common/components/dialogs/symbolSelectDialog/SymbolSelectManager";
import {ISymbol} from "../../../../common/components/dialogs/symbolSelectDialog/SymbolSelect";
import Box from "@mui/material/Box";
import IconButton from "@mui/material/IconButton";
import ClearOutlinedIcon from "@mui/icons-material/ClearOutlined";
import FilterComponentParams from "./types/FilterComponentParams";

function SymbolSelectFilter(props: FilterComponentParams) {

    const {
        backtestFilters,
        setBacktestFilters,
    } = props;

    const {
        symbols
    } = useApplicationSuiteContext();

    const managerRef = useRef<ISymbolSelectManagerRef | null>(null);

    const openSymbolSelectDialog = () => {
        if (managerRef.current) {
            // @ts-ignore
            managerRef.current.openDialog();
        }
    };

    function selectSymbol(symbol: ISymbol) {
        setBacktestFilters((prev: any) => ({
            ...prev,
            symbolSearch: symbol.name
        }));
    }

    return (
        <>
            <SymbolSelectManager
                ref={managerRef}
                // @ts-ignore
                caller={'backtester'}
                symbolSelectCallback={selectSymbol}
                symbols={(symbols || []) as ISymbol[]}
            />
            <FormControl fullWidth>
                <InputLabel id="symbol" sx={{
                        '&:not(.MuiInputLabel-shrink)': {
                            transform: 'translate(14px, 10px) scale(1)',
                        },
                    }}
                >
                    Symbol
                </InputLabel>
                <Select
                    sx={{ color: 'white', '.MuiSelect-select': {padding: '0'}, height: 40, px: 1}}
                    labelId="symbol"
                    value={backtestFilters.symbolSearch || ""}
                    name="symbol"
                    label="Symbol"
                >
                    {(symbols || []).map((symbol: any, index) => (
                        <MenuItem key={index} value={symbol.name}>{symbol.name}</MenuItem>
                    ))}
                </Select>
                {/* Prevent the actual select from being opened */}
                <Box
                    onClick={openSymbolSelectDialog}
                    style={{
                        position: 'absolute',
                        top: 0,
                        left: 0,
                        right: 0,
                        bottom: 0,
                        cursor: 'pointer',
                        zIndex: 2, // Ensure this is higher than the zIndex of elements you wish to cover
                    }}
                    sx={{
                        '&:hover': {
                            border: '1px solid white',
                            borderRadius: '4px',
                            backgroundColor: 'transparent',
                        },
                    }}
                >
                    {
                        backtestFilters.symbolSearch && <IconButton
                        onClick={(e) => {
                            e.stopPropagation();
                            setBacktestFilters((prev: any) => ({
                                ...prev,
                                symbolSearch: ''
                            }));
                        }}
                        sx={{
                            position: 'absolute',
                            top: '50%',
                            right: '32px',
                            transform: 'translateY(-50%)',
                            zIndex: 1,
                        }}
                      >
                        <ClearOutlinedIcon fontSize={'small'}/>
                      </IconButton>
                    }
                </Box>
            </FormControl>
        </>
    );
}

export default SymbolSelectFilter;
