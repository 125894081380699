import React from "react";
import {Autocomplete, Box, TextField} from "@mui/material";
import {useCollectionData, useCollectionDataOnce} from "react-firebase-hooks/firestore";
import {collection, query, where} from "firebase/firestore";
import {firebase_firestore} from "../../common/firebaseConfig";
import {useApplicationSuiteContext} from "../../contexts/ApplicationSuiteProvider";

type PatternSelectionProps = {
    pattern?: any;
    onSelect: (pattern: any) => void;
    error?: {
        hasError: boolean;
        message?: string;
    };
}

function PatternSelect({pattern, onSelect, error}: PatternSelectionProps) {
    const {
        usermeta,
        tradingHouse,
    } = useApplicationSuiteContext();

    const [usermetaPatterns] =
        useCollectionData(
            query(
                collection(firebase_firestore, `usermeta/${usermeta.gcid}/patterns`),
                where("active", "==", true),
                where("tradingHouse", "==", tradingHouse)
            )
        );

    const [standardPatterns] =
        useCollectionDataOnce(
            query(
                collection(firebase_firestore, `tradingHouses/${tradingHouse}/standardPatterns`),
                where("active", "==", true)
            )
        ) as any[];

    return <Autocomplete
        fullWidth
        id="tags-filled"
        onChange={(event: any, newValue: any) => onSelect(newValue)}
        getOptionLabel={(option) => `${option.name} (v${option.version})`}
        options={(standardPatterns || []).sort((a: any, b: any) => a.name.localeCompare(b.name)).concat((usermetaPatterns || []).sort((a: any, b: any) => a.name.localeCompare(b.name)))}
        value={pattern || null}
        renderOption={(props, option, { selected }) => (
            <li {...props} key={option.id} style={{width: "100%"}}>
                <Box display="flex"  alignItems="flex-end" width={"100%"}>
                    <Box>{option.name} {option.author && <span style={{fontSize: 10, color: "gray"}}>({option.author})</span>}</Box>

                    <span style={{
                        flexGrow: 1,
                        backgroundImage: 'radial-gradient(gray 15%, transparent 20%)', /* This makes actual dots */
                        backgroundSize: '5px 1px', /* Adjust the size of dots and the gap between them */
                        height: '1px', /* The height of the line of dots */
                        margin: '0 10px', /* Optional: to give some space between name/dots and dots/number */
                    }}/>
                    <Box sx={{fontSize: "10px"}}> v{option.version}</Box>
                </Box>
            </li>
        )}
        renderInput={(params) => (
            <TextField
                {...params}
                variant="outlined"
                label="Pattern"
                error={!!error?.hasError}
                helperText={error?.hasError ? error.message : ""}
                placeholder=""
            />
        )}
    />
}

export default PatternSelect;
