import React, {useRef} from "react";
import SymbolSelectManager, {ISymbolSelectManagerRef} from "../../common/components/dialogs/symbolSelectDialog/SymbolSelectManager";
import {FormControl, InputLabel, MenuItem, Select} from "@mui/material";
import {useApplicationSuiteContext} from "../../contexts/ApplicationSuiteProvider";
import {ISymbol} from "../../common/components/dialogs/symbolSelectDialog/SymbolSelect";

type SymbolSelectProps = {
    symbol: string;
    onSelect: (symbol: ISymbol) => void;
}

function SymbolSelect({symbol, onSelect}: SymbolSelectProps) {
    const {
        symbols,
    } = useApplicationSuiteContext()

    const managerRef = useRef<ISymbolSelectManagerRef | null>(null);
    const openSymbolSelectDialog = () => {
        if (managerRef.current) {
            // @ts-ignore
            managerRef.current.openDialog();
        }
    };

    return (
        <>
            <SymbolSelectManager
                ref={managerRef}
                caller={'symbolSelect'}
                symbolSelectCallback={onSelect}
                symbols={(symbols || []) as ISymbol[]}
            />
            <FormControl variant="outlined" fullWidth>
                <InputLabel id="symbol">Symbol</InputLabel>
                <Select
                    labelId="symbol"
                    value={symbol}
                    name="executionSymbol"
                    label="Symbol"
                    open={false}
                    onClick={openSymbolSelectDialog}
                >
                    {(symbols || []).map((symbol: any, index) => (
                        <MenuItem key={index} value={symbol.name}>{symbol.name}</MenuItem>
                    ))}
                </Select>
            </FormControl>
        </>
    )

}

export default SymbolSelect;
