import React from "react";
import ActionChip from "./ActionChip";
import ConstructionIcon from "@mui/icons-material/Construction";
import {Tabs} from "../../layout/MainLayout";
import useRecentlyViewedBacktests from "../../hooks/useRecentlyViewedBacktests";
import {useApplicationSuiteContext} from "../../contexts/ApplicationSuiteProvider";
import UpgradeDialog from "../UpgradeDialog";
import LockIcon from "@mui/icons-material/Lock";

function ViewPatternActionChip({ onClick, backtest, pattern, ...otherProps }: any) {
    const {
        usermeta,
        setActiveTab,
        setPattern,
        canViewPrivatePatterns
    } = useApplicationSuiteContext();

    const [, setRecentlyViewedBacktests] = useRecentlyViewedBacktests();

    const [upgradeDialogOpen, setUpgradeDialogOpen] = React.useState(false);

    function traderOwnsPattern() {
        if (backtest) {
            return backtest.gcid === usermeta.gcid;
        } else if (pattern) {
            return true // TODO: We dont have any reason to check this, but we should probably check if the pattern is owned by the user. Right now we only ever show the user's patterns
        }
    }

    const locked = !canViewPrivatePatterns && !traderOwnsPattern();

    function goToPatternBuilder(event: any) {
        event.stopPropagation();

        if (locked) {
            setUpgradeDialogOpen(true);
        } else {
            if (backtest) {
                setRecentlyViewedBacktests(backtest);
            }

            let patternObject = pattern || backtest.pattern;

            if (patternObject) {
                if (!traderOwnsPattern()) {
                    patternObject.author = "Showcase"
                }
                setPattern(patternObject);
                setActiveTab(Tabs.PatternBuilder);
            }

            if (onClick) {
                onClick();
            }
        }

    }

    return (
        <>
            {(pattern || backtest.pattern) && <ActionChip
                label="View Pattern"
                icon={<ConstructionIcon/>}
                hoveringIcon={locked && <LockIcon color={'warning'}/>}
                goToWidth="125px"
                onClick={goToPatternBuilder}
                color={locked ? 'lightgray' : 'primary'}
                {...otherProps}
            />}
            {upgradeDialogOpen && <UpgradeDialog open={upgradeDialogOpen} onClose={() => setUpgradeDialogOpen(false)} />}
        </>

    );
}

export default ViewPatternActionChip;
