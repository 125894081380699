import {useApplicationSuiteContext} from "../../../contexts/ApplicationSuiteProvider";
import {useShowcaseContext} from "../contexts/ShowcaseContext";
import {useHttpsCallable} from "react-firebase-hooks/functions";
import {firebase_functions} from "../../../common/firebaseConfig";
import React, {useCallback} from "react";
import usePaginatedLoader from "../../../hooks/usePaginatedLoader";
import ReportCardList from "../../../components/backtests/ReportCardList";

function ShowcaseBacktestReports() {
    const {
        tradingHouse,
    } = useApplicationSuiteContext();

    const {
        backtestFilters,
        backtestSort,
        type
    } = useShowcaseContext()

    const [getShowcaseBacktests] = useHttpsCallable(firebase_functions, "getBacktests");
    const func = useCallback(
        (params: any) => getShowcaseBacktests({...params, tradingHouse, backtestFilters, sort: backtestSort, type}),
        [getShowcaseBacktests, tradingHouse, backtestFilters, backtestSort, type]
    );

    const { data: backtests, loadMore, isLoading, totalCount } = usePaginatedLoader({
        queryFunction: func,
        take: 15,
    });

    return (
        <ReportCardList backtests={backtests} loadMore={loadMore} isLoading={isLoading} totalCount={totalCount} emptyListText={{
            title: "No backtests found",
            subTitle: "Try adjusting your filters"
        }} />
    );
}

export default ShowcaseBacktestReports;
