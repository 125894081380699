import {Box, Typography} from "@mui/material";
import React from "react";
import {useTheme} from "@mui/material/styles";
import Button from "@mui/material/Button";

function FilterHeaderSection(props: { title?: string, onClearSection?: () => void, onClearText?: string }) {
    const { title, onClearSection } = props;

    const theme = useTheme();

    return (
        <Box p={1} sx={{ backgroundColor: theme.palette.background.custom3, boxShadow: 'inset 0px -3px 8px -3px black', height: 40 }} display={"flex"} justifyContent={'space-between'} alignItems={'center'}>
            <Typography variant="body1" color={"textPrimary"}>{title}</Typography>
            {onClearSection && <Button onClick={() => onClearSection()} color={"primary"}>{props.onClearText || "Clear"}</Button>}
        </Box>
    );
}

export default FilterHeaderSection;
