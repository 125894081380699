import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import Button from "@mui/material/Button";
import ReportEquityChart from "./BacktestEquityChart";
import {Box, IconButton, Typography} from "@mui/material";
import React from "react";
import {useTheme} from "@mui/material/styles";
import TimeAgo from "javascript-time-ago";
import _duration from "format-duration-time";
import Tooltip from "@mui/material/Tooltip";
import {CalendarMonth} from "@mui/icons-material";
import {useApplicationSuiteContext} from "../../contexts/ApplicationSuiteProvider";
import {Tabs} from "../../layout/MainLayout";
import BacktestTable from "../../app/reports/components/backtestResults/BacktestTable";
import BacktestGradeIcon from "./BacktestGradeIcon";
import ViewBacktestSettingsActionChip from "../actionChips/ViewBacktestSettingsActionChip";
import ViewPatternActionChip from "../actionChips/ViewPatternActionChip";
import BacktestDescription from "./BacktestDescription";
import CreateAlertActionChip from "../actionChips/CreateAlertActionChip";

function ReportViewModal({ backtest, open, onClose, selectable }: any) {
    const theme = useTheme();

    const _timeAgo = new TimeAgo('en-US')

    const {
        setActiveTab,
        setPattern,
    } = useApplicationSuiteContext();

    function renderBacktestDateTime() {
        if (backtest.backtestSettings.startTime === backtest.backtestSettings.endTime) {
            return `All day`;
        }

        return `${new Date(backtest.backtestSettings.startTime).toLocaleTimeString()} to ${new Date(backtest.backtestSettings.endTime).toLocaleTimeString()}`;
    }

    const startDate = new Date(backtest.backtestSettings.dateRange.startDate).toDateString();
    const endDate = new Date(backtest.backtestSettings.dateRange.endDate).toDateString();
    const dateRangeString = `${startDate} to ${endDate}`;
    const timeRangeString = renderBacktestDateTime();

    function goToPatternBuilder() {
        setPattern(backtest.pattern);
        setActiveTab(Tabs.PatternBuilder);
        onClose && onClose();
    }

    function renderBacktestDateRangeReadable() {
        const durationMinutes = _duration(backtest.backtestSettings.dateRange.endDate - backtest.backtestSettings.dateRange.startDate).format('m')
        const durationDays = Math.ceil(durationMinutes / 1440);
        // determine months and days
        const months = Math.floor(durationDays / 30);

        if (months) {
            const monthDays = durationDays % 30;
            const monthPlural = months > 1 ? 's' : '';
            const monthDayPlural = monthDays > 1 ? 's' : '';
            if (monthDays) {
                return `${months} month${monthPlural} and ${monthDays} day${monthDayPlural}`
            } else {
                return `${months} month${monthPlural}`
            }
        } else {
            const dayPlural = durationDays > 1 ? 's' : '';
            return `${durationDays} day${dayPlural}`
        }
    }

    return (
        <Dialog open={open} onClose={onClose} fullWidth maxWidth={'lg'} onClick={(event) => event.stopPropagation()}>
            <DialogTitle sx={{backgroundColor: theme.palette.background.custom4}}>
                <Box display={'flex'} flexDirection={'column'} justifyContent={'space-between'} width={'100%'} alignItems={'center'}>

                    <Box display={'flex'} justifyContent={'space-between'} width={'100%'} alignItems={'center'}>
                        <Box>
                            <Typography variant="h6" color="text.primary" overflow={'hidden'} whiteSpace={'nowrap'} textOverflow={'ellipsis'} onClick={() => goToPatternBuilder()}
                                        sx={{
                                            cursor: 'pointer',
                                            '&:hover': {
                                                color: theme.palette.primary.main,
                                                textDecoration: 'underline'
                                            }
                                        }}
                            >
                                {backtest.name}
                                <Typography variant={'caption'}> (v{backtest?.pattern?.version || 0})</Typography>
                            </Typography>
                        </Box>

                        <BacktestGradeIcon backtest={backtest} />
                    </Box>
                    <Box overflow={'hidden'} width={'100%'}>
                        <BacktestDescription backtest={backtest}/>
                    </Box>
                </Box>
            </DialogTitle>
            <DialogContent>
                <Box width={'100%'} height={250}>
                    <ReportEquityChart backtest={backtest} />
                </Box>
                <Box height={300}>
                    <BacktestTable backtest={backtest} />
                </Box>
            </DialogContent>
            <DialogActions
                sx={{borderTop: `1px solid ${theme.palette.divider}`}}
            >
                <Box display={'flex'} justifyContent={'space-between'} width={'100%'} alignItems={'center'}>
                    <Box>

                        <Tooltip
                            arrow={true}
                            placement={"top-start"}
                            title={
                                <React.Fragment>
                                    <Typography variant="body2" color="inherit">
                                        {renderBacktestDateRangeReadable()}
                                    </Typography>
                                    <Typography variant="body2" color="inherit">
                                        {dateRangeString}
                                    </Typography>
                                    <Typography variant="body2" color="inherit">
                                        {timeRangeString}
                                    </Typography>
                                </React.Fragment>
                            }
                        >
                            <IconButton>
                                <CalendarMonth fontSize={'small'} />
                            </IconButton>
                        </Tooltip>
                        {!backtest.endTime && <Typography variant={"caption"}>{_duration(new Date().getTime() - backtest.startTime).format('m[m] ss[s]')}</Typography>}
                        {backtest.endTime && <Typography variant={"caption"}>{_timeAgo.format(new Date(backtest.startTime))}</Typography>}
                    </Box>

                    <Box display={'flex'} gap={2}>
                        <CreateAlertActionChip backtest={backtest} forceOpen={true} disabled={selectable}/>
                        <ViewBacktestSettingsActionChip backtest={backtest} forceOpen={true} disabled={selectable}/>
                        <ViewPatternActionChip backtest={backtest} forceOpen={true} disabled={selectable}/>
                    </Box>

                    <Box>

                        <Button onClick={onClose} color="primary">
                            Close
                        </Button>
                    </Box>
                </Box>
            </DialogActions>
        </Dialog>
    )
}

export default ReportViewModal;
