import {Box, Typography} from "@mui/material";
import SymbolSelect from "../../form/SymbolSelect";
import React from "react";
import {Alert} from "../../../contexts/ApplicationSuiteProvider";
import PatternTimeframeSelect from "../../form/PatternTimeframeSelect";
import flatternPattern from "../../../common/utils/flatternPattern";
import _cloneDeep from "lodash.clonedeep";

function AlertPattenDetailsForm({alert, onAlertKeyChange}: { alert: Alert, onAlertKeyChange: (key: string, value: any) => void }) {

    function handleChangeSymbol(symbol: string) {
        if (!alert.pattern) {
            return;
        }

        alert.pattern.trigger.chartDetails.symbol = symbol;

        const conditions = flatternPattern(alert.pattern);

        for (const condition of conditions) {
            condition.chartDetails.symbol = symbol;
        }

        onAlertKeyChange("pattern", _cloneDeep(alert.pattern));
        onAlertKeyChange('name', `${symbol} - ${alert.pattern.name}`)
    }

    if (!alert.pattern) {
        return null;
    }

    return (
        <>
            <Box display={"flex"} flexDirection={"column"} gap={2} p={1}>
                <SymbolSelect
                    symbol={alert.pattern.trigger.chartDetails.symbol}
                    onSelect={(symbol) => handleChangeSymbol(symbol.name)}
                />

                <Typography variant={"body2"}>Chart Timeframes</Typography>
                <PatternTimeframeSelect
                    pattern={alert.pattern}
                    onPatternTimeframeChange={(pattern) => onAlertKeyChange("pattern", pattern)}
                />
            </Box>
        </>
    )

}

export default AlertPattenDetailsForm;
