import FilterButton from "./FilterButton";
import {Typography} from "@mui/material";
import React from "react";
import FilterComponentParams from "../filters/types/FilterComponentParams";
import FilterHeaderSection from "../filters/components/FilterHeaderSection";
import TriggerChartFilter from "../filters/TriggerChartFilter";

function TriggerChartButton(props: FilterComponentParams) {
    const {
        backtestFilters,
        setBacktestFilters
    } = props;


    const getLabel = () => {
        if (!backtestFilters.triggerChart) {
            return "Trigger Chart";
        }

        let label = ` ${backtestFilters.triggerChart.periodicity} ${backtestFilters.triggerChart.timeframe}`;

        return <Typography variant="body2" color={"textPrimary"}>
            {label}
        </Typography>
    };

    return <>
        <FilterButton
            menu={
                <>
                    <FilterHeaderSection
                        title={"Trigger Chart"}
                        onClearSection={() => {
                            setBacktestFilters((prev: any) => ({
                                ...prev,
                                triggerChart: null,
                            }));
                        }}
                    />

                    <TriggerChartFilter backtestFilters={backtestFilters} setBacktestFilters={setBacktestFilters}/>
                </>
            }
            label={getLabel()}
        />
    </>
}

export default TriggerChartButton;
