import React from 'react';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Button from '@mui/material/Button';
import {useApplicationSuiteContext} from "../contexts/ApplicationSuiteProvider";
import {Typography} from "@mui/material";
import PublicIcon from "@mui/icons-material/Public";
import Box from "@mui/material/Box";
import {Notifications, Summarize} from "@mui/icons-material";
import VideoSettingsIcon from "@mui/icons-material/VideoSettings";
import ConstructionIcon from "@mui/icons-material/Construction";

const UpgradeDialog = ({open, onClose}: any) => {

    const {
        hasStarterTrialAccess,
    } = useApplicationSuiteContext();

    const handleClose = () => {
        onClose && onClose();
    };

    function openMarketplace() {
        window.open('https://myaccount.greenchart.com/dashboard/marketplace?display=Strategy%20Suite', '_blank');
    }

    return (
        <Dialog open={open} onClose={handleClose}>
            <DialogTitle>Upgrade Now to Maximize Your GreenChart Experience</DialogTitle>
            <DialogContent>
                <DialogContentText>
                    Here's what awaits you:

                    <Box display={'flex'} flexDirection={'column'} p={2} gap={1}>
                        <Box display={'flex'} alignItems={'center'} gap={.5}>
                            <PublicIcon  fontSize={'large'}/>
                            <Typography variant={'body1'} color={'textPrimary'} fontWeight={'bold'}>Showcase:</Typography>
                            <Typography>{
                                hasStarterTrialAccess
                                    ? 'View and copy winning strategies from members.'
                                    : 'View winning strategies from members.'
                            }</Typography>
                        </Box>

                        <Box display={'flex'} alignItems={'center'} gap={.5}>
                            <ConstructionIcon  fontSize={'large'}/>
                            <Typography variant={'body1'} color={'textPrimary'} fontWeight={'bold'}>Pattern Builder:</Typography>
                            <Typography>Build a winning strategy.</Typography>
                        </Box>

                        <Box display={'flex'} alignItems={'center'} gap={.5}>
                            <VideoSettingsIcon  fontSize={'large'}/>
                            <Typography variant={'body1'} color={'textPrimary'} fontWeight={'bold'}>Backtester:</Typography>
                            <Typography>Simulate your strategy with historical data.</Typography>
                        </Box>

                        <Box display={'flex'} alignItems={'center'} gap={.5}>
                            <Summarize  fontSize={'large'}/>
                            <Typography variant={'body1'} color={'textPrimary'} fontWeight={'bold'}>Backtest Reports:</Typography>
                            <Typography>Gain valuable insights and refine your strategy.</Typography>
                        </Box>

                        <Box display={'flex'} alignItems={'center'} gap={.5}>
                            <Notifications  fontSize={'large'}/>
                            <Typography variant={'body1'} color={'textPrimary'} fontWeight={'bold'}>Alerts:</Typography>
                            <Typography>Get buy/sell notifications.</Typography>
                        </Box>
                    </Box>
                </DialogContentText>
            </DialogContent>
            <DialogActions sx={{
                display: 'flex',
                justifyContent: 'space-between',
                padding: '16px'

            }}>
                <Button onClick={openMarketplace} variant={"contained"} color={"primary"}>Upgrade</Button>
                <Button onClick={handleClose} color="primary">Cancel</Button>
            </DialogActions>
        </Dialog>
    );
};

export default UpgradeDialog;
