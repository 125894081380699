import {useTheme} from "@mui/material/styles";
import React from "react";
import {useApplicationSuiteContext} from "../../contexts/ApplicationSuiteProvider";
import {Box, Paper, Popper, Typography} from "@mui/material";

function BacktestDescription({ backtest }: any) {
    const theme = useTheme();

    const anchorRef = React.useRef<HTMLDivElement>(null);
    const [open, setOpen] = React.useState(false);
    const [arrowRef, setArrowRef] = React.useState(null);

    const {
        symbols
    } = useApplicationSuiteContext();

    function getSymbolDescription() {
        const symbol = symbols?.find((symbol: any) => symbol.name === backtest.backtestSettings.executionSymbol);
        const description = symbol && symbol.description ? `: ${symbol.description}` : '';
        return symbol ? `${symbol.name} (${symbol.type}${description})` : backtest.backtestSettings.executionSymbol;
    }

    return (
        <>
            <Typography
                variant="body2"
                color="text.secondary"
                ref={anchorRef}
                onMouseEnter={() => setOpen(true)}
                onMouseLeave={() => setOpen(false)}
                sx={{
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                    whiteSpace: 'nowrap',
                }}
            >
                {backtest?.pattern?.description || backtest.backtestSettings.executionSymbol}
            </Typography>
            {open && <Popper
              modifiers={
                  [
                      {
                          name: 'arrow',
                          enabled: true,
                          phase: 'beforeRead',
                          options: {
                              element: arrowRef,
                          },
                      },
                  ]}
              open={open}
              anchorEl={anchorRef.current}
              placement={'bottom-start'}
              disablePortal={false}
              onMouseEnter={() => setOpen(true)}
              onMouseLeave={() => setOpen(false)}
              sx={{
                  zIndex: 9999,
                  width: '300px',
              }}
              onClick={(event) => event.stopPropagation()}
            >
              <Box sx={{
                  position: 'absolute',
                  fontSize: 7,
                  left: 0,
                  marginLeft: '1.5em',
                  marginTop: '-1.9em',
                  height: '3em',
                  width: '1em',
                  '&::before': {
                      content: '""',
                      margin: 'auto',
                      display: 'block',
                      width: 0,
                      height: 0,
                      borderStyle: 'solid',
                      borderWidth: '1em 1em 1em 1em',
                      borderColor: `transparent transparent ${theme.palette.background.custom3} transparent`,
                  },
              }} ref={setArrowRef} className="arrow"/>
              <Paper sx={{
                  px: 2,
                  py: 1,
                  backgroundColor: theme.palette.background.custom3,
                  '--Paper-overlay': 'none !important',
              }}>
                <Box>
                  <Typography variant="caption" color="text.secondary">
                      {open && getSymbolDescription()}
                  </Typography>
                  <Typography variant="body2" color="text.primary">
                      {backtest.pattern?.description}
                  </Typography>
                </Box>
              </Paper>

            </Popper>
            }
        </>

    )
}

export default BacktestDescription;
