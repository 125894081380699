import React, { useState, useEffect } from 'react';
import FilterComponentParams from "./types/FilterComponentParams";
import {InputAdornment, TextField} from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";

function PatternNameSearchFilter(props: FilterComponentParams) {
    const { backtestFilters, setBacktestFilters } = props;

    const [inputValue, setInputValue] = useState(backtestFilters.patternNameSearch || '');

    // Effect to handle debouncing
    useEffect(() => {
        const handler = setTimeout(() => {
            if (inputValue === backtestFilters.patternNameSearch) return;
            setBacktestFilters((prev: any) => ({
                ...prev,
                patternNameSearch: inputValue,
            }));
        }, 300);

        // Cleanup the timeout if inputValue changes before the delay is over
        return () => {
            clearTimeout(handler);
        };
    }, [inputValue, backtestFilters.patternNameSearch, setBacktestFilters]);

    return (
        <>
            <TextField
                slotProps={{
                    input: {
                        startAdornment: (
                            <InputAdornment position="start">
                                <SearchIcon />
                            </InputAdornment>
                        )
                    }
                }}
                placeholder={'Pattern Name'}
                label={'Pattern Name'}
                value={inputValue}
                onChange={(e) => {
                    setInputValue(e.target.value);
                }}
                sx={{
                    "& .MuiInputBase-root": {
                        height: "40px",
                    },

                }}
                InputLabelProps={{
                    sx: {
                        '&:not(.MuiInputLabel-shrink)': {
                            transform: 'translate(14px, 10px) scale(1)',
                        },
                    }
                }}
                InputProps={{ sx: { height: "40px" } }}
                fullWidth
            />
        </>
    );
}

export default PatternNameSearchFilter;
