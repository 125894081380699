import React from "react";
import NetProfitFilter from "../filters/NetProfitFilter";
import FilterComponentParams from "../filters/types/FilterComponentParams";
import AbsoluteDrawdownFilter from "../filters/AbsoluteDrawdownFilter";
import DateRangeFilter from "../filters/DateRangeFilter";
import FilterButton from "./FilterButton";
import FilterHeaderSection from "../filters/components/FilterHeaderSection";
import Box from "@mui/material/Box";
import RelativeDrawdownFilter from "../filters/RelativeDrawdownFilter";
import DaysBacktestedFilter from "../filters/DaysBacktestedFilter";
import TriggerChartFilter from "../filters/TriggerChartFilter";

function MoreFilterButton(props: FilterComponentParams) {

    const {
        backtestFilters,
        setBacktestFilters
    } = props;

    const getLabel = () => {
        return 'Filters'
    };


    return <>
        <FilterButton
            menu={<>
                <Box height={500} sx={{overflowY: 'auto'}}>
                    <FilterHeaderSection title={"More Filters"}/>
                    <NetProfitFilter backtestFilters={backtestFilters} setBacktestFilters={setBacktestFilters}/>
                    <AbsoluteDrawdownFilter backtestFilters={backtestFilters} setBacktestFilters={setBacktestFilters}/>
                    <RelativeDrawdownFilter backtestFilters={backtestFilters} setBacktestFilters={setBacktestFilters}/>
                    <TriggerChartFilter backtestFilters={backtestFilters} setBacktestFilters={setBacktestFilters}/>
                    <DateRangeFilter backtestFilters={backtestFilters} setBacktestFilters={setBacktestFilters}/>
                    <DaysBacktestedFilter backtestFilters={backtestFilters} setBacktestFilters={setBacktestFilters}/>
                </Box>
            </>}
            label={getLabel()}
        />
    </>
}

export default MoreFilterButton;
