import React from "react";
import { Box, Typography } from "@mui/material";
import TextFieldSelectInput from "./components/TextFieldSelectInput";
import FilterComponentParams from "./types/FilterComponentParams";

function DaysBacktestedFilter(props: FilterComponentParams) {
    const {
        backtestFilters,
        setBacktestFilters
    } = props;

    // const handleApply = () => {
    // setBacktestFilters((prev: any) => ({
    //     ...prev,
    //     startDate: backtestFilters.minDateRange,
    //     endDate: backtestFilters.maxDateRange,
    // }));
    // handleClose();
    // };

    const onValueChanges = (key: string, value?: number) => {
        setBacktestFilters((prev: any) => ({
            ...prev,
            [key]: value
        }));
    }

    return (
        <>

            <Box p={2}>
                <Typography variant="body1" color={"textPrimary"} fontWeight={'bold'}>
                    Days Backtested
                </Typography>

                <Box display="flex" alignItems="center" justifyContent="space-between" gap={2}>
                    <TextFieldSelectInput
                        value={backtestFilters.daysBetween}
                        type={'number'}
                        placeholder={'Any Range'}
                        onChange={(val) => onValueChanges('daysBetween', val)}
                        predefinedValues={[
                            {label: 'Any Range', value: undefined},
                            {label: '1 Day+', value: 1},
                            {label: '7 Days+', value: 7},
                            {label: '30 Days+', value: 30},
                            {label: '90 Days+', value: 90},
                            {label: '180 Days+', value: 180},
                            {label: '365 Days+', value: 365},
                        ]}
                    />
                </Box>
            </Box>
        </>
    );
}

export default DaysBacktestedFilter;
