import {ConditionGroupType, ConditionType} from "../../app/builder/contexts/PatternTreeContext";
import {Pattern} from "../../app/builder/contexts/PatternContext";

function getAllConditionsFromGroup(group: ConditionGroupType): ConditionType[] {
    let allConditions: ConditionType[] = []

    for (const condition of group.conditions) {
        allConditions.push(condition)
    }

    for (const conditionGroup of group.conditionGroups) {
        allConditions = [...allConditions, ...getAllConditionsFromGroup(conditionGroup)]
    }

    return allConditions
}

function flattenPattern(pattern: Pattern): ConditionType[] {
    let allConditions: ConditionType[] = [];

    for (const group of [...pattern.buyConditionGroups, ...pattern.sellConditionGroups]) {
        allConditions = [...allConditions, ...getAllConditionsFromGroup(group)];
    }

    return allConditions;
}

export default flattenPattern;
