import React, {useState, useRef, useEffect} from "react";
import { TextField, Popper, Paper, ClickAwayListener, InputAdornment, List, ListItem } from "@mui/material";
import {useTheme} from "@mui/material/styles";

interface TextFieldSelectInputProps {
    value: string | number | undefined;
    onChange: (value?: number) => void;
    predefinedValues: { label: string, value?: number }[];
    placeholder: string;
    type: 'number' | 'currency' | 'percentage';
}

function TextFieldSelectInput({
                                  value,
                                  onChange,
                                  predefinedValues,
                                  placeholder,
                                    type,
                              }: TextFieldSelectInputProps) {

    const theme = useTheme();

    const [inputValue, setInputValue] = useState<string | number | undefined>(value);
    const [open, setOpen] = useState(false);
    const anchorEl = useRef(null);

    const minValueAllowed = type === 'percentage' ? -100 : Number.MIN_SAFE_INTEGER;

    useEffect(() => {
        setInputValue(value);
    }, [value]);

    const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (isNaN(Number(event.target.value))) {
            setInputValue(event.target.value);
        } else {
            setInputValue(Number(event.target.value));
        }

        const newValue = Number(event.target.value);
        if (isNaN(newValue) || Number(newValue) < minValueAllowed) {
            return;
        }

        onChange(newValue);
    };

    const handleSelectOption = (selectedValue?: number) => {
        setInputValue(selectedValue);
        setOpen(false);
        onChange(selectedValue);
    };

    const handleFocus = (event: any) => {
        setOpen(true);
    };

    const handleOnBlur = () => {
        if (isNaN(Number(inputValue)) || Number(inputValue) < minValueAllowed) {
            setInputValue(value);
        }
    };

    const handleClose = () => {
        setOpen(false);
    };

    return (
        <ClickAwayListener onClickAway={handleClose}>
            <div ref={anchorEl}>
                <TextField
                    value={inputValue === Number.MIN_SAFE_INTEGER ? '' : inputValue === Number.MAX_SAFE_INTEGER ? '' : inputValue === undefined ? '' : inputValue}
                    placeholder={placeholder}
                    autoComplete={"off"}
                    onChange={handleInputChange}
                    onFocus={handleFocus}
                    onBlur={handleOnBlur}
                    sx={{ width: '100%' }}
                    InputProps={{
                        style: { height: 40 },
                        startAdornment: (
                            <InputAdornment position="start">
                                {type === 'currency' && '$'}
                                {type === 'percentage' && '%'}
                                {type === 'number' && '#'}
                            </InputAdornment>
                        ),
                    }}
                />

                <Popper open={open} anchorEl={anchorEl.current} placement="bottom-start" sx={{width: 200, height: 220}}>
                    <Paper sx={{ backgroundColor: theme.palette.background.custom3,  height: 220, overflowY: 'auto',  borderTopRightRadius: 0, borderTopLeftRadius: 0 }}>
                        <List
                            sx={{py: 0}}
                        >
                            {predefinedValues.map((val) => (
                                <ListItem
                                    key={val.value}
                                    sx={{
                                        cursor: 'pointer',
                                        '&:hover': {
                                            backgroundColor: theme.palette.background.custom2
                                        }
                                    }}
                                    onClick={(event) => {
                                        event.stopPropagation();
                                        handleSelectOption(val.value)
                                    }}
                                >
                                    {val.label}
                                </ListItem>

                            ))}
                        </List>
                    </Paper>
                </Popper>
            </div>
        </ClickAwayListener>
    );
}

export default TextFieldSelectInput;
