import React, {createContext, useState, useContext, ReactNode, useEffect} from 'react';
import CreateAlertModal from "../components/alerts/CreateAlertModal";
import {Alert, defaultAlert, useApplicationSuiteContext} from "./ApplicationSuiteProvider";
import {collection, getCountFromServer, query, where} from "firebase/firestore";
import {firebase_firestore} from "../common/firebaseConfig";

type ModalContextType = {
    isCreateAlertModalOpen: boolean;
    setIsCreateAlertModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
    setDefaultAlert: React.Dispatch<React.SetStateAction<Alert>>;
};

const ModalContext = createContext<ModalContextType | undefined>(undefined);

type ModalProviderProps = {
    children: ReactNode;
};

function ModalProvider({ children }: ModalProviderProps) {

    const {
        usermeta,
        isCreateAlertModalOpen,
        setIsCreateAlertModalOpen
    } = useApplicationSuiteContext()

    const [alert, setDefaultAlert] = useState<Alert>(defaultAlert);

    useEffect(() => {
        getCountFromServer(
            query(
                collection(firebase_firestore, `tradingPlans`),
                where("user.gcid", "==", usermeta.gcid),
            )
        ).then((snapshot: any) => setIsCreateAlertModalOpen(snapshot.data().count === 0));
    }, [usermeta.gcid, setIsCreateAlertModalOpen]);

    useEffect(() => {
        if (!isCreateAlertModalOpen) {
            setDefaultAlert(defaultAlert);
        }
    }, [isCreateAlertModalOpen]);

    return (
        <ModalContext.Provider value={{
            isCreateAlertModalOpen,
            setIsCreateAlertModalOpen,
            setDefaultAlert
        }}>
            {children}
            {isCreateAlertModalOpen && <CreateAlertModal alert={alert}/>}
        </ModalContext.Provider>
    );
}

function useModalContext(): ModalContextType {
    const context = useContext(ModalContext);
    if (!context) {
        throw new Error('useModal must be used within an ModalProvider');
    }
    return context;
}

export { ModalProvider, useModalContext };

