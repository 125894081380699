import React from "react";
import Box from "@mui/material/Box";
import Checkbox from "@mui/material/Checkbox";
import { useTheme } from "@mui/material/styles";
import CheckIcon from '@mui/icons-material/Check';

type DaySelectProps = {
    days: number[];
    onDaysChange: (days: number[]) => void;
};

function DaySelect({ days, onDaysChange }: DaySelectProps) {
    const allDaysOfWeekLabels = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];
    const theme = useTheme();

    const handleCheckedChange = (index: number) => (event: React.ChangeEvent<HTMLInputElement>) => {
        const newDays = event.target.checked
            ? [...days, index]
            : days.filter((day) => day !== index);
        onDaysChange(newDays);
    };

    return (
        <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            sx={{
                backgroundColor: theme.palette.background.paper,
                borderRadius: "20px",
                overflow: "hidden",
                border: `1px solid #7A757F`,
            }}
        >
            {allDaysOfWeekLabels.map((day, index) => (
                <Checkbox
                    key={day}
                    checked={days.includes(index)}
                    onChange={handleCheckedChange(index)}
                    icon={<DayIcon day={day} />}
                    checkedIcon={<DayIcon day={day} checked />}
                    sx={{
                        p: 0,
                        flex: 1,
                        color: "transparent", // Hide default checkbox icon
                        "& PrivateSwitchBase-input.MuiSvgIcon-root": {
                            display: "none", // Completely hide the default checkbox icon
                        },
                    }}
                />
            ))}
        </Box>
    );
}

function DayIcon({ day, checked }: { day: string; checked?: boolean }) {
    const theme = useTheme();

    //#4A77A6
    return (
        <Box
            sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                width: "100%",
                height: "30px",
                backgroundColor: checked ? theme.palette.primary.main : theme.palette.background.custom3,
                color: checked ? "#fff" : theme.palette.text.primary,
                fontWeight: "bold",
                borderRight: `1px solid #7A757F`,
            }}
        >
            <Box display={"flex"} alignItems={"center"} justifyContent={"center"} gap={.5}>
                {checked && <CheckIcon fontSize={'small'}/>}
                {day}
            </Box>
        </Box>
    );
}

export default DaySelect;
