import React, {useEffect, useRef, useState} from 'react';
import {LineChart} from '@mui/x-charts';
import {useApplicationSuiteContext} from "../../contexts/ApplicationSuiteProvider";
import {firebase_functions} from "../../common/firebaseConfig";
import {useHttpsCallable} from "react-firebase-hooks/functions";

interface TradeSummary {
    range: Date;
    profit: number;
}

interface GroupData {
    group: string;
    data: TradeSummary[];
}

interface ChartDataPoint {
    x: Date;
    y: number;
}

interface ChartDataSeries {
    data: number[]; // Y-values
    label: string;
    type: 'line';
    showMark?: boolean;
    color?: string;
    lineStyle?: { strokeDasharray: string };
    disableHighlight?: boolean;  // Add this line if you want to control legend visibility
    curve?: 'linear' | 'monotoneX' | 'monotoneY' | 'natural' | 'step' | 'stepBefore' | 'stepAfter';
    valueFormatter?: (value: any) => string;
}

function BacktestEquityChart(props: any) {
    const { backtest } = props;
    const { usermeta } = useApplicationSuiteContext();
    const [chartData, setChartData] = useState<ChartDataSeries[]>([]);
    const [xAxisDates, setXAxisDates] = useState<Date[]>([]);
    const [yAxisRange, setYAxisRange] = useState<{ min: number; max: number } | null>(null);

    const [getBacktestTradeProfits] = useHttpsCallable(firebase_functions, "getBacktestTradeProfits");
    const hasFetchedData = useRef(false);

    useEffect(() => {
        if (!backtest || !usermeta || hasFetchedData.current || !backtest?.summaries || !backtest?.summaries?.length) return;
        hasFetchedData.current = true;
        const fetchChartData = async () => {
            // Step 1: Fetch daily trade summaries
            const groups = backtest.summaries.map((summary: any) => summary.group);
            let groupTradeProfits: GroupData[] = await getBacktestTradeProfits({backtestId: backtest.id, groups: groups}).then((result: any) => result.data);
            const startDate = new Date(backtest.backtestSettings.dateRange.startDate);
            const normalizedStartDate = new Date(startDate.getFullYear(), startDate.getMonth(), startDate.getDate()-1);

            // Step 2: Collect all unique dates
            const allDatesSet = new Set<number>([normalizedStartDate.getTime()]);
            groupTradeProfits.forEach(({ data }) => {
                data.forEach(({ range }: any) => {
                    // Normalize dates to remove time components
                    const rangeDate = new Date(range);
                    const normalizedDate = new Date(rangeDate.getFullYear(), rangeDate.getMonth(), rangeDate.getDate());
                    allDatesSet.add(normalizedDate.getTime());
                });
            });

            const allDates = Array.from(allDatesSet)
                .map((timestamp) => new Date(timestamp))
                .sort((a, b) => a.getTime() - b.getTime());

            setXAxisDates(allDates);

            // Step 3: Prepare data for the chart
            const series: ChartDataSeries[] = groupTradeProfits.map(({ group, data }) => {
                const dataPoints = alignChartData(data, allDates);
                const series: ChartDataSeries = {
                    data: dataPoints.map((point) => point.y),
                    valueFormatter: (value: number) => {
                        const percentProfit = value / backtest.backtestSettings.analyzeTrades.equity * 100;
                        const decmialsToShow = percentProfit < 1 ? 2 : 0;
                        return `$${value.toFixed(2)} (${(percentProfit).toFixed(decmialsToShow)}%)`
                    },
                    label: group.replace('-', '/'),
                    type: 'line',
                    showMark: false, // Disable markers for this series
                    // curve: 'linear'
                };

                if (dataPoints[dataPoints.length - 1].y < 0) {
                    series.color = `rgba(255, 255, 255, 0.1)`;
                }

                return series;
            })
            // sort by last value's y
            .sort((a, b) => b.data[b.data.length - 1] - a.data[a.data.length - 1]);

            // Add the baseline series at y=0
            const baselineSeries: ChartDataSeries = {
                data: allDates.map(() => 0),
                label: '',
                type: 'line',
                showMark: false,
                color: 'lightgray',
                lineStyle: { strokeDasharray: '5,5' }, // Makes the line dashed
                disableHighlight: true,  // Hide this series from the legend
            };

            // Step 5: Calculate min and max y-values
            const allYValues = series.flatMap(serie => serie.data); // Extract all y-values
            const minY = Math.min(...allYValues);
            const maxY = Math.max(...allYValues);

            setYAxisRange({ min: minY+(minY*.1), max: maxY });

            setChartData([...series, baselineSeries]);
        };

        fetchChartData();
    }, [backtest, usermeta, getBacktestTradeProfits]);

    if (chartData.length === 0) {
        return null;
    }

    return (
        <LineChart
            xAxis={[
                {
                    scaleType: 'time',
                    data: xAxisDates,
                    valueFormatter: (value: Date, context) => {
                        if (context.location === 'tooltip') {
                            return `Net profit ${value.toLocaleDateString()}`
                        }
                        return `${value.toLocaleDateString()}`;
                    },
                    min: xAxisDates[0],
                    max: xAxisDates[xAxisDates.length - 1],
                },
            ]}
            yAxis={[
                {
                    valueFormatter: (value: number) => `$${value.toFixed(0)}`,
                    min: yAxisRange?.min, // Apply the calculated minimum
                    max: yAxisRange?.max, // Apply the calculated maximum
                },
            ]}
            series={chartData}
            margin={{ top: 8, right: 10, left: 50, bottom: 24 }}
            slotProps={{
                legend: {hidden: true},
            }}
        />
    );
}

// Align and combine the cumulative profit from daily summaries across all dates
const alignChartData = (
    dailySummaries: TradeSummary[],
    allDates: Date[]
): ChartDataPoint[] => {
    const dataPoints: ChartDataPoint[] = [];
    let cumulativeProfit = 0;

    // Create a map for quick access to daily profits by date
    const profitByDate = new Map<number, number>();
    dailySummaries.forEach((summary) => {
        const summaryDate = new Date(summary.range);
        const normalizedDate = new Date(summaryDate.getFullYear(), summaryDate.getMonth(), summaryDate.getDate());
        profitByDate.set(normalizedDate.getTime(), summary.profit);
    });

    allDates.forEach((date) => {
        const timestamp = date.getTime();
        if (profitByDate.has(timestamp)) {
            cumulativeProfit += profitByDate.get(timestamp) || 0;
        }
        dataPoints.push({
            x: date,
            y: cumulativeProfit,
        });
    });

    return dataPoints;
};

export default BacktestEquityChart;
