import {Alert} from "../../../contexts/ApplicationSuiteProvider";
import {Box, Typography} from "@mui/material";
import React from "react";
import PhoneNumberInput from "../../form/PhoneNumberInput";

function AlertLastChancePhoneNumberInputForm({alert, onAlertKeyChange}: { alert: Alert, onAlertKeyChange: (key: string, value: any) => void }) {

    return (
        <>
            <Box display={"flex"} flexDirection={"column"} gap={2}>
                <Typography variant={"body1"}>Text Message Notification</Typography>
                <Box display={"flex"} gap={1} px={4}>
                    <Typography variant={"caption"} flexGrow={1}>Phone Number:</Typography>
                    <PhoneNumberInput
                        phoneNumber={alert.user?.phoneNumber}
                        onPhoneNumberChange={(value) => {
                            onAlertKeyChange("user.phoneNumber", value)
                            const updatedAlertTypes = Array.from(new Set([...alert.alertTypes, "sms"]));
                            onAlertKeyChange("alertTypes", updatedAlertTypes);
                        }}
                    />
                </Box>
            </Box>
        </>
    )
}

export default AlertLastChancePhoneNumberInputForm
