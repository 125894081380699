import React from "react";
import { Box, Typography } from "@mui/material";
import TextFieldSelectInput from "./components/TextFieldSelectInput";
import FilterComponentParams from "./types/FilterComponentParams";

function NetProfitFilter(props: FilterComponentParams) {

    const {
        backtestFilters,
        setBacktestFilters
    } = props;

    const getMinPercentagePredefinedValues = () => {
        const values: Array<{ label: string, value: number }> = [
            { label: "No Min", value: Number.MIN_SAFE_INTEGER },
            { label: "-100%", value: -100 },
            { label: "0%", value: 0 }
        ];

        if (backtestFilters.maxProfitPercentage === Number.MAX_SAFE_INTEGER) {
            values.push(
                { label: "100%", value: 100 },
                { label: "200%", value: 200 },
                { label: "300%", value: 300 },
                { label: "400%", value: 400 },
                { label: "500%", value: 500 }
            );
        } else {
            for (let val = 6; val > 1; val--) {
                const value = backtestFilters.maxProfitPercentage - val * 50;

                if (value > -100 && value !== 0) {
                    values.push({ label: `${value}%`, value: value });
                }
            }
        }
        return values.sort((a, b) => a.value - b.value);
    };

    const getMaxPercentagePredefinedValues = () => {
        const values: Array<{ label: string, value: number }> = [
            { label: "No Max", value: Number.MAX_SAFE_INTEGER },
        ];

        if (backtestFilters.minProfitPercentage === Number.MIN_SAFE_INTEGER) {
            values.push(
                { label: "0%", value: 0 },
                { label: "100%", value: 100 },
                { label: "200%", value: 200 },
                { label: "300%", value: 300 },
                { label: "400%", value: 400 },
                { label: "500%", value: 500 }
            );
        } else {

            for (let val = 1; val <= 6; val++) {
                const value = backtestFilters.minProfitPercentage + val * 50;
                values.push({ label: `${value}%`, value: value });
            }
        }
        return values.sort((a, b) => a.value - b.value);
    };

    // const getMinDollarsPredefinedValues = () => {
    //     const values: Array<{ label: string, value: number }> = [
    //         { label: "No Min", value: Number.MIN_SAFE_INTEGER },
    //         { label: "$0", value: 0 }
    //     ];
    //
    //     if (backtestFilters.maxProfitDollars === Number.MAX_SAFE_INTEGER) {
    //         values.push(
    //             { label: "$100", value: 100 },
    //             { label: "$200", value: 200 },
    //             { label: "$300", value: 300 },
    //             { label: "$400", value: 400 },
    //             { label: "$500", value: 500 }
    //         );
    //     } else {
    //         for (let val = 6; val > 1; val--) {
    //             const value = backtestFilters.maxProfitDollars - val * 50;
    //
    //             if (value > 0) {
    //                 values.push({ label: `$${value}`, value: value });
    //             }
    //         }
    //     }
    //     return values.sort((a, b) => a.value - b.value);
    // };
    //
    // const getMaxDollarsPredefinedValues = () => {
    //     const values: Array<{ label: string, value: number }> = [
    //         { label: "No Max", value: Number.MAX_SAFE_INTEGER },
    //     ];
    //
    //     if (backtestFilters.minProfitDollars === Number.MIN_SAFE_INTEGER) {
    //         values.push(
    //             { label: "$0", value: 0 },
    //             { label: "$100", value: 100 },
    //             { label: "$200", value: 200 },
    //             { label: "$300", value: 300 },
    //             { label: "$400", value: 400 },
    //             { label: "$500", value: 500 }
    //         );
    //     } else {
    //
    //         for (let val = 1; val <= 6; val++) {
    //             const value = backtestFilters.minProfitDollars + val * 50;
    //             values.push({ label: `$${value}`, value: value });
    //         }
    //     }
    //     return values.sort((a, b) => a.value - b.value);
    // };

    // const handleApply = () => {
        // setBacktestFilters((prev: any) => ({
        //     ...prev,
        //     minProfitPercentage: backtestFilters.minProfitPercentage,
        //     maxProfitPercentage: backtestFilters.maxProfitPercentage,
        //     minProfitDollars: backtestFilters.minProfitDollars,
        //     maxProfitDollars: backtestFilters.maxProfitDollars
        // }));
        // handleClose();
    // };

    const onValueChanges = (key: string, value?: number) => {
        setBacktestFilters((prev: any) => ({
            ...prev,
            [key]: value
        }));
    }

    return (
        <>
            <Box p={2}>
                <Typography variant="body1" color={"textPrimary"} fontWeight={'bold'}>
                    Net Profit %
                </Typography>
                <Box display="flex" justifyContent="space-between" my={1}>
                    <Typography variant="body1" color={"textSecondary"}>Minimum</Typography>
                    <Typography variant="body1" color={"textSecondary"}>Maximum</Typography>
                </Box>

                <Box display="flex" alignItems="center" justifyContent="space-between" gap={2}>
                    <Box flexGrow={1}>
                        <TextFieldSelectInput
                            value={backtestFilters.minProfitPercentage === Number.MIN_SAFE_INTEGER ? '' : backtestFilters.minProfitPercentage}
                            type={'percentage'}
                            placeholder={'No Min'}
                            onChange={(val) => onValueChanges('minProfitPercentage', val)}
                            predefinedValues={getMinPercentagePredefinedValues()}
                        />
                    </Box>

                    <Typography color={"textPrimary"}>-</Typography>

                    <Box flexGrow={1}>
                        <TextFieldSelectInput
                            value={backtestFilters.maxProfitPercentage === Number.MAX_SAFE_INTEGER ? '' : backtestFilters.maxProfitPercentage}
                            type={'percentage'}
                            placeholder={'No Max'}
                            onChange={(val) => onValueChanges('maxProfitPercentage', val)}
                            predefinedValues={getMaxPercentagePredefinedValues()}
                        />
                    </Box>

                </Box>
            </Box>
        </>
    );
}

export default NetProfitFilter;
