import FilterButton from "./FilterButton";
import {Typography} from "@mui/material";
import React from "react";
import FilterComponentParams from "../filters/types/FilterComponentParams";
import NetProfitFilter from "../filters/NetProfitFilter";
import FilterHeaderSection from "../filters/components/FilterHeaderSection";

function NetProfitFilterButton(props: FilterComponentParams) {
    const {
        backtestFilters,
        setBacktestFilters
    } = props;


    const getLabel = () => {
        const isMinDefault = backtestFilters.minProfitPercentage === Number.MIN_SAFE_INTEGER;
        const isMaxDefault = backtestFilters.maxProfitPercentage === Number.MAX_SAFE_INTEGER;

        if (isMinDefault && isMaxDefault) {
            return "Profit";
        }


        let label = "";

        if (!isMinDefault && !isMaxDefault) {
            label += `${backtestFilters.minProfitPercentage}% to ${backtestFilters.maxProfitPercentage}%`;
        } else if (isMinDefault) {
            label += `Up to ${backtestFilters.maxProfitPercentage}%`;
        } else if (isMaxDefault) {
            label += `Above ${backtestFilters.minProfitPercentage}%`;
        }

        return <Typography variant="body2" color={"textPrimary"}>
            {label}
        </Typography>
    };

    return <>
        <FilterButton
            menu={
            <>
                <FilterHeaderSection
                    title={"Net Profit %"}
                    onClearSection={() => {
                        setBacktestFilters((prev: any) => ({
                            ...prev,
                            minProfitPercentage: Number.MIN_SAFE_INTEGER,
                            maxProfitPercentage: Number.MAX_SAFE_INTEGER,
                        }));
                    }}
                />

                <NetProfitFilter backtestFilters={backtestFilters} setBacktestFilters={setBacktestFilters}/>
            </>
            }
            label={getLabel()}
        />
    </>
}

export default NetProfitFilterButton;
