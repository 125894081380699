// Inspired by https://michalkotowski.pl/writings/how-to-refresh-a-react-component-when-local-storage-has-changed
import {useEffect, useState} from "react";

const useLocalStorage = <T extends any>(key: string, defaultValue: T) => {
    const [storage, _setStorage] = useState<T>(defaultValue);

    useEffect(() => {
        const handleStorage = () => {
            const data = localStorage.getItem(key);
            if (!data) {
                _setStorage(defaultValue);
                return;
            }
            _setStorage(JSON.parse(data));
        };

        handleStorage();
        window.addEventListener('storage', handleStorage);
        return () => window.removeEventListener('storage', handleStorage);

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [key]);

    const setStorage = (data: unknown) => {
        localStorage.setItem(key, JSON.stringify(data));
        window.dispatchEvent(new Event('storage'));
    };

    return [storage, setStorage];
};

export default useLocalStorage;
