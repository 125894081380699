import React, {createContext, useState, useContext, ReactNode, useEffect, useMemo} from 'react';
import {useAuthState} from "react-firebase-hooks/auth";
import {firebase_auth, firebase_firestore, firebase_functions} from "../common/firebaseConfig";
import {useCollectionData, useDocumentData} from "react-firebase-hooks/firestore";
import {collection, doc, limit, query, where} from "firebase/firestore";
import _cloneDeep from "lodash.clonedeep";
import {defaultPattern, Pattern} from "../app/builder/contexts/PatternContext";
import {useHttpsCallable} from "react-firebase-hooks/functions";
import {ISymbol} from "../common/components/dialogs/symbolSelectDialog/SymbolSelect";
import {useSearchParams} from "react-router-dom";
import {SnackbarHelperContext} from "./SnackbarHelperContext";
import {Tabs} from "../layout/MainLayout";
import {ModalProvider} from "./ModalProvider";
import TimeAgo from "javascript-time-ago";
import en from "javascript-time-ago/locale/en";

TimeAgo.addDefaultLocale(en)

type FixedOptions = {
    exitStrategy: 'fixed';
};

type AtrOptions = {
    exitStrategy: 'atr';
    atrPeriod: number,
    atrChartPeriod: number,
    atrChartTimeframe: string,
};

type PeakValleyOptions = {
    exitStrategy: 'peak_valley';
    fractalCount: number,
    maxPeakValleyLookback: number,
    priceOffset: number,
};

type IncrementalOptions = {
    exitStrategy: 'incremental';
    incrementalStart?: number;
    incrementalStop?: number;
    incrementalStep?: number;
};



const defaultATRExitStrategy: AtrOptions = {
    exitStrategy: "atr",
    atrChartPeriod: 5,
    atrChartTimeframe: "minute",
    atrPeriod: 15,
}

const defaultIncrementalExitStrategy: IncrementalOptions = {
    exitStrategy: "incremental",
    incrementalStart: .00050,
    incrementalStop: .00010,
    incrementalStep: .00010,
}

const defaultPeakValleyExitStrategy: PeakValleyOptions = {
    exitStrategy: "peak_valley",
    fractalCount: 2,
    maxPeakValleyLookback: 10,
    priceOffset: .00010,
}

const defaultFixedExitStrategy: FixedOptions = {
    exitStrategy: "fixed",
}

export const defaultInputSettings: any = {
    name: "Custom Settings",
    atrPeriod: 5,
    executionSymbol: "EURUSD",
    dateRange: {
        startDate: new Date(new Date().getTime() - (1440 * 60 * 1000 * 30)).setHours(0, 0, 0, 0),
        endDate: new Date().setHours(23, 59, 59, 999),
    },
    startTime: new Date().setHours(5, 0, 0,0),
    endTime: new Date().setHours(9, 0, 0,0),
    timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
    generateTrades: {
        ...defaultIncrementalExitStrategy,
        ...defaultPeakValleyExitStrategy,
        ...defaultFixedExitStrategy,
        ...defaultATRExitStrategy,
        exitStrategy: "atr",
        tradeRatios: [],
    },
    analyzeTrades: {
        equity: 10000,
        riskPerTrade: .01,
        commissions: 2,
        tradeLimit: 0,
        dailyTradeLimit: 0,
        allowHedging: false,
        forceExit: {},
        contractSize: 100000,
    },
    isPublic: true,
}

export type Alert = {
    id: string;
    name: string;
    alertTypes: ('email' | 'sms')[];
    timeRanges: {
        startTimeRange: string;
        endTimeRange: string;
        allowableCount?: number;
    }[];
    daysOfWeek: number[];
    pattern?: Pattern;
    active: boolean;
    user?: {
        phoneNumber?: string;
        email?: string;
        gcid: string;
    };
};

export const defaultAlert: Alert = {
    id: "",
    name: 'Custom Alert',
    alertTypes: ['email'],
    timeRanges: [],
    daysOfWeek: [1,2,3,4,5],
    active: true,
}

export type BacktestSortType = {
    field: string;
    direction: 'asc' | 'desc';
};

export type BacktestFilterType = {
    symbolSearch?: string;
    minProfitPercentage: number;
    maxProfitPercentage: number;
    minProfitDollars: number;
    maxProfitDollars: number;
    minAbsoluteDrawdownPercentage: number;
    maxAbsoluteDrawdownPercentage: number;
    minRelativeDrawdownPercentage: number;
    maxRelativeDrawdownPercentage: number;
    minDateRange: number;
    maxDateRange: number;
    daysBetween?: number;
    patternNameSearch: string;
    triggerChart?: {
        periodicity?: number;
        timeframe?: string;
    };
};

export const backtestFilterDefaultValues: BacktestFilterType = {
    symbolSearch: undefined,
    minProfitPercentage: Number.MIN_SAFE_INTEGER,
    maxProfitPercentage: Number.MAX_SAFE_INTEGER,
    minProfitDollars: Number.MIN_SAFE_INTEGER,
    maxProfitDollars: Number.MAX_SAFE_INTEGER,
    minAbsoluteDrawdownPercentage: Number.MIN_SAFE_INTEGER,
    maxAbsoluteDrawdownPercentage: Number.MAX_SAFE_INTEGER,
    minRelativeDrawdownPercentage: Number.MIN_SAFE_INTEGER,
    maxRelativeDrawdownPercentage: Number.MAX_SAFE_INTEGER,
    minDateRange: Number.MIN_SAFE_INTEGER,
    maxDateRange: Number.MAX_SAFE_INTEGER,
    daysBetween: undefined,
    patternNameSearch: '',
}

export type PatternFilterType = {
    symbol?: string;
    name?: string;
    timeframes?: string[];
    indicators?: string[];
};

type ApplicationSuiteContextType = {
    backtestSettingInputData: any;
    setBacktestSettingInputData: React.Dispatch<React.SetStateAction<any | null>>;
    activeTab: number;
    setActiveTab: React.Dispatch<React.SetStateAction<number>>;
    usermeta: any;
    runningBacktests: boolean;
    pattern: Pattern;
    setPattern: React.Dispatch<React.SetStateAction<Pattern>>;
    canShowcaseViewBacktestSettings: boolean;
    canViewPrivatePatterns: boolean;
    symbols: ISymbol[] | undefined;
    tradingHouse: string;
    setTradingHouse: React.Dispatch<React.SetStateAction<string>>;
    canToggleTradingHouse: boolean;
    tradingHouseObject: any;
    canPrivatePatterns: boolean;
    hasStarterTrialAccess: boolean;
    hasStarter: boolean;
    setShowReportCardDateTimeTooltips: React.Dispatch<React.SetStateAction<boolean>>;
    showReportCardDateTimeTooltips: boolean;
    isCreateAlertModalOpen: boolean;
    setIsCreateAlertModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
    savedBacktests: any[];
    canCreateAlerts: boolean;
    canActivateAlerts: boolean;
    canEditAlerts: boolean;
};

const ApplicationSuiteContext = createContext<ApplicationSuiteContextType | undefined>(undefined);

type ApplicationSuiteProviderProps = {
    children: ReactNode;
};

function ApplicationSuiteProvider({ children }: ApplicationSuiteProviderProps) {
    const [user] = useAuthState(firebase_auth);
    const [usermeta] = useDocumentData(doc(firebase_firestore, `usermeta/${user?.uid}`));
    const [runningBacktests] = useCollectionData(
        query(
            collection(firebase_firestore, `usermeta/${usermeta?.gcid}/backtests`),
            where("status", "==", "running"),
            limit(1)
        ));
    const [getSymbols] = useHttpsCallable(firebase_functions, 'getSymbols');
    const [searchParams] = useSearchParams();
    const [setSharedPatternOnAccount] = useHttpsCallable(firebase_functions, "setSharedPatternOnAccount")
    const {setSnackbarSettings} = useContext(SnackbarHelperContext);


    const [activeTab, setActiveTab] = useState<number>(Tabs.Dashboard);
    const [backtestSettingInputData, setBacktestSettingInputData] = useState<any>(defaultInputSettings);
    const [pattern, setPattern] = useState<Pattern>(_cloneDeep(defaultPattern));
    const [symbols, setSymbols] = useState<ISymbol[]>();
    const [isCreateAlertModalOpen, setIsCreateAlertModalOpen] = useState<boolean>(false);

    const [showReportCardDateTimeTooltips, setShowReportCardDateTimeTooltips] = useState<boolean>(false);

    const [canToggleTradingHouse, setCanToggleTradingHouse] = useState<boolean>(false);
    const [tradingHouse, setTradingHouse] = useState<string>(pattern?.tradingHouse ? pattern.tradingHouse : "greenchart");
    const [tradingHouseObject] = useDocumentData(doc(firebase_firestore, `tradingHouses/${tradingHouse}`));
    const [savedBacktests] = useCollectionData(query(collection(firebase_firestore, `usermeta/${usermeta?.gcid}/savedShowcase`), where("backtestSettings.tradingHouse", "==", tradingHouse))) as any[];
    const [alerts] = useCollectionData(query(collection(firebase_firestore, `tradingPlans`), where("user.gcid", "==", usermeta?.gcid || null))) as any[];

    // now - 7 days
    const oneWeek = new Date(new Date().getTime() - (1440 * 60 * 1000 * 60 * 24 * 7)).setHours(0, 0, 0, 0);

    const membershipStatus = useMemo(() => usermeta?.tradingHouses && usermeta.tradingHouses[tradingHouseObject?.name]?.currentStatus?.status, [usermeta, tradingHouseObject]);
    const isTrial = useMemo(() => membershipStatus === 'trial', [membershipStatus]);
    const isRegularMember = useMemo(() => membershipStatus === 'member' || membershipStatus === 'free', [membershipStatus]);
    const isGTFMember = useMemo(() => ((isTrial || isRegularMember) && tradingHouseObject?.name === 'gtf'), [isTrial, isRegularMember, tradingHouseObject]);
    const isAdmin = useMemo(() => usermeta?.isAdmin, [usermeta]);

    const hasAlertAccess = useMemo(() => usermeta?.products?.alerts, [usermeta]);
    const hasAdminAccess = useMemo(() => usermeta?.products?.alerts?.name?.toLowerCase().startsWith("admin"), [usermeta]);
    const hasProPlus = useMemo(() => usermeta?.products?.alerts?.name?.toLowerCase().startsWith("pro plus"), [usermeta]);
    const hasPro = useMemo(() => usermeta?.products?.alerts?.name?.toLowerCase().startsWith("pro"), [usermeta]);
    const hasStarter = useMemo(() => usermeta?.products?.alerts?.name?.toLowerCase().startsWith("starter") && !usermeta?.products?.alerts?.name?.toLowerCase().startsWith("starter plus"), [usermeta]);
    const hasBasic = useMemo(() => usermeta?.products?.alerts?.name?.toLowerCase().startsWith("basic"), [usermeta]);
    const hasStarterTrialAccess = useMemo(() => hasStarter && new Date(usermeta?.tradingHouses?.greenchart?.currentStatus?.timestamp?.seconds * 1000).getTime() > oneWeek, [hasStarter, usermeta?.tradingHouses?.greenchart?.currentStatus?.timestamp?.seconds, oneWeek]);

    const maxAlertCount = useMemo(() => hasAlertAccess ? usermeta?.products?.alerts.alertCount
        : isAdmin
            ? 10
            : 1, [hasAlertAccess, usermeta?.products?.alerts?.alertCount, isAdmin]);

    const canShowcaseViewBacktestSettings = useMemo(() => usermeta?.isAdmin || hasAdminAccess || hasProPlus || hasStarterTrialAccess, [usermeta, hasAdminAccess, hasProPlus, hasStarterTrialAccess]);
    const canViewPrivatePatterns = useMemo(() => usermeta?.isAdmin || hasAdminAccess || hasProPlus || hasPro || hasBasic || hasStarterTrialAccess, [usermeta, hasAdminAccess, hasProPlus, hasPro, hasBasic, hasStarterTrialAccess]);
    const canPrivatePatterns = useMemo(() => usermeta?.isAdmin || hasAdminAccess || hasProPlus, [usermeta, hasAdminAccess, hasProPlus]);
    const canCreateAlerts = useMemo(() => usermeta?.isAdmin || ((hasAlertAccess || isGTFMember) && (alerts?.length || 0) < 50), [usermeta, hasAlertAccess, isGTFMember, alerts]);
    const canActivateAlerts = useMemo(() => (alerts?.filter((alert: Alert) => alert.active).length || 0) < maxAlertCount, [alerts, maxAlertCount]);
    const canEditAlerts = useMemo(() => usermeta?.isAdmin || hasAlertAccess, [usermeta, hasAlertAccess]);

    useEffect(() => {
        if (!usermeta) return;

        defaultAlert.user = {
            phoneNumber: '',
            email:  usermeta.alertEmail || usermeta.email || "",
            gcid: usermeta.gcid,
        };

        if (canEditAlerts) {
            const defaultTimeRange = new Date()
            defaultTimeRange.setHours(24, 0, 0, 0)
            const defaultTime = `${("0"+defaultTimeRange.getUTCHours()).slice(-2)}:${("0"+defaultTimeRange.getUTCMinutes()).slice(-2)}`
            defaultAlert.timeRanges = [{startTimeRange: defaultTime, endTimeRange: defaultTime}]
        } else {
            defaultAlert.timeRanges = [
                {startTimeRange: '14:00', endTimeRange: '18:00', allowableCount: 1}, // 8am mt - 12pm mt
                {startTimeRange: '18:00', endTimeRange: '22:00', allowableCount: 1}, // 12pm mt - 4pm mt
                {startTimeRange: '22:00', endTimeRange: '02:00', allowableCount: 1}, // 4pm mt - 8pm mt
            ]
        }

        if (usermeta.alertPhoneNumber && usermeta.alertPhoneNumber.phoneNumber && usermeta.alertPhoneNumber.verified) {
            defaultAlert.user.phoneNumber = usermeta.alertPhoneNumber.phoneNumber
            defaultAlert.alertTypes = [...defaultAlert.alertTypes, 'sms']
        } else if (usermeta.phoneNumber && usermeta.phoneNumber.phoneNumber && usermeta.phoneNumber.verified) {
            defaultAlert.user.phoneNumber = usermeta.phoneNumber.phoneNumber
            defaultAlert.alertTypes = [...defaultAlert.alertTypes, 'sms']
        }

    }, [usermeta, canEditAlerts]);

    useEffect(() => {
        if (usermeta) {
            setTradingHouse(["member", "free", "trial"].includes(usermeta.tradingHouses?.gtf?.currentStatus?.status) ? "gtf" : "greenchart");

            const canToggleTradingHouse = usermeta.isAdmin || usermeta.tradingHouses?.gtf?.currentStatus?.status === "free";
            setCanToggleTradingHouse(canToggleTradingHouse);

            if (canToggleTradingHouse) {
                const tradingHouse = localStorage.getItem("tradingHouse")
                if (tradingHouse) {
                    setTradingHouse(tradingHouse);
                }
            }
        }
    }, [usermeta]);

    useEffect(() => {
        if (!canActivateAlerts) {
            defaultAlert.active = false;
        }
    }, [canActivateAlerts]);

    useEffect(() => {
        getSymbols().then((symbols: any) => {
            setSymbols(symbols.data.map((symbol: any) => ({...symbol, name: symbol.displaySymbol})));
        });
    }, [getSymbols]);

    useEffect(() => {
        setPattern(defaultPattern);
        setBacktestSettingInputData((prev: any) => ({
            ...prev,
            patternId: undefined,
        }));
    }, [tradingHouse]);

    useEffect(() => {
        const pid = extractPatternId();
        if (!pid || !pid.length) return;

        setSnackbarSettings({message: 'Importing pattern onto your account...', severity: 'info', autoHideDuration: 2000});
        void setSharedPatternOnAccount({linkId: pid}).then((res: any) => {
            if (!res?.data?.pattern) {
                setSnackbarSettings({message: res?.data?.message ? res.data.message : 'There was a problem importing the pattern onto your account!', severity: 'error', autoHideDuration: 6000});
                return;
            }

            setPattern(res.data.pattern);
            setSnackbarSettings({message: 'Pattern added to your account!', severity: 'success', autoHideDuration: 6000});
        }).catch((error: any) => {
            console.error("Error:", error);
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    function extractPatternId() {
        const pid = searchParams.get('pid');
        searchParams.delete('pid');
        return pid;
    }

    if (!user || !usermeta) return null;

    return (
        <ApplicationSuiteContext.Provider value={{
            canShowcaseViewBacktestSettings,
            canViewPrivatePatterns,
            canPrivatePatterns,
            activeTab,
            setActiveTab,
            usermeta,
            backtestSettingInputData,
            setBacktestSettingInputData,
            runningBacktests: (runningBacktests?.length || 0) > 0,
            pattern,
            setPattern,
            symbols,
            tradingHouse,
            setTradingHouse,
            canToggleTradingHouse,
            tradingHouseObject,
            hasStarterTrialAccess,
            hasStarter,
            setShowReportCardDateTimeTooltips,
            showReportCardDateTimeTooltips,
            isCreateAlertModalOpen,
            setIsCreateAlertModalOpen,
            savedBacktests,
            canCreateAlerts,
            canActivateAlerts,
            canEditAlerts,
        }}>
            <ModalProvider>
                {children}
            </ModalProvider>
        </ApplicationSuiteContext.Provider>
    );
}

function useApplicationSuiteContext(): ApplicationSuiteContextType {
    const context = useContext(ApplicationSuiteContext);
    if (!context) {
        throw new Error('useApplicationSuite must be used within an ApplicationSuiteProvider');
    }
    return context;
}

export { ApplicationSuiteProvider, useApplicationSuiteContext };

