import FilterButton from "./FilterButton";
import {Box, Typography} from "@mui/material";
import React from "react";
import FilterComponentParams from "../filters/types/FilterComponentParams";
import AbsoluteDrawdownFilter from "../filters/AbsoluteDrawdownFilter";
import RelativeDrawdownFilter from "../filters/RelativeDrawdownFilter";
import FilterHeaderSection from "../filters/components/FilterHeaderSection";

function DrawdownFilterButton(props: FilterComponentParams) {

    const {
        backtestFilters,
        setBacktestFilters
    } = props;


    const getRelLabel = () => {
        const isMinRelativeDefault = backtestFilters.minRelativeDrawdownPercentage === Number.MIN_SAFE_INTEGER;
        const isMaxRelativeDefault = backtestFilters.maxRelativeDrawdownPercentage === Number.MAX_SAFE_INTEGER;

        if (isMinRelativeDefault && isMaxRelativeDefault) {
            return null;
        }

        let label = "";

        if (!isMinRelativeDefault && !isMaxRelativeDefault) {
            label += `Rel: ${backtestFilters.minRelativeDrawdownPercentage}% to ${backtestFilters.maxRelativeDrawdownPercentage}%`;
        } else if (!isMinRelativeDefault) {
            label += `Rel: ${backtestFilters.minRelativeDrawdownPercentage}%+`;
        } else if (!isMaxRelativeDefault) {
            label += `Rel: Up to ${backtestFilters.maxRelativeDrawdownPercentage}%`;
        }

        return label;
    }

    const getAbsLabel = () => {
        const isMinAbsoluteDefault = backtestFilters.minAbsoluteDrawdownPercentage === Number.MIN_SAFE_INTEGER;
        const isMaxAbsoluteDefault = backtestFilters.maxAbsoluteDrawdownPercentage === Number.MAX_SAFE_INTEGER;


        if (isMinAbsoluteDefault && isMaxAbsoluteDefault) {
            return null;
        }

        let label = "";

        if (!isMinAbsoluteDefault && !isMaxAbsoluteDefault) {
            label += `Abs: ${backtestFilters.minAbsoluteDrawdownPercentage}% to ${backtestFilters.maxAbsoluteDrawdownPercentage}%`;
        } else if (!isMinAbsoluteDefault) {
            label += `Abs: ${backtestFilters.minAbsoluteDrawdownPercentage}%+`;
        } else if (!isMaxAbsoluteDefault) {
            label += `Abs: Up to ${backtestFilters.maxAbsoluteDrawdownPercentage}%`;
        }

        return label;

        // if (isMinDefault && isMaxDefault) {
        //     return "Drawdown";
        // }
        //
        // if (isMinDefault) {
        //     return `Up to ${backtestFilters.maxAbsoluteDrawdownPercentage}%`;
        // }
        //
        // if (isMaxDefault) {
        //     return `${backtestFilters.minAbsoluteDrawdownPercentage}%+`;
        // }
        //
        // return `${backtestFilters.minAbsoluteDrawdownPercentage}% to ${backtestFilters.maxAbsoluteDrawdownPercentage}%`;
    };

    const getLabel = () => {
        const absLabel = getAbsLabel();
        const relLabel = getRelLabel();

        if (!absLabel && !relLabel) {
            return "Drawdown";
        }

        return (
            <Box>
                <Typography variant="body2" color={"textPrimary"}>
                    {absLabel}
                </Typography>
                <Typography variant="body2" color={"textPrimary"}>
                    {relLabel}
                </Typography>
            </Box>

        )
    }

    return <>
        <FilterButton
            menu={
            <>
                <FilterHeaderSection
                    title={"Absolute Drawdown %"}
                    onClearSection={() => {
                        setBacktestFilters((prev: any) => ({
                            ...prev,
                            minAbsoluteDrawdownPercentage: Number.MIN_SAFE_INTEGER,
                            maxAbsoluteDrawdownPercentage: Number.MAX_SAFE_INTEGER,
                        }));
                    }}
                />

                <AbsoluteDrawdownFilter backtestFilters={backtestFilters} setBacktestFilters={setBacktestFilters}/>

                <FilterHeaderSection
                    title={"Relative Drawdown %"}
                    onClearSection={() => {
                        setBacktestFilters((prev: any) => ({
                            ...prev,
                            minRelativeDrawdownPercentage: Number.MIN_SAFE_INTEGER,
                            maxRelativeDrawdownPercentage: Number.MAX_SAFE_INTEGER,
                        }));
                    }}
                />
                <RelativeDrawdownFilter backtestFilters={backtestFilters} setBacktestFilters={setBacktestFilters}/>
            </>}
            label={getLabel()}
        />
    </>
}

export default DrawdownFilterButton;
