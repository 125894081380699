import { Chip } from "@mui/material";
import React, { useState, useRef, useEffect } from "react";

type ActionChipProps = {
    label: string;
    icon?: React.ReactElement;
    hoveringIcon?: React.ReactElement;
    onClick: (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => void;
    hoverImmediately?: boolean;
    sx?: object;
    goToWidth?: string;
    forceOpen?: boolean;
} & React.ComponentProps<typeof Chip>;

function ActionChip({
                        label,
                        icon,
                        hoveringIcon,
                        hoverImmediately,
                        onClick,
                        sx,
                        goToWidth = "36px",
                        forceOpen = false,
                        ...rest
                    }: ActionChipProps) {
    const [isOpen, setIsOpen] = useState(forceOpen);
    const closingTimer = useRef<NodeJS.Timeout | null>(null);
    const openingTimer = useRef<NodeJS.Timeout | null>(null);

    useEffect(() => {
        setIsOpen(forceOpen);
    }, [forceOpen]);

    // Handle mouse enter event
    const handleMouseEnter = () => {
        if (!forceOpen) {
            // Cancel any pending close action
            if (closingTimer.current) {
                clearTimeout(closingTimer.current);
                closingTimer.current = null;
            }

            if (hoverImmediately) {
                setIsOpen(true);
            } else {
                // Start a timer to delay opening
                openingTimer.current = setTimeout(() => {
                    setIsOpen(true);
                    openingTimer.current = null;
                }, 100); // Delay in milliseconds
            }
        }
    };

    // Handle mouse leave event
    const handleMouseLeave = () => {
        if (!forceOpen) {
            // Cancel any pending open action
            if (openingTimer.current) {
                clearTimeout(openingTimer.current);
                openingTimer.current = null;
            }

            if (hoverImmediately) {
                setIsOpen(false);
            } else {
                // Start a timer to delay closing
                closingTimer.current = setTimeout(() => {
                    setIsOpen(false);
                    closingTimer.current = null;
                }, 400); // Delay in milliseconds
            }
        }
    };

    // Cleanup on unmount
    useEffect(() => {
        return () => {
            if (closingTimer.current) {
                clearTimeout(closingTimer.current);
            }
            if (openingTimer.current) {
                clearTimeout(openingTimer.current);
            }
        };
    }, []);

    if (!forceOpen) {
        label = rest.size === 'small' ? '' : label;
        goToWidth = rest.size === 'small' ? '36px' : goToWidth;
    }

    return (
        <Chip
            icon={isOpen ? hoveringIcon || icon : icon}
            label={label}
            onClick={(e) => {
                e.stopPropagation();

                if (onClick) {
                    onClick(e);
                }
            }}
            variant="outlined"
            color="primary"
            onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseLeave}
            sx={{
                overflow: "hidden",
                width: isOpen ? goToWidth : "36px",
                transition: "width 0.3s ease",
                ".MuiSvgIcon-root": {
                    margin: 0,
                },
                ".MuiChip-label": {
                    opacity: isOpen ? 1 : 0,
                    transition: "opacity 0.3s ease",
                    width: isOpen ? "auto" : 0,
                    padding: isOpen && label?.trim() !== '' ? "0 8px" : 0,
                },
                ...sx, // Merge additional styles passed as props
            }}
            {...rest} // Pass down all other Chip props
        />
    );
}

export default ActionChip;
