import {Typography} from "@mui/material";
import Box from "@mui/material/Box";
import React from "react";
import {useApplicationSuiteContext} from "../../../contexts/ApplicationSuiteProvider";
import {useCollectionData} from "react-firebase-hooks/firestore";
import {collection, orderBy, query, where} from "firebase/firestore";
import {firebase_firestore} from "../../../common/firebaseConfig";
import ReportCardList from "../../../components/backtests/ReportCardList";
import SummarizeIcon from "@mui/icons-material/Summarize";

function RunningBacktestsView() {

    const {
        usermeta,
        tradingHouse
    } = useApplicationSuiteContext();

    const [backtests] = useCollectionData(
        query(
            collection(firebase_firestore, `usermeta/${usermeta.gcid}/backtests`),
            where("backtestSettings.tradingHouse", "==", tradingHouse),
            where('status', 'in', ['running', 'error']),
            orderBy("startTime", "desc"),
        )
    );

    function renderBacktests() {
        return <>
            <Box display={'flex'} alignItems={'center'} gap={1} color="white" px={1}>
                <SummarizeIcon fontSize={'medium'}/>
                <Typography variant={'h6'} >
                    Running Backtests
                </Typography>
            </Box>

            <Box display={'flex'} flexDirection={'column'}
                 px={.5}
                 sx={{
                     overflowY: 'auto',
                 }}>
                <ReportCardList backtests={backtests} condensed={true}/>
            </Box>
        </>
    }

    return backtests && backtests.length ? renderBacktests() : null

}

export default RunningBacktestsView;
