import React, {useMemo} from "react";
import ActionChip from "./ActionChip";
import {useApplicationSuiteContext} from "../../contexts/ApplicationSuiteProvider";
import {collection, doc, setDoc, deleteDoc} from "firebase/firestore";
import {firebase_firestore} from "../../common/firebaseConfig";
import { BookmarkAdd, BookmarkAdded } from "@mui/icons-material";
import useRecentlyViewedBacktests from "../../hooks/useRecentlyViewedBacktests";

function FavoriteBacktestActionChip({ onClick, backtest, ...otherProps }: any) {
    const {
        usermeta,
        savedBacktests,
    } = useApplicationSuiteContext();

    const [, setRecentlyViewedBacktests] = useRecentlyViewedBacktests();

    function favoriteBacktest(event: any) {
        event.stopPropagation();

        if (isFavorite) {
            handleRemoveFromSavedShowcase();
        } else {
            setRecentlyViewedBacktests(backtest);
            handleAddToSavedShowcase();
        }

        if (onClick) {
            onClick();
        }
    }

    const isFavorite = useMemo(() => {
        return savedBacktests?.some((savedBacktest: any) => savedBacktest.id === backtest.id);
    }, [savedBacktests, backtest.id]);

    const handleAddToSavedShowcase = async () => {
        const savedShowcaseRef = collection(firebase_firestore, `usermeta/${usermeta.gcid}/savedShowcase`);
        try {
            await setDoc(doc(savedShowcaseRef, backtest.id), backtest);
        } catch (error) {
            console.error("Error adding document: ", error);
        }
    };

    const handleRemoveFromSavedShowcase = async () => {
        const savedShowcaseRef = collection(firebase_firestore, `usermeta/${usermeta.gcid}/savedShowcase`);
        await deleteDoc(doc(savedShowcaseRef, backtest.id));
    };

    return (
        <>
            <ActionChip
                label=""
                icon={isFavorite ? <BookmarkAdded/> : <BookmarkAdd />}
                hoveringIcon={isFavorite ? <BookmarkAdded /> : <BookmarkAdd color={'primary'}/>}
                hoverImmediately
                onClick={favoriteBacktest}
                color={isFavorite ? 'primary' : 'lightgray'}
                {...otherProps}
            />
        </>

    );
}

export default FavoriteBacktestActionChip;
