import React from "react";
import {TextField } from "@mui/material";
import {useTheme} from "@mui/material/styles";

type EmailInputProps = {
    email?: string; // Optional email value
    onEmailChange: (value: string) => void; // Callback to handle changes to the email
    [key: string]: any; // Additional props
};

function EmailInput({email, onEmailChange, ...props}: EmailInputProps) {
    const theme = useTheme()

    return (
    <TextField
        value={email || ""}
        onChange={(e) => onEmailChange(e.target.value)}
        placeholder="Email"
        type="email"
        variant="outlined"
        fullWidth
        InputProps={{
            sx: {
                height: "40px",
                color: theme.palette.text.primary,
                '&.Mui-disabled .MuiOutlinedInput-notchedOutline': {borderColor: theme.palette.divider}
            }
        }}
        {...props}
    />
    )
}

export default EmailInput;
