import React, {useCallback, useEffect, useRef} from "react";
import BacktestReportCard from "./BacktestReportCard";
import Box from "@mui/material/Box";
import {useTheme} from "@mui/material/styles";
import {Skeleton, Typography} from "@mui/material";

type ReportCardListProps = {
    backtests?: any[];
    condensed?: boolean;
    loadMore?: () => void;
    isLoading?: boolean;
    totalCount?: number;
    emptyListText?: {
        title: string;
        subTitle?: string;
    };
    selectable?: boolean;
    onSelected?: (selected: any) => void;
    selectedBacktestId?: any;
}

function ReportCardList({backtests, condensed, loadMore, isLoading, totalCount, emptyListText, selectable, onSelected, selectedBacktestId}: ReportCardListProps) {
    const theme = useTheme();
    const containerRef = useRef<HTMLDivElement>(null); // Create a ref for the container

    const width = condensed ? selectable ? 300 : 250 : 350;
    const height = condensed ? 100 : 310;

    // Scroll detection logic
    const handleScroll = useCallback(() => {
        if (!loadMore || isLoading || totalCount === backtests?.length) return;

        const container = containerRef.current;
        if (container) {
            // Check if scrolled to the bottom
            const isBottom = container.scrollHeight - container.scrollTop <= container.clientHeight;
            if (isBottom) {
                loadMore(); // Call the passed loadMore function
            }
        }
    }, [loadMore, isLoading, totalCount, backtests?.length]);

    // Check if the container is scrollable and load more if not
    const checkScrollable = useCallback(() => {
        if (!loadMore || isLoading || totalCount === backtests?.length) return;

        const container = containerRef.current;
        if (container && container.scrollHeight <= container.clientHeight) {
            loadMore();
        }
    }, [loadMore, isLoading, totalCount, backtests?.length]);

    // Attach scroll event listener
    useEffect(() => {
        const container = containerRef.current;
        if (container) {
            container.addEventListener("scroll", handleScroll);
        }

        checkScrollable(); // Check if container is scrollable after the first render

        return () => {
            if (container) {
                container.removeEventListener("scroll", handleScroll);
            }
        };
    }, [backtests, handleScroll, checkScrollable]);

    function renderSkeletons() {
        const screenWidth = window.innerWidth;
        const componentsPerRow = Math.floor(screenWidth / width);

        return Array.from({length: componentsPerRow}).map((_, index) => {
            return <Skeleton
                key={index}
                variant="rounded"
                animation="wave"
                height={height}
            ></Skeleton>
        })
    }

    function renderBacktests() {
        return backtests && backtests.length && backtests.map((backtest: any) => {
            return <BacktestReportCard key={backtest.id} backtest={backtest} condensed={condensed} selectable={selectable} onSelected={onSelected} isSelected={backtest.pattern?.id === selectedBacktestId}/>
        })
    }

    function renderNoBacktests() {
        return <Box display={'flex'} justifyContent={'center'} alignItems={'center'} height={'100%'} width={'100%'}>
            <Box display={'flex'} flexDirection={'column'} alignItems={'center'}>
                <Typography variant={'h5'} color={"textSecondary"}>{emptyListText?.title}</Typography>
                {emptyListText?.subTitle && <Typography variant={'body2'} color={"textSecondary"}>{emptyListText.subTitle}</Typography>}
            </Box>
        </Box>
    }

    return (
        <Box
            p={1}
            sx={{
                 backgroundColor: theme.palette.background.custom2,
                 borderRadius: '4px',
                 display: 'flex',
                 flexDirection: 'column',
                 overflowY: 'hidden',
            }}>
            <Box
                sx={{
                    overflowY: 'auto',
                    display: 'flex',
                    flexDirection: 'column',

                }}
            >
                <Box
                    sx={{overflowY: "auto" }}
                    borderRadius={1}
                    height={"100%"}
                    ref={containerRef} // Attach the ref to the container
                >
                    {backtests?.length === 0 && !isLoading && renderNoBacktests()}
                    <Box display={'grid'} gap={1} gridTemplateColumns={`repeat(auto-fill, minmax(${width}px, 1fr))`}>
                        {backtests && backtests.length > 0 && renderBacktests()}
                        {isLoading && renderSkeletons()}
                    </Box>
                    {/*{isLoading && renderLoading()}*/}
                </Box>
            </Box>
        </Box>

    )
}

export default ReportCardList;
