import {
    Autocomplete, Chip, createFilterOptions, Grid, TextField
} from "@mui/material";
import React from "react";
import ExitStrategiesProps from "./ExitStrategiesProps";

const filter = createFilterOptions<any>();

export default function RiskRewardInput(props: ExitStrategiesProps) {
    const {backtestSettingInputData, handleInputChange, formErrors} = props;

    const riskRewardOptions: any[] = Array.from({ length: 19 }, (_, i) => {
        i++;
        const label = i <= 10 ? `1/${11 - i}` : `${i - 9}/1`;
        return { label };
    });

    function verifyRiskRewardRatio(ratio: string) {
        const onlyNumbersLeftAndRightOfBackslash = new RegExp(/^(\d{1,3}(,\d{3})*(\.\d+)?|\d*\.?\d+)\/(\d{1,3}(,\d{3})*(\.\d+)?|\d*\.?\d+)/);
        return onlyNumbersLeftAndRightOfBackslash.test(ratio)
    }

    const handleKeyPress = (event: any) => {
        if(event.key === 'Enter') {
            if (verifyRiskRewardRatio(event.target.value)) {
                const [risk, reward] = event.target.value.split('/');
                handleInputChange('generateTrades.tradeRatios', [
                    ...backtestSettingInputData.generateTrades.tradeRatios,
                    { risk: parseFloat(risk), reward: parseFloat(reward) },
                ]);
            }
        }
    }

    return (
        <>
            <Grid item xs={6}>
                <Autocomplete
                    multiple
                    disableCloseOnSelect={true}
                    id="tags-filled"
                    onChange={(event, newValue: any) => {
                        newValue = newValue
                            .filter((val: any) => verifyRiskRewardRatio(val.inputValue ? val.inputValue : val))
                            .map((val: any) => {
                                if (typeof val === 'string') {
                                    return {
                                        risk: parseFloat(val.split("/")[0]),
                                        reward: parseFloat(val.split("/")[1])
                                    }
                                } else {
                                    let inputValue = val.inputValue;
                                    return {
                                        risk: parseFloat(inputValue.split("/")[0]),
                                        reward: parseFloat(inputValue.split("/")[1])
                                    }
                                }
                            })
                        handleInputChange("generateTrades.tradeRatios", newValue)
                    }}
                    options={riskRewardOptions.map((option) => option.label)}
                    value={backtestSettingInputData.generateTrades.tradeRatios.map((option: any) => `${option.risk}/${option.reward}`)}
                    renderTags={(value: readonly string[], getTagProps) =>
                        value.map((option: string, index: number) => (
                            <Chip variant="outlined" label={option} {...getTagProps({ index })}  key={`${option}_${index}`}/>
                        ))
                    }
                    getOptionLabel={(option) => (typeof option === 'string' ? option : option.title)}
                    filterOptions={(options, params) => {
                        const filtered = filter(options, params);

                        const { inputValue } = params;
                        // Suggest the creation of a new value
                        const isExisting = options.some((option) => inputValue === `${option.risk}/${option.reward}`);
                        if (inputValue !== '' && !isExisting && verifyRiskRewardRatio(inputValue)) {
                            filtered.push({
                                inputValue,
                                title: `Add "${inputValue}"`,
                            });
                        }

                        return filtered;
                    }}
                    renderInput={(params) => (
                        <TextField
                            {...params}
                            variant="outlined"
                            label="Risk / Reward"
                            error={!!formErrors.generateTrades?.tradeRatios}
                            helperText={formErrors.generateTrades?.tradeRatios}
                            placeholder=""
                            onKeyPress={handleKeyPress}
                        />
                    )}
                />
            </Grid>
        </>
    )
}
