import Application from "../../../layout/Application";
import {BacktestReportProvider, useBacktestReportContext} from "../contexts/BacktestReportContext";
import Box from "@mui/material/Box";
import { useTheme } from "@mui/material/styles";
import BacktestReportSidebar from "./BacktestReportSidebar";
import { useHttpsCallable } from "react-firebase-hooks/functions";
import ReportCardList from "../../../components/backtests/ReportCardList";
import BacktestFilterView from "../../../components/backtests/filter/BacktestFilterView";
import { useApplicationSuiteContext } from "../../../contexts/ApplicationSuiteProvider";
import {firebase_functions} from "../../../common/firebaseConfig";
import usePaginatedLoader from "../../../hooks/usePaginatedLoader";
import React, {useCallback} from "react";

function BacktestReportApp() {
    const theme = useTheme();

    const {
        tradingHouse,
    } = useApplicationSuiteContext();

    const {
        backtestFilters,
        backtestSort,
        type,
    } = useBacktestReportContext();

    const [getBacktests] = useHttpsCallable(firebase_functions, "getBacktests");

    const func = useCallback(
        (params: any) => getBacktests({ ...params, tradingHouse, backtestFilters: backtestFilters, sort: backtestSort, type }),
        [getBacktests, tradingHouse, backtestFilters, backtestSort, type]
    );

    const { data: backtests, loadMore, isLoading, totalCount } = usePaginatedLoader({
        queryFunction: func,
        take: 15,
    });

    return (
        <Box sx={{ background: theme.palette.background.custom1, overflowY: "hidden" }} height={"100%"} display={"flex"} flexDirection={"column"} gap={1}>
            <BacktestFilterView />
            <ReportCardList backtests={backtests}
                            loadMore={loadMore}
                            isLoading={isLoading}
                            totalCount={totalCount}
                            emptyListText={{
                                title: "No backtests found",
                                subTitle: "Try adjusting your filters or run a new backtest"
                            }}
            />
        </Box>
    );
}

const app = ({ tab }: any) => (
    <Application AppComponent={BacktestReportApp} SidebarComponent={BacktestReportSidebar} ContextProviders={[BacktestReportProvider]} tab={tab} />
);
export default app;
