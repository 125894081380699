import {Alert} from "../../../contexts/ApplicationSuiteProvider";
import {Box, TextField, Typography} from "@mui/material";
import DaySelect from "../../form/DaySelect";
import AlertNotificationTypeSelect from "../../form/AlertNotificationTypeSelect";
import React from "react";

function AlertNotificationForm({alert, onAlertKeyChange}: { alert: Alert, onAlertKeyChange: (key: string, value: any) => void }) {

    return (
        <>
            <Box display={"flex"} flexDirection={"column"} gap={2} height={440}>
                <Typography variant={"body2"}>Custom Alert Name</Typography>
                <TextField
                    label={"Alert Name"}
                    value={alert.name}
                    InputProps={{ sx: { height: "40px"} }}
                    onChange={(event) => onAlertKeyChange("name", event.target.value)}
                />

                <Typography variant={"body2"}>Alert Notification Days</Typography>
                <DaySelect
                    days={alert.daysOfWeek}
                    onDaysChange={(days: number[]) => onAlertKeyChange("daysOfWeek", days)}
                />

                <Typography variant={"body2"}>Notification Preference</Typography>
                <AlertNotificationTypeSelect
                    alert={alert}
                    onNotificationTypeChange={(key, value) => onAlertKeyChange(key, value)}
                />
            </Box>
        </>
    )
}

export default AlertNotificationForm
