import React, {createContext, useContext, ReactNode, useState} from 'react';
import {
    backtestFilterDefaultValues,
    BacktestFilterType, BacktestSortType,
} from "../../../contexts/ApplicationSuiteProvider";

const backtestSortDefaultValue: BacktestSortType = {
    field: 'summary.grades.grade',
    direction: 'desc',
};

interface ShowcaseContextProps {
    backtestFilters: BacktestFilterType;
    setBacktestFilters: React.Dispatch<React.SetStateAction<BacktestFilterType>>;
    backtestSort: BacktestSortType;
    setBacktestSort: React.Dispatch<React.SetStateAction<BacktestSortType>>;
    type: 'showcase_favorites' | 'showcase';
    setType: React.Dispatch<React.SetStateAction<'showcase_favorites' | 'showcase'>>;
}

// Create the contexts
const ShowcaseContext = createContext<ShowcaseContextProps | undefined>(undefined);

interface Props {
    children: ReactNode;
}

export const ShowcaseProvider: React.FC<Props> = ({ children }) => {

    const [backtestFilters, setBacktestFilters] = useState<BacktestFilterType>(backtestFilterDefaultValues);
    const [backtestSort, setBacktestSort] = useState<BacktestSortType>(backtestSortDefaultValue);
    const [type, setType] = useState<'showcase_favorites' | 'showcase'>('showcase');

    return (
        <ShowcaseContext.Provider value={{
            backtestFilters,
            setBacktestFilters,
            backtestSort,
            setBacktestSort,
            type,
            setType,
        }}>
            {children}
        </ShowcaseContext.Provider>
    );
}

// Custom hook to use this contexts
export const useShowcaseContext = (): ShowcaseContextProps => {
    const context = useContext(ShowcaseContext);
    if (!context) {
        throw new Error('useShowcaseContext must be used within a ShowcaseProvider');
    }
    return context;
}
