import React from "react";
import ActionChip from "./ActionChip";
import NotificationAddIcon from '@mui/icons-material/NotificationAdd';
import {useModalContext} from "../../contexts/ModalProvider";
import flattenPattern from "../../common/utils/flatternPattern";
import _cloneDeep from "lodash.clonedeep";
import {Pattern} from "../../app/builder/contexts/PatternContext";
import {useApplicationSuiteContext} from "../../contexts/ApplicationSuiteProvider";

function CreateAlertActionChip({ onClick, backtest, pattern, ...otherProps }: any) {

    const {
        canCreateAlerts
    } = useApplicationSuiteContext()

    const {
        setIsCreateAlertModalOpen,
        setDefaultAlert
    } = useModalContext()

    function openAlertModal() {
        setIsCreateAlertModalOpen(true);

        const _pattern: Pattern = backtest ? _cloneDeep(backtest.pattern) : _cloneDeep(pattern);

        if (backtest) {
            const conditions = flattenPattern(_pattern);

            for (const condition of conditions) {
                condition.chartDetails.symbol = backtest.backtestSettings.executionSymbol;
            }

            _pattern.trigger.chartDetails.symbol = backtest.backtestSettings.executionSymbol;
        }

        setDefaultAlert((prev) => {
            return {
                ...prev,
                pattern: _pattern,
                name: `${_pattern.trigger.chartDetails.symbol} - ${_pattern.name}`
            }
        })

        if (onClick) {
            onClick();
        }
    }

    return (
        <>
            <ActionChip
                label="Create Alert"
                icon={<NotificationAddIcon />}
                goToWidth="120px"
                onClick={openAlertModal}
                disabled={!canCreateAlerts}
                {...otherProps}
            />
        </>


    );
}

export default CreateAlertActionChip;
