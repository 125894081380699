import FilterButton from "./FilterButton";
import {Box, Typography} from "@mui/material";
import React from "react";
import FilterComponentParams from "../filters/types/FilterComponentParams";
import DateRangeFilter from "../filters/DateRangeFilter";
import FilterHeaderSection from "../filters/components/FilterHeaderSection";
import DaysBacktestedFilter from "../filters/DaysBacktestedFilter";

function DateRangeFilterButton(props: FilterComponentParams) {
    const {
        backtestFilters,
        setBacktestFilters
    } = props;

    const getLabel = () => {
        const isMinDefault = backtestFilters.minDateRange === Number.MIN_SAFE_INTEGER;
        const isMaxDefault = backtestFilters.maxDateRange === Number.MAX_SAFE_INTEGER;

        if (isMinDefault && isMaxDefault) {
            return "Date Range";
        }

        let startDateLabel = null;
        let endDateLabel = null;

        if (!isMinDefault && !isMaxDefault) {
            startDateLabel = new Date(backtestFilters.minDateRange).toLocaleDateString();
            endDateLabel = new Date(backtestFilters.maxDateRange).toLocaleDateString();
        } else if (isMinDefault) {
            endDateLabel = `Before ${new Date(backtestFilters.maxDateRange).toLocaleDateString()}`;
        } else if (isMaxDefault) {
            startDateLabel = `After ${new Date(backtestFilters.minDateRange).toLocaleDateString()}`;
        }

        return <Box display={'flex'} flexDirection={'column'}>
            <Typography variant="body2" color={"textPrimary"}>
                {startDateLabel}
            </Typography>
            <Typography variant="body2" color={"textPrimary"}>
                {endDateLabel}
            </Typography>
        </Box>
    };

    return <>
        <FilterButton
            menu={<>

                <FilterHeaderSection
                    title={"Date Range"}
                    onClearSection={() => {
                        setBacktestFilters((prev: any) => ({
                            ...prev,
                            minDateRange: Number.MIN_SAFE_INTEGER,
                            maxDateRange: Number.MAX_SAFE_INTEGER,
                        }));
                    }}
                />

                <DateRangeFilter backtestFilters={backtestFilters} setBacktestFilters={setBacktestFilters}/>

                <FilterHeaderSection
                    title={"Days Backtested"}
                    onClearSection={() => {
                        setBacktestFilters((prev: any) => ({
                            ...prev,
                            daysBetween: undefined,
                        }));
                    }}
                />
                <DaysBacktestedFilter backtestFilters={backtestFilters} setBacktestFilters={setBacktestFilters}/>
            </>}
            label={getLabel()}
        />
    </>
}

export default DateRangeFilterButton;
