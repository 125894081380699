// import React, {useEffect, useRef, useState} from "react";
// import Box from "@mui/material/Box";
// import Typography from "@mui/material/Typography";
// import {
//     FormControl,
//     InputAdornment,
//     InputLabel,
//     MenuItem,
//     OutlinedInput,
//     Select,
//     Switch,
//     TextField
// } from "@mui/material";
// import {useShowcaseContext} from "../contexts/ShowcaseContext";
// import {useApplicationSuiteContext} from "../../../contexts/ApplicationSuiteProvider";
// import SymbolSelectManager, {
//     ISymbolSelectManagerRef
// } from "../../../common/components/dialogs/symbolSelectDialog/SymbolSelectManager";
// import {ISymbol} from "../../../common/components/dialogs/symbolSelectDialog/SymbolSelect";
// import debounce from "lodash/debounce";
// import ClearOutlinedIcon from '@mui/icons-material/ClearOutlined';
// import IconButton from "@mui/material/IconButton";
// import SSButton from "../../../common/components/actionButton/SSButton";
// import {useTheme} from "@mui/material/styles";
// import {LocalizationProvider} from "@mui/x-date-pickers/LocalizationProvider";
// import {AdapterDayjs} from "@mui/x-date-pickers/AdapterDayjs";
// import {DesktopDatePicker} from "@mui/x-date-pickers/DesktopDatePicker";
// import dayjs from "dayjs";
//




// Deprecated
const ShowcaseSidebar: React.FC = () => {
    return null;
}

export default ShowcaseSidebar;



// const ShowcaseSidebar: React.FC = () => {
//     const {symbols} = useApplicationSuiteContext();
//     const {
//         showcaseMaxDateRangeFilter,
//         showcaseMinDateRangeFilter,
//         showcaseSymbolTypeFilter,
//         showcaseSymbolSearchFilter,
//         showcaseSavedOnlyFilter,
//         showcaseSearchByPatternName,
//         showcaseTradeLimitFilter,
//         setShowcaseMaxDateRangeFilter,
//         setShowcaseMinDateRangeFilter,
//         setShowcaseSymbolTypeFilter,
//         setShowcaseSymbolSearchFilter,
//         setShowcaseSavedOnlyFilter,
//         setShowcaseSearchByPatternName,
//         setShowcaseTradeLimitFilter
//     } = useShowcaseContext();
//
//     const [localState, setLocalState] = useState({
//         showcaseSearchByPatternName,
//         showcaseSymbolSearchFilter,
//         showcaseSymbolTypeFilter,
//         showcaseTradeLimitFilter,
//         showcaseMaxDateRangeFilter,
//         showcaseMinDateRangeFilter,
//         showcaseSavedOnlyFilter
//     });
//
//     const [useTradeLimitFilter, setUseTradeLimitFilter] = useState(false);
//     const [useDateRangeFilter, setUseDateRangeFilter] = useState(false);
//
//     const managerRef = useRef<ISymbolSelectManagerRef | null>(null);
//     const theme = useTheme();
//
//     const openSymbolSelectDialog = () => {
//         if (managerRef.current) {
//             // @ts-ignore
//             managerRef.current.openDialog();
//         }
//     };
//
//     const selectSymbol = (symbolChosen: ISymbol) => {
//         setLocalState(prev => ({
//             ...prev,
//             showcaseSymbolSearchFilter: symbolChosen.name
//         }));
//     };
//
//     useEffect(() => {
//         const debouncedSetSearchByPatternName = debounce((value: string) => {
//             setLocalState(prev => ({
//                 ...prev,
//                 showcaseSearchByPatternName: value
//             }));
//         }, 500);
//
//         debouncedSetSearchByPatternName(localState.showcaseSearchByPatternName);
//
//         // Cleanup function to cancel debounce if the component unmounts or the value changes
//         return () => {
//             debouncedSetSearchByPatternName.cancel();
//         };
//     }, [localState.showcaseSearchByPatternName]);
//
//     const applyChanges = () => {
//         setShowcaseMaxDateRangeFilter(localState.showcaseMaxDateRangeFilter);
//         setShowcaseMinDateRangeFilter(localState.showcaseMinDateRangeFilter);
//         setShowcaseSymbolTypeFilter(localState.showcaseSymbolTypeFilter);
//         setShowcaseSymbolSearchFilter(localState.showcaseSymbolSearchFilter);
//         setShowcaseSavedOnlyFilter(localState.showcaseSavedOnlyFilter);
//         setShowcaseSearchByPatternName(localState.showcaseSearchByPatternName);
//         setShowcaseTradeLimitFilter(localState.showcaseTradeLimitFilter);
//     };
//
//     useEffect(() => {
//         setLocalState(prev => ({
//             ...prev,
//             showcaseTradeLimitFilter: useTradeLimitFilter ? 0 : -1
//         }));
//     }, [useTradeLimitFilter]);
//
//     useEffect(() => {
//
//         const previousMaxDateRangeFilter = localStorage.getItem('showcaseMaxDateRangeFilter');
//         const previousMinDateRangeFilter = localStorage.getItem('showcaseMinDateRangeFilter');
//
//         const maxDate = previousMaxDateRangeFilter ? parseInt(previousMaxDateRangeFilter) : Date.now();
//         const minDate = previousMinDateRangeFilter ? parseInt(previousMinDateRangeFilter) : maxDate - 1000 * 60 * 60 * 24 * 30 * 3;
//
//         setLocalState(prev => ({
//             ...prev,
//             showcaseMaxDateRangeFilter: useDateRangeFilter ? maxDate : 0,
//             showcaseMinDateRangeFilter: useDateRangeFilter ? minDate : 0
//         }));
//
//     }, [useDateRangeFilter]);
//
//     return <>
//         <SymbolSelectManager
//             ref={managerRef}
//             // @ts-ignore
//             caller={'backtester'}
//             symbolSelectCallback={selectSymbol}
//             symbols={(symbols || []) as ISymbol[]}
//         />
//
//         <Box display='flex' flexDirection='column' height={'calc(100vh - 112px)'} justifyContent={'space-between'}>
//             <Box display='flex' flexDirection='column' height={'100%'} gap={4}>
//                 <Box sx={{overflowY: "auto"}} display={'flex'} flexDirection={'row'} justifyContent={'space-between'}>
//                     <Typography variant={"h6"}>Showcase Filters</Typography>
//                 </Box>
//
//                 <FormControl fullWidth>
//                     <TextField
//                         sx={{backgroundColor: theme.palette.background.custom4, color: 'white'}}
//                         label="Search by Pattern Name"
//                         value={localState.showcaseSearchByPatternName}
//                         onChange={(e: any) => setLocalState(prev => ({
//                             ...prev,
//                             showcaseSearchByPatternName: e.target.value
//                         }))}
//                         variant="outlined"
//                         fullWidth
//                     />
//                 </FormControl>
//
//                 <FormControl fullWidth>
//                     <InputLabel id="symbol">Symbol</InputLabel>
//                     <Select
//                         sx={{backgroundColor: theme.palette.background.custom4, color: 'white'}}
//                         labelId="symbol"
//                         value={localState.showcaseSymbolSearchFilter}
//                         name="symbol"
//                         label="Symbol"
//                     >
//                         {(symbols || []).map((symbol: any, index) => (
//                             <MenuItem key={index} value={symbol.name}>{symbol.name}</MenuItem>
//                         ))}
//                     </Select>
//                     {/* Prevent the actual select from being opened */}
//                     <Box
//                         onClick={openSymbolSelectDialog}
//                         style={{
//                             position: 'absolute',
//                             top: 0,
//                             left: 0,
//                             right: 0,
//                             bottom: 0,
//                             cursor: 'pointer',
//                             zIndex: 2, // Ensure this is higher than the zIndex of elements you wish to cover
//                         }}
//                     >
//                         {
//                             localState.showcaseSymbolSearchFilter && <IconButton
//                             onClick={(e) => {
//                                 e.stopPropagation();
//                                 setLocalState(prev => ({
//                                     ...prev,
//                                     showcaseSymbolSearchFilter: ''
//                                 }));
//                             }}
//                             sx={{
//                                 position: 'absolute',
//                                 top: '50%',
//                                 right: '32px',
//                                 transform: 'translateY(-50%)',
//                                 zIndex: 1,
//                             }}
//                           >
//                             <ClearOutlinedIcon fontSize={'small'}/>
//                           </IconButton>
//                         }
//                     </Box>
//                 </FormControl>
//
//                 <FormControl fullWidth>
//                     <InputLabel id="symbol-type-select-label">Symbol Type</InputLabel>
//                     <Select
//                         sx={{backgroundColor: theme.palette.background.custom4, color: 'white'}}
//                         labelId="symbol-type-select-label"
//                         id="symbol-type-select"
//                         value={localState.showcaseSymbolTypeFilter}
//                         label="Symbol Type"
//                         onChange={(e: any) => setLocalState(prev => ({
//                             ...prev,
//                             showcaseSymbolTypeFilter: e.target.value
//                         }))}
//                     >
//                         <MenuItem value={"all"}>All</MenuItem>
//                         <MenuItem value={"stock"}>Stock</MenuItem>
//                         <MenuItem value={"forex"}>Forex</MenuItem>
//                         <MenuItem value={"crypto"}>Crypto</MenuItem>
//                         <MenuItem value={"index"}>Index</MenuItem>
//                     </Select>
//                 </FormControl>
//
//                 <Box
//                     display={'flex'}
//                     alignItems={'flex-start'}
//                     gap={1}
//                 >
//
//                     <Switch
//                         checked={useTradeLimitFilter}
//                         onChange={(e) => setUseTradeLimitFilter(e.target.checked)}
//                         size="small"
//                     />
//
//                     <Box
//                         display={'flex'}
//                         flexDirection={'column'}
//                         width={'100%'}
//                     >
//
//                         <Typography variant="body2" gutterBottom>
//                             Max open positions
//                         </Typography>
//
//
//                         {
//                             useTradeLimitFilter && <>
//                             <FormControl fullWidth>
//                               <InputLabel htmlFor="outlined-adornment-amount">Max open positions</InputLabel>
//                               <OutlinedInput
//                                 sx={{backgroundColor: theme.palette.background.custom4, color: 'white'}}
//                                 value={
//                                     localState.showcaseTradeLimitFilter === 0 || localState.showcaseTradeLimitFilter === -1
//                                         ? ""
//                                         : localState.showcaseTradeLimitFilter
//                                 }
//                                 disabled={!useTradeLimitFilter}
//                                 name="analyzeTrades.tradeLimit"
//                                 onChange={(e: any) => setLocalState(prev => ({
//                                     ...prev,
//                                     showcaseTradeLimitFilter: parseInt(e.target.value) || 0
//                                 }))}
//                                 type={"number"}
//                                 inputProps={{min: 0}}
//                                 id="outlined-adornment-amount"
//                                 startAdornment={<InputAdornment position="start">#</InputAdornment>}
//                                 label="Max open positions"
//                                 placeholder='∞'
//                               />
//                             </FormControl>
//                           </>
//                         }
//
//
//                     </Box>
//
//
//                 </Box>
//
//                 <Box
//                     display={'flex'}
//                     alignItems={'flex-start'}
//                     gap={1}
//                 >
//                     <Switch
//                         checked={useDateRangeFilter}
//                         onChange={(e) => setUseDateRangeFilter(e.target.checked)}
//                         size="small"
//                     />
//
//                     <Box
//                         display={'flex'}
//                         flexDirection={'column'}
//                         width={'100%'}
//                     >
//
//                         <Typography variant="body2" gutterBottom>
//                             Backtested Date Range
//                         </Typography>
//                         <Box
//                             display={'flex'}
//                             flexDirection={'column'}
//                             alignItems={'center'}
//                             width={'100%'}
//                             gap={1}
//                         >
//                             {
//                                 useDateRangeFilter && <>
//
//                                 <LocalizationProvider dateAdapter={AdapterDayjs}>
//                                   <DesktopDatePicker
//                                     sx={{width: "100%"}}
//                                     label="Minimum"
//                                     value={dayjs(new Date(localState.showcaseMinDateRangeFilter))}
//                                     onChange={(value: any) => {
//                                         setLocalState(prev => ({
//                                             ...prev,
//                                             showcaseMinDateRangeFilter: new Date(value.$d.getTime()).getTime()
//                                         }));
//                                         localStorage.setItem('showcaseMinDateRangeFilter', new Date(value.$d.getTime()).getTime().toString());
//                                     }}
//                                   />
//
//                                 </LocalizationProvider>
//                                 <LocalizationProvider dateAdapter={AdapterDayjs}>
//                                   <DesktopDatePicker
//                                     sx={{width: "100%"}}
//                                     label="Maximum"
//                                     value={dayjs(new Date(localState.showcaseMaxDateRangeFilter))}
//                                     onChange={(value: any) => {
//                                         setLocalState(prev => ({
//                                             ...prev,
//                                             showcaseMaxDateRangeFilter: new Date(value.$d.getTime()).getTime()
//                                         }));
//                                         localStorage.setItem('showcaseMaxDateRangeFilter', new Date(value.$d.getTime()).getTime().toString());
//                                     }}
//                                   />
//
//                                 </LocalizationProvider>
//                               </>
//                             }
//                         </Box>
//                     </Box>
//                 </Box>
//
//                 <Box
//                     display={'flex'}
//                     alignItems={'center'}
//                     gap={1}
//                 >
//                     <Switch
//                         checked={localState.showcaseSavedOnlyFilter}
//                         onChange={(e: any) => setLocalState(prev => ({
//                             ...prev,
//                             showcaseSavedOnlyFilter: e.target.checked
//                         }))}
//                         name="savedOnly"
//                         color="primary"
//                         size="small"
//                     />
//                     <Typography variant="body2" gutterBottom textAlign={"center"}>
//                         Show Favorited Showcases Only
//                     </Typography>
//                 </Box>
//             </Box>
//             <Box sx={{display: 'flex', justifyContent: 'center'}}>
//                 <SSButton sx={{width: '100%', height: '50px'}} onClick={applyChanges} type={'action'}>
//                     Apply
//                 </SSButton>
//             </Box>
//         </Box>
//     </>
// }
//
// export default ShowcaseSidebar;
