import EmailInput from "./EmailInput";
import PhoneNumberInput from "./PhoneNumberInput";
import React from "react";
import Box from "@mui/material/Box";
import {FormControlLabel, FormGroup, Radio, Typography} from "@mui/material";
import {Alert} from "../../contexts/ApplicationSuiteProvider";

function AlertNotificationTypeSelect({alert, onNotificationTypeChange: handleAlertKeyChange}: { alert: Alert, onNotificationTypeChange: (key: string, value: any) => void }) {

    const [textMessageToggle, setTextMessageToggle] = React.useState(alert.alertTypes.includes('sms'));

    const handleToggle = (type: 'sms' | 'email') => {
        handleAlertKeyChange("alertTypes", alert.alertTypes.includes(type) ? alert.alertTypes.filter((t) => t !== type) : [...alert.alertTypes, type]);
    };

    return (

            <FormGroup sx={{px: 1.5}}>
                <FormControlLabel
                    key={'in-app'}
                    control={
                        <Radio value={true}  checked={true}/>
                    }
                    label={<><Typography>In-App Notification <Typography variant={'caption'}>(default)</Typography></Typography> </>}
                />

                <FormControlLabel
                    key={'email'}
                    control={
                        <Radio
                            checked={alert.alertTypes.includes('email')}
                            onClick={() => handleToggle('email')}
                        />
                    }
                    label={'Email Notification'}
                />
                {
                    alert.alertTypes.includes('email') && (
                        <Box display={"flex"} gap={1} px={4}>
                            <Typography variant={"caption"}>Email Address:</Typography>
                            <EmailInput
                                email={alert.user?.email}
                                disabled
                                onEmailChange={(value) => handleAlertKeyChange("user.email", value)}
                            />
                        </Box>
                    )
                }

                <FormControlLabel
                    key={'sms'}
                    control={
                        <Radio
                            color={alert.alertTypes.includes('sms') ? 'primary' : 'warning'}
                            checked={textMessageToggle}
                            onClick={() => {
                                setTextMessageToggle(!textMessageToggle)

                                if (alert.user?.phoneNumber) {
                                    handleToggle('sms')
                                }
                            }}
                        />
                    }
                    label={'Text Message Notification'}
                />
                {
                    textMessageToggle && (
                        <Box display={"flex"} gap={1} px={4}>
                            <Typography variant={"caption"} flexGrow={1}>Phone Number:</Typography>
                            <PhoneNumberInput
                                phoneNumber={alert.user?.phoneNumber}
                                onPhoneNumberChange={(value) => {
                                    handleAlertKeyChange("user.phoneNumber", value)
                                    const updatedAlertTypes = Array.from(new Set([...alert.alertTypes, "sms"]));
                                    handleAlertKeyChange("alertTypes", updatedAlertTypes);
                                }}
                            />
                        </Box>
                    )
                }
            </FormGroup>

    )
}


export default AlertNotificationTypeSelect;
