import React, {useEffect, useState} from "react";
import {Pattern} from "../../app/builder/contexts/PatternContext";
import {ConditionType} from "../../app/builder/contexts/PatternTreeContext";
import _cloneDeep from "lodash.clonedeep";
import {Select, MenuItem, Box} from '@mui/material';
import flatternPattern from "../../common/utils/flatternPattern";

type PatternTimeframeSelectProps = {
    pattern: Pattern,
    onPatternTimeframeChange: (pattern: Pattern) => void
}

type UniqueChartDetailsType = {
    [chartId: string]: {
        periodicity: number,
        timeframe: string,
        conditions: ConditionType["chartDetails"][]
    }
}

function PatternTimeframeSelect({pattern: initialPattern, onPatternTimeframeChange}: PatternTimeframeSelectProps) {
    const [pattern, setPattern] = useState<Pattern>(_cloneDeep(initialPattern));
    const [uniqueChartDetails, setUniqueChartDetails] = useState<UniqueChartDetailsType>({});

    useEffect(() => {
        setPattern(_cloneDeep(initialPattern));
    }, [initialPattern]);

    useEffect(() => {
        const conditions = flatternPattern(pattern);

        const ucd = conditions
            .concat(pattern.trigger as unknown as ConditionType)
            .reduce((acc: UniqueChartDetailsType, condition) => {
                if (!condition.chartDetails) {
                    return acc;
                }

                const {periodicity, timeframe} = condition.chartDetails;
                const chartId = `${periodicity}-${timeframe}`;
                if (!acc[chartId]) {
                    acc[chartId] = {periodicity, timeframe, conditions: []}
                }
                acc[chartId].conditions.push(condition.chartDetails);
                return acc;
            }, {});

        setUniqueChartDetails(ucd);
    }, [pattern]);

    function handleTimeframeChange(chartId: string, newChartDetails: any) {
        for (const condition of uniqueChartDetails[chartId].conditions) {
            condition.periodicity = newChartDetails.periodicity;
            condition.timeframe = newChartDetails.timeframe;
        }

        onPatternTimeframeChange(pattern);
    }

    return (
        <Box display={"flex"} flexDirection={"column"} gap={1}>
            {Object.entries(uniqueChartDetails)
                .sort(([aChartid, {periodicity: aPeriodicity, timeframe: aTimeframe}], [bChartid, {periodicity: bPeriodicity, timeframe: bTimeframe}]) => {
                    if (aTimeframe === bTimeframe) {
                        return aPeriodicity - bPeriodicity;
                    }
                    return aTimeframe === 'minute' ? -1 : 1;
                })
                .map(([chartId, {periodicity, timeframe}]: any) => (
                <PeriodicityTimeframeRow
                    key={chartId}
                    periodicity={periodicity}
                    timeframe={timeframe}
                    onTimeframeChange={({periodicity, timeframe}: any) => handleTimeframeChange(chartId, {periodicity, timeframe})}
                />
            ))}
        </Box>
    )
}

function PeriodicityTimeframeRow({periodicity, timeframe, onTimeframeChange}: any) {
    const [chartDetails, setChartDetails] = useState<Pick<ConditionType['chartDetails'], 'periodicity' | 'timeframe'>>({periodicity, timeframe});

    const handleChange = (e: any) => {
        const {name, value} = e.target;
        const newChartDetails: any = _cloneDeep(chartDetails);

        if (name === 'timeframe' && value === 'hour' && chartDetails.periodicity > 24) {
            newChartDetails.periodicity = 1;
        } else if (name === 'timeframe' && value === 'minute' && chartDetails.periodicity > 59) {
            newChartDetails.periodicity = 1;
        }

        newChartDetails[name] = value;
        setChartDetails(newChartDetails);
        onTimeframeChange(newChartDetails);
    }

    return (
        <Box display={"flex"} alignItems={"center"} width={"100%"} gap={1}>
            <Select fullWidth name="periodicity" value={chartDetails.periodicity} onChange={handleChange} sx={{height: 40}}>
                {Array.from({length: chartDetails.timeframe === 'minute' ? 59 : 24}, (_, i) => (
                    <MenuItem key={i+1} value={i+1}>{i+1}</MenuItem>
                ))}
            </Select>
            <Select fullWidth name="timeframe" value={chartDetails.timeframe} onChange={handleChange} sx={{height: 40}}>
                <MenuItem value="minute">Minute</MenuItem>
                <MenuItem value="hour">Hour</MenuItem>
            </Select>
        </Box>
    )
}

export default PatternTimeframeSelect;
