import React, {useEffect, useRef, useState} from "react";
import Box from "@mui/material/Box";
import {useTheme} from "@mui/material/styles";
import {useCollectionData} from "react-firebase-hooks/firestore";
import {collection, query, where} from "firebase/firestore";
import {firebase_firestore} from "../../../common/firebaseConfig";
import {useApplicationSuiteContext} from "../../../contexts/ApplicationSuiteProvider";
import PatternBacktestView from "../patterns/PatternBacktestView";
import Application from "../../../layout/Application";
import {BacktestReportProvider} from "../../reports/contexts/BacktestReportContext";
import {PatternProvider} from "../../builder/contexts/PatternContext";
import {PatternTreeProvider} from "../../builder/contexts/PatternTreeContext";
import {Typography} from "@mui/material";
import Button from "@mui/material/Button";
import {Tabs} from "../../../layout/MainLayout";
import {ShowcaseProvider} from "../../showcase/contexts/ShowcaseContext";
import "../../builder/blankMessageArea/AdArea.css"
import RecentlyViewedBacktests from "../recentlyViewedBacktests/RecentlyViewedBacktests";
import ShowcaseBacktestReports from "../../showcase/components/ShowcaseBacktestReports";
import ShowcaseFilterView from "../../../components/backtests/filter/ShowcaseFilterView";
import InfoIcon from "@mui/icons-material/Info";
import {GreenChartTooltip} from "../../../common/components/GreenChartTooltip";

function DashboardApp() {

    const theme = useTheme();
    const {
        usermeta,
        tradingHouse,
        setActiveTab,
    } = useApplicationSuiteContext();

    const [usermetaPatterns, usermetaPatternsLoading] = useCollectionData(
        query(
            collection(firebase_firestore, `usermeta/${usermeta.gcid}/patterns`),
            where("active", "==", true),
            where("tradingHouse", "==", tradingHouse),
        )
    ) as any[];

    const containerRef = useRef(null);
    const [isNarrow, setIsNarrow] = useState(false);
    const [isMedium, setIsMedium] = useState(false);

    useEffect(() => {
        const currentRef = containerRef.current; // Capture the ref

        const observer = new ResizeObserver((entries) => {
            for (let entry of entries) {
                if (entry.target === currentRef) {
                    const width = entry.contentRect.width;
                    // Define your width threshold (e.g., 600px)
                    setIsNarrow(width < 768);
                    setIsMedium(width < 1100);
                }
            }
        });

        if (currentRef) {
            observer.observe(currentRef);
        }

        return () => {
            if (currentRef) {
                observer.unobserve(currentRef);
            }
        };
    }, []);

    function goToPatternBuilder() {
        setActiveTab(Tabs.PatternBuilder);
    }

    function goToShowcase() {
        setActiveTab(Tabs.Showcase);
    }

    return (
        <Box
            sx={{
                background: theme.palette.background.custom1,
                width: '100%',
                height: '100%',
                overflow: 'auto'
            }}
            ref={containerRef}
        >
            <Box
                display="grid"
                sx={{
                    gridTemplateColumns:
                    isNarrow
                        ? '1fr'  // Single column on small screens
                        : isMedium
                            ? '1fr 1fr'
                            : '1fr 2fr',     // Two columns on medium and up
                    gridTemplateRows:
                    isNarrow
                        ? 'auto auto' // Adjust rows on small screens
                        : '2fr 1fr',   // Original rows on medium and up
                }}
                height="100%"
                gap={1}
            >
                <Box
                    sx={{
                        gridColumn: '1 / 2',
                        gridRow: '1 / 2',
                        display: 'flex',
                        flexDirection: 'column',
                        height: '100%',
                        overflowY: 'hidden',
                    }}
                >
                    <Box p={1}
                         sx={{
                            backgroundColor: theme.palette.background.custom2,
                            borderRadius: '4px',
                            display: 'flex',
                            flexDirection: 'column',
                            overflowY: 'hidden',
                             height: '100%',
                        }}>


                        <Box display={'flex'} alignItems={'center'} pb={1}>
                            <Typography
                                variant="body1"
                                color="textSecondary"
                                height={40}
                                onClick={() => goToPatternBuilder()}
                                sx={{
                                    cursor: 'pointer',
                                    '&:hover': {
                                        textDecoration: 'underline',
                                        color: theme.palette.primary.main,
                                    }
                                }}
                            >
                                Patterns
                            </Typography>
                        </Box>

                        <Box
                            display={'flex'}
                            flexDirection={'column'}
                            sx={{
                                overflowY: 'auto',
                                overflowX: 'hidden',
                            }}

                        >
                            {usermetaPatterns && usermetaPatterns.length ?
                                usermetaPatterns
                                    .sort((a: any, b: any) => a.name.localeCompare(b.name))
                                    .map((pattern: any) => {
                                        return <PatternBacktestView key={pattern.id} pattern={pattern} />;
                                    })
                                : null
                            }

                            {
                                (!usermetaPatterns || !usermetaPatterns.length) && !usermetaPatternsLoading &&
                              <Box display={'flex'} flexDirection={'column'}>
                                <Typography variant="body1" color="white">
                                  You don't have any patterns yet. No worries, you can start building your first pattern now in the <Typography variant="body1" color="primary" component={'span'} onClick={() => goToPatternBuilder()} sx={{cursor: 'pointer', '&:hover': {textDecoration: 'underline'}}}>Pattern Builder</Typography>.
                                  <br/>
                                  <br/>
                                  Patterns are an outline of your trading strategy, helping you define the rules and conditions for your trade entries.
                                </Typography>
                                <br/>
                                <Button variant={'outlined'} onClick={() => goToPatternBuilder()}>
                                  Create your first pattern!
                                </Button>

                              </Box>

                            }
                        </Box>
                    </Box>

                </Box>

                <Box
                        sx={{
                            gridColumn:
                                isNarrow
                                    ? '1 / 2'
                                    : '2 / 3',
                            gridRow:
                                isNarrow
                                    ? '2 / 3'
                                    : '1 / 3',
                            display: 'flex',
                            flexDirection: 'column',
                            height: '100%',
                            overflowY: 'hidden',
                            backgroundColor: theme.palette.background.custom2,
                            borderRadius: '4px',

                        }}
                >
                    <Box display={'flex'} alignItems={'center'} p={1} flexDirection={'row'} height={40} gap={.5}>
                        <Typography variant="body1"
                                    color="textSecondary"
                                    onClick={() => goToShowcase()}
                                    sx={{
                                        cursor: 'pointer',
                                        '&:hover': {
                                            textDecoration: 'underline',
                                            color: theme.palette.primary.main,
                                        }
                                    }}>
                            Showcase
                        </Typography>
                        <GreenChartTooltip title={'Showcase displays publicly shared backtests ran by other Traders.'} arrow placement={'bottom-start'}>
                            <InfoIcon
                                sx={{
                                    color: theme.palette.text.primary,
                                    '&:hover': {
                                        cursor: 'pointer',
                                        color: '#00A1FF',
                                    },
                                }}
                                fontSize={'small'}
                            />
                        </GreenChartTooltip>

                    </Box>
                    <Box px={1}>
                        <ShowcaseFilterView/>
                    </Box>
                    <ShowcaseBacktestReports/>
                </Box>


                <Box
                    sx={{
                        gridColumn: '1 / 2',
                        gridRow: '2 / 3',
                        display: isNarrow ? 'none' : 'flex',
                        flexDirection: 'column',
                        height: '100%',
                        overflowY: 'hidden',
                        backgroundColor: theme.palette.background.custom2,
                        borderRadius: '4px',
                    }}>
                    <Box display={'flex'} alignItems={'center'} p={1} pb={0}>

                        <Typography variant="body1" color="textSecondary" height={40}>
                            Recently Viewed Backtests
                        </Typography>
                    </Box>
                    <RecentlyViewedBacktests />
                </Box>
            </Box>
        </Box>

    );
}

const App = ({tab}: any) => <Application AppComponent={DashboardApp} ContextProviders={[BacktestReportProvider, PatternTreeProvider, PatternProvider, ShowcaseProvider]} tab={tab}/>
export default App;
