import React from "react";
import IconButton from "@mui/material/IconButton";
import {BookmarkAdded} from "@mui/icons-material";
import Box from "@mui/material/Box";
import {GreenChartTooltip} from "../../../../common/components/GreenChartTooltip";

function FavoriteToggle({onToggle, toggleValue}: any) {

  return (
      <Box>
          <GreenChartTooltip title={"Show saved backtests"} placement={"bottom-start"} arrow>
              <IconButton
                  onClick={() => onToggle(!toggleValue)}
                  sx={{color: toggleValue ? "primary.main" : "inherit", height: 40, width: 40}}
                  size={"small"}
              >
                  {toggleValue ? <BookmarkAdded color={'primary'} /> : <BookmarkAdded color={'action'} />}
              </IconButton>
          </GreenChartTooltip>
      </Box>

  );
}

export default FavoriteToggle;
