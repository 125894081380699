import React, {useState} from 'react';
import {Box, Card, CardActions, CardContent, IconButton, Typography,} from '@mui/material';
import Tooltip, { TooltipProps, tooltipClasses } from '@mui/material/Tooltip';
import {AdminPanelSettings, CalendarMonth} from '@mui/icons-material';
import {styled, useTheme} from '@mui/material/styles';
import BacktestEquityChart from './BacktestEquityChart';
import _duration from "format-duration-time";
import ReportViewModal from "./ReportViewModal";
import {Tabs} from "../../layout/MainLayout";
import BacktestStatusIcon from "./BacktestStatusIcon";
import {useApplicationSuiteContext} from "../../contexts/ApplicationSuiteProvider";
import BacktestGradeIcon from "./BacktestGradeIcon";
import useRecentlyViewedBacktests from "../../hooks/useRecentlyViewedBacktests";
import Slider, {LazyLoadTypes} from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import BacktestTable from "../../app/reports/components/backtestResults/BacktestTable";
import CreateAlertActionChip from "../actionChips/CreateAlertActionChip";
import ViewBacktestSettingsActionChip from "../actionChips/ViewBacktestSettingsActionChip";
import ViewPatternActionChip from "../actionChips/ViewPatternActionChip";
import ActionChip from "../actionChips/ActionChip";
import BacktestDescription from "./BacktestDescription";
import FavoriteBacktestActionChip from "../actionChips/FavoriteBacktestActionChip";

type BacktestReportCardProps = {
    backtest: any,
    condensed?: boolean,
    selectable?: boolean,
    onSelected?: (backtest: any) => void,
    isSelected?: boolean,
}

const CustomWidthTooltip = styled(({ className, ...props }: TooltipProps) => (
    <Tooltip {...props} classes={{ popper: className }} />
))({
    [`& .${tooltipClasses.tooltip}`]: {
        maxWidth: 600,
    },
});

function BacktestReportCard({ backtest, condensed, selectable, onSelected, isSelected }: BacktestReportCardProps) {
    const theme = useTheme();
    const [reportOpen, setReportOpen] = React.useState(false);
    const [, setRecentlyViewedBacktests] = useRecentlyViewedBacktests();

    const {
        setActiveTab,
        setPattern,
    } = useApplicationSuiteContext();

    const sliderSettings = {
        dots: true,
        lazyLoad: 'ondemand' as LazyLoadTypes,
        infinite: false,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
    };

    function openBacktestReport() {
        if (backtest.status !== "running") {
            setRecentlyViewedBacktests(backtest);
            setReportOpen(!reportOpen);
        }
    }

    function goToPatternBuilder(event: any) {
        event.stopPropagation();
        setRecentlyViewedBacktests(backtest);
        setPattern(backtest.pattern);
        setActiveTab(Tabs.PatternBuilder);
    }

    return (
        <Card
            sx={{
                display: 'flex',
                flexDirection: 'column',
                '--Paper-overlay': 'none !important',
                border: selectable ? `2px solid ${isSelected ? theme.palette.primary.main : theme.palette.divider}` : 'none',
            }}
            className={'backtest-report-card'}
        >
            <Box display="flex" flexDirection={'column'} justifyContent="space-between" alignItems={'flex-start'} p={1}
                 flexGrow={1}
                 onClick={() => openBacktestReport()}
                 sx={{
                     cursor: 'pointer',
                     backgroundColor: theme.palette.background.custom4,
                     '&:hover': {
                         opacity: 0.95,
                     },
                     boxShadow: "inset 0px -3px 8px -3px black",
                 }}
            >

                <Box display={'flex'} justifyContent={'space-between'} width={'100%'} alignItems={'center'}>
                    <Box overflow={'hidden'}>
                        <Typography variant="body1" color="text.primary" overflow={'hidden'} whiteSpace={'nowrap'} textOverflow={'ellipsis'} onClick={(event) => goToPatternBuilder(event)}
                                    sx={{
                                        cursor: 'pointer',
                                        '&:hover': {
                                            color: theme.palette.primary.main,
                                            textDecoration: 'underline'
                                        }
                                    }}
                        >
                            {backtest.name}
                            <Typography variant={'caption'}> (v{backtest?.pattern?.version || 0})</Typography>
                        </Typography>
                    </Box>
                    {['running', 'error'].includes(backtest.status)
                        ? <Box display={'flex'} alignItems={'center'} flexDirection={'column'}>
                            <BacktestStatusIcon backtest={backtest} />
                            {(!backtest.endTime && backtest.status === 'running') && <Typography
                              variant={"caption"}
                              fontSize={10}
                              overflow={'hidden'}
                              whiteSpace={'nowrap'}
                              textOverflow={'ellipsis'}
                            >
                                {_duration(new Date().getTime() - backtest.startTime).format('m[m] ss[s]')}
                            </Typography>}
                        </Box>
                        : <BacktestGradeIcon backtest={backtest} />}
                </Box>

                <Box overflow={'hidden'} width={'100%'}>
                    <BacktestDescription backtest={backtest}/>
                </Box>
            </Box>

            {
                !condensed && <CardContent sx={{
                    height: 200,
                    flexGrow: 1,
                    p: 0,
                    backgroundColor: theme.palette.background.custom3,
                }}>

                <style>
                    {`
                    .backtest-report-card {
                        .slick-list, .slick-track, .slick-slide, .slick-slide > div {
                            height: 100%;
                        }
                        .slick-slider {
                            height: calc(100% - 25px);
                            cursor: grab;
                        }
                        
                        :active {
                          cursor: grabbing !important;
                        }
                      
                        .slick-dots {
                            margin-left: 20px;
                        }
                        
                        .slick-dots li.slick-active button:before {
                            color: ${theme.palette.primary.main};
                        }
                        
                        .slick-dots li button:before {
                            color: ${theme.palette.primary.light};
                        }
                    }
                `}
                </style>

                    {!(backtest.summaries && backtest.summaries.length) && <Box display={'flex'} justifyContent={'center'} alignItems={'center'} height={'100%'} flexDirection={'column'}>
                      <Typography variant={'body1'} color={'textPrimary'}>No trades were executed during this backtest</Typography>
                      <Typography variant={'caption'} color={'textSecondary'} textAlign={'center'}>Consider refining your strategy parameters or adjusting the timeframe to generate more meaningful results.</Typography>
                    </Box>}
                    {!!backtest.summaries && <Slider {...sliderSettings} >
                      <BacktestEquityChart backtest={backtest}/>
                      <BacktestTable backtest={backtest} condensed/>
                    </Slider>}
              </CardContent>
            }
            <CardActions sx={{
                position: 'relative',
                height: condensed ? 30 : 40,
                background: theme.palette.background.custom3,
                p:0,
                px: 1,
                py: .5,
                borderTop: condensed ? 'none' : `1px solid ${theme.palette.divider}`,
            }}>
                {
                    selectable
                        ? <BacktestReportCardSelectFooter backtest={backtest} condensed={condensed} onSelected={onSelected} isSelected={isSelected}/>
                        : <BacktestReportCardBaseFooter backtest={backtest} condensed={condensed}/>
                }
            </CardActions>

            {reportOpen && <ReportViewModal backtest={backtest} open={reportOpen} onClose={() => setReportOpen(false)} selectable={selectable}/>}
        </Card>
    );
}

function BacktestReportCardSelectFooter({ backtest, condensed, onSelected, isSelected }: any) {
    const {
        usermeta,
    } = useApplicationSuiteContext();

    const [isHovering, setIsHovering] = useState(false);

    return (
        <Box display={'flex'} flexDirection={'column'} width={'100%'}
             onMouseEnter={() => setIsHovering(true)}
             onMouseLeave={() => setIsHovering(false)}
        >
            <Box display={'flex'} justifyContent={'space-between'} width={'100%'} alignItems={'center'}>
                {(usermeta.isAdmin && isHovering) && (
                    <CustomWidthTooltip
                        arrow={true}
                        placement={"top-start"}
                        title={
                            <React.Fragment>
                                <Box>
                                    <Box borderBottom={1}>
                                        <Typography variant="body2" color={'textPrimary'}>Admin Only</Typography>
                                    </Box>
                                    <Box>
                                        <Box display={'grid'} gridTemplateRows={'1fr 1fr 1fr'} gridTemplateColumns={'1fr 5fr'} alignItems={'center'}>
                                            <Typography variant="body2" color={'textSecondary'}>Backtest:</Typography>
                                            <Typography variant={'body1'} mx={'auto'} color={'textPrimary'}>{backtest.id}</Typography>
                                            <Typography variant="body2" color={'textSecondary'}>Pattern:</Typography>
                                            <Typography variant={'body1'} mx={'auto'} color={'textPrimary'}>{backtest.pattern?.id}</Typography>
                                            <Typography variant="body2" color={'textSecondary'}>Runner:</Typography>
                                            <Typography variant={'body1'} mx={'auto'} color={'textPrimary'}>{backtest.gcid}</Typography>
                                            <Typography variant="body2" color={'textSecondary'}>Owner:</Typography>
                                            <Typography variant={'body1'} mx={'auto'} color={'textPrimary'}>{backtest.backtestSettings?.gcid}</Typography>
                                        </Box>
                                    </Box>
                                </Box>

                            </React.Fragment>
                        }
                      >
                        <IconButton size={'small'} onClick={() => console.log(backtest)} >
                          <AdminPanelSettings color={'warning'} fontSize={'small'} />
                        </IconButton>
                    </CustomWidthTooltip>
                )
                }
                <ActionChip
                    sx={{ml: 'auto', width: 'auto'}}
                    variant={isSelected ? 'filled' : 'outlined'}
                    label={'Select'}
                    size={condensed ? 'small' : 'medium'}
                    onClick={() => onSelected(backtest)}
                    forceOpen={true}
                />
            </Box>
        </Box>
    )
}

function BacktestReportCardBaseFooter({ condensed, backtest }: any) {

    const {
        setShowReportCardDateTimeTooltips,
        showReportCardDateTimeTooltips,
        usermeta,
    } = useApplicationSuiteContext();

    const [localReportCardDateTimeTooltip, setLocalReportCardDateTimeTooltip] = React.useState(false);
    const [isHovering, setIsHovering] = useState(false);

    function renderBacktestDateTime() {
        if (backtest.backtestSettings.startTime === backtest.backtestSettings.endTime) {
            return `All day`;
        }

        return `${new Date(backtest.backtestSettings.startTime).toLocaleTimeString()} to ${new Date(backtest.backtestSettings.endTime).toLocaleTimeString()}`;
    }

    const startDate = new Date(backtest.backtestSettings.dateRange.startDate).toDateString();
    const endDate = new Date(backtest.backtestSettings.dateRange.endDate).toDateString();
    const dateRangeString = `${startDate} to ${endDate}`;
    const timeRangeString = renderBacktestDateTime();

    function toggleAllReportCardDateTimeTooltips(event: any) {
        event.stopPropagation();
        setShowReportCardDateTimeTooltips(!showReportCardDateTimeTooltips);
    }

    function renderBacktestDateRangeReadable() {
        const startDate = new Date(backtest.backtestSettings.dateRange.startDate);
        const endDate = new Date(backtest.backtestSettings.dateRange.endDate);

        let totalDays = Math.ceil((endDate.getTime() - startDate.getTime()) / (1000 * 60 * 60 * 24)); // Total days difference
        let years = 0;
        let months = 0;
        let days = 0;

        // Calculate years
        while (totalDays >= 365) {
            const yearLength = isLeapYear(startDate.getFullYear()) ? 366 : 365;
            if (totalDays >= yearLength) {
                years++;
                totalDays -= yearLength;
                startDate.setFullYear(startDate.getFullYear() + 1);
            } else {
                break;
            }
        }

        // Calculate months
        while (totalDays > 0) {
            const currentMonthDays = getDaysInMonth(startDate.getFullYear(), startDate.getMonth());
            if (totalDays >= currentMonthDays) {
                months++;
                totalDays -= currentMonthDays;
                startDate.setMonth(startDate.getMonth() + 1);
            } else {
                break;
            }
        }

        // Remaining days
        days = totalDays;

        const yearPlural = years > 1 ? 's' : '';
        const monthPlural = months > 1 ? 's' : '';
        const dayPlural = days > 1 ? 's' : '';

        if (years) {
            if (months && days) {
                return `${years} year${yearPlural}, ${months} month${monthPlural}, and ${days} day${dayPlural}`;
            } else if (months) {
                return `${years} year${yearPlural} and ${months} month${monthPlural}`;
            } else if (days) {
                return `${years} year${yearPlural} and ${days} day${dayPlural}`;
            } else {
                return `${years} year${yearPlural}`;
            }
        } else if (months) {
            if (days) {
                return `${months} month${monthPlural} and ${days} day${dayPlural}`;
            } else {
                return `${months} month${monthPlural}`;
            }
        } else {
            return `${days} day${dayPlural}`;
        }
    }

// Helper function to check if a year is a leap year
    function isLeapYear(year: number) {
        return (year % 4 === 0 && year % 100 !== 0) || year % 400 === 0;
    }

// Helper function to get the number of days in a specific month
    function getDaysInMonth(year: number, month: number) {
        return new Date(year, month + 1, 0).getDate();
    }


    return (
        <Box display={'flex'} flexDirection={'column'} width={'100%'} height={'100%'} justifyContent={'center'}
             onMouseEnter={() => setIsHovering(true)}
             onMouseLeave={() => setIsHovering(false)}
        >
            <Box display={'flex'} justifyContent={'space-between'} width={'100%'} alignItems={'center'}>
                <Box display={'flex'} alignItems={'center'} sx={{overflow: 'hidden'}} gap={1}>
                    <FavoriteBacktestActionChip backtest={backtest} size={condensed ? 'small' : 'medium'} />

                    <Tooltip
                        arrow={true}
                        placement={"top-start"}
                        open={showReportCardDateTimeTooltips || localReportCardDateTimeTooltip}
                        onClose={() => {
                            setLocalReportCardDateTimeTooltip(false);
                            setShowReportCardDateTimeTooltips(false);
                        }}
                        onOpen={() => setLocalReportCardDateTimeTooltip(true)}
                        title={
                            <React.Fragment>
                                <Typography variant="body1" color="inherit">
                                    Backtest Date Range
                                </Typography>
                                <Typography variant="body2" color="inherit">
                                    {renderBacktestDateRangeReadable()}
                                </Typography>
                                <Typography variant="body2" color="inherit">
                                    {dateRangeString}
                                </Typography>
                                <Typography variant="body2" color="inherit">
                                    {timeRangeString}
                                </Typography>
                            </React.Fragment>
                        }
                    >
                        <IconButton onClick={(event) => toggleAllReportCardDateTimeTooltips(event)} size={'small'}>
                            <CalendarMonth fontSize={'small'} />
                        </IconButton>
                    </Tooltip>

                    {
                        (usermeta.isAdmin && isHovering) && <CustomWidthTooltip
                        arrow={true}
                        placement={"top-start"}
                        title={
                            <React.Fragment>
                                <Box>
                                    <Box borderBottom={1}>
                                        <Typography variant="body2" color={'textPrimary'}>Admin Only</Typography>
                                    </Box>
                                    <Box>
                                        <Box display={'grid'} gridTemplateRows={'1fr 1fr 1fr'} gridTemplateColumns={'1fr 5fr'} alignItems={'center'}>
                                            <Typography variant="body2" color={'textSecondary'}>Backtest:</Typography>
                                            <Typography variant={'body1'} mx={'auto'} color={'textPrimary'}>{backtest.id}</Typography>
                                            <Typography variant="body2" color={'textSecondary'}>Pattern:</Typography>
                                            <Typography variant={'body1'} mx={'auto'} color={'textPrimary'}>{backtest.pattern?.id}</Typography>
                                            <Typography variant="body2" color={'textSecondary'}>Runner:</Typography>
                                            <Typography variant={'body1'} mx={'auto'} color={'textPrimary'}>{backtest.gcid}</Typography>
                                            <Typography variant="body2" color={'textSecondary'}>Owner:</Typography>
                                            <Typography variant={'body1'} mx={'auto'} color={'textPrimary'}>{backtest.backtestSettings?.gcid}</Typography>
                                        </Box>
                                    </Box>
                                </Box>

                            </React.Fragment>
                        }
                      >
                        <IconButton size={'small'} onClick={() => console.log(backtest)} >
                          <AdminPanelSettings color={'warning'} fontSize={'small'} />
                        </IconButton>
                      </CustomWidthTooltip>
                    }

                </Box>

                <Box display={"flex"} gap={1}>
                    <CreateAlertActionChip backtest={backtest} size={condensed ? 'small' : 'medium'}/>
                    <ViewBacktestSettingsActionChip backtest={backtest} size={condensed ? 'small' : 'medium'} />
                    <ViewPatternActionChip backtest={backtest} size={condensed ? 'small' : 'medium'} />
                </Box>
            </Box>
        </Box>
    )
}


export default BacktestReportCard;
