import PhoneInput, {CountryData} from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import React, {useContext} from "react";
import {useTheme} from "@mui/material/styles";
import parsePhoneNumberFromString, {CountryCode, parsePhoneNumber} from "libphonenumber-js";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import {GreenChartTooltip} from "../../common/components/GreenChartTooltip";
import {Typography} from "@mui/material";
import {useHttpsCallable} from "react-firebase-hooks/functions";
import {firebase_functions} from "../../common/firebaseConfig";
import {SnackbarHelperContext} from "../../contexts/SnackbarHelperContext";
import VerificationInput from "react-verification-input";

function PhoneNumberInput({phoneNumber: initialPhoneNumber, onPhoneNumberChange}: {phoneNumber?: string, onPhoneNumberChange: (value: string) => void}) {
    const theme = useTheme()

    const { setSnackbarSettings } = useContext(SnackbarHelperContext);

    const [phoneNumber, setPhoneNumber] = React.useState<string>("");
    const [tempPhoneNumber, setTempPhoneNumber] = React.useState<string>("");

    const [canSendVerificationCode, setCanSendVerificationCode] = React.useState<boolean>(false);
    const [sendingVerificationCode, setSendingVerificationCode] = React.useState<boolean>(false);
    const [awaitingUserInputVerificationCode, setAwaitingUserInputVerificationCode] = React.useState<boolean>(false);
    const [verifyingPhoneNumber, setVerifyingPhoneNumber] = React.useState<boolean>(false);
    const [verificationCode, setVerificationCode] = React.useState<string>("");

    const [sendphonenumberverificationcode] = useHttpsCallable(
        firebase_functions,
        'sendphonenumberverificationcode'
    );

    const [verifyverificationcode] = useHttpsCallable(
        firebase_functions,
        'verifyverificationcode'
    );

    const [updateTraderAccountDetails] = useHttpsCallable(
        firebase_functions,
        'updateTraderAccountDetails'
    );



    React.useEffect(() => {
        const phoneNumber = parsePhoneNumberFromString(initialPhoneNumber || '', "US");

        if (phoneNumber && phoneNumber.isValid()) {
            setTempPhoneNumber(phoneNumber.formatNational());
            setPhoneNumber(phoneNumber.formatNational());
            setCanSendVerificationCode(false);
            setVerifyingPhoneNumber(false);
            setAwaitingUserInputVerificationCode(false);
            setVerificationCode("");
        }
    }, [initialPhoneNumber]);

    function handlePhoneNumberChange(value: string, country: CountryData) {
        const countryCode = country?.countryCode?.toUpperCase() as CountryCode || "US";
        setTempPhoneNumber(value);

        if (isValidPhoneNumber(value, countryCode) && !isPhoneNumberVerified(value)) {
            setCanSendVerificationCode(true);
            setVerifyingPhoneNumber(false);
            setAwaitingUserInputVerificationCode(false);
            setVerificationCode("");
        } else {
            setCanSendVerificationCode(false);
        }
    }

    function handlePhoneNumberOnBlur() {
        if (!isValidPhoneNumber(tempPhoneNumber, "US")) {
            setTempPhoneNumber(phoneNumber || "");
            setCanSendVerificationCode(false);
        }
    }

    function isValidPhoneNumber(value: string, countryCode: CountryCode | string = "US") {
        const phoneNumber = parsePhoneNumberFromString(value, countryCode.toUpperCase() as CountryCode);
        return phoneNumber && phoneNumber.isValid();
    }

    function isPhoneNumberVerified(value: string = tempPhoneNumber) {
        if (!phoneNumber || !value || phoneNumber.trim() === "" || value.trim() === "") {
            return false;
        }

        try {
            return parsePhoneNumber(value, "US").isEqual(parsePhoneNumber(phoneNumber, "US"));
        } catch (error) {
            return false;
        }
    }

    async function onSendVerificationCode() {
        if (canSendVerificationCode) {
            try {
                setSendingVerificationCode(true);

                const parsedPhoneNumber = parsePhoneNumber(tempPhoneNumber, "US");
                const verificationCodeResponse: any = await sendphonenumberverificationcode({phoneNumber: parsedPhoneNumber.number});

                if (verificationCodeResponse && verificationCodeResponse.data?.error) {
                    setSnackbarSettings({severity: 'error', message: `Error: ${verificationCodeResponse.error}`, autoHideDuration: 6000})
                } else {
                    setSnackbarSettings({severity: 'success', message: `Verification code sent`, autoHideDuration: 6000})
                    setAwaitingUserInputVerificationCode(true);
                }
            } catch (error) {
                setSnackbarSettings({severity: 'error', message: `Error sending verification code`, autoHideDuration: 6000})
            } finally {
                setSendingVerificationCode(false);
            }
        }
    }

    async function onVerifyPhoneNumber(verificationCode: string) {
        if (verifyingPhoneNumber) {
            return;
        }

        try {
            setVerifyingPhoneNumber(true);
            const verifyPhoneNumberResponse: {data: {valid: boolean, value?: string}} = await verifyverificationcode({code: verificationCode}) as any;

            if (verifyPhoneNumberResponse?.data?.valid) {
                try {
                    await updateTraderAccountDetails({alertPhoneNumber: {number: verifyPhoneNumberResponse.data.value!, verified: true}})
                } catch (error) {}

                onPhoneNumberChange(verifyPhoneNumberResponse.data.value!);

                setAwaitingUserInputVerificationCode(false);
                setCanSendVerificationCode(false);
                setSnackbarSettings({severity: 'success', message: `Phone number verified`, autoHideDuration: 6000})
            } else {
                setSnackbarSettings({severity: 'error', message: `Invalid verification code`, autoHideDuration: 6000})
            }
        } catch (error) {
            setSnackbarSettings({severity: 'error', message: `Error verifying phone number`, autoHideDuration: 6000})
        } finally {
            setVerificationCode("");
            setVerifyingPhoneNumber(false);
        }
    }

  return (
      <>
          <Box display={'flex'} flexDirection={'column'} width={'100%'}>
              <Box display="flex" alignItems="center" width={'100%'}>
                  <GreenChartTooltip title={isPhoneNumberVerified() ? "Verified!" : ""} placement={"top"} arrow>
                      <Box width={'100%'}>
                          <PhoneInput
                              country={"us"}
                              value={tempPhoneNumber}
                              onChange={handlePhoneNumberChange}
                              onBlur={handlePhoneNumberOnBlur}
                              preferredCountries={["us", "ca"]}
                              onlyCountries={["us", "ca"]}
                              disableCountryGuess={true}
                              disableDropdown={true}
                              disableCountryCode={true}
                              buttonStyle={{
                                  backgroundColor: theme.palette.background.custom4,
                                  border: `1px solid ${theme.palette.divider}`,
                              }}
                              inputStyle={{
                                  width: "100%",
                                  height: "40px",
                                  borderRadius: "0px",
                                  border: `1px solid ${theme.palette.divider}`,
                                  outline: "none",
                                  backgroundColor: "transparent",
                                  color: theme.palette.text.primary,
                              }}
                          />
                      </Box>
                  </GreenChartTooltip>
                  {
                      canSendVerificationCode && !awaitingUserInputVerificationCode && <Button
                        variant={'outlined'}
                        sx={{fontSize: 12, height: 40, lineHeight: 'normal', borderTopLeftRadius: 0, borderBottomLeftRadius: 0}}
                        onClick={onSendVerificationCode}
                        disabled={sendingVerificationCode}
                      >
                          {sendingVerificationCode ? 'Sending...' : "Send Code"}
                      </Button>
                  }
                  <style>
                      {`
                     
                        .VerificationInput-character-input {
                            height: 40px;
                            line-height: 40px;
                            font-size: 24px;
                        }
                      `}
                  </style>
                  {awaitingUserInputVerificationCode && <VerificationInput
                      containerProps={{
                            style: {
                                height: '40px',
                                gap: '0px',
                            }
                      }}
                      value={verificationCode}
                      onChange={setVerificationCode}
                      inputProps={{
                          disabled: verifyingPhoneNumber,
                      }}
                      classNames={{
                            character: 'VerificationInput-character-input',
                      }}
                      length={6}
                      onComplete={onVerifyPhoneNumber}
                  />
                  }
              </Box>
              <Box display={'flex'} alignItems={'center'} width={'100%'} justifyContent={'center'}>
                  {canSendVerificationCode && !sendingVerificationCode && !awaitingUserInputVerificationCode && <Typography variant={'caption'} textAlign={'center'} color={"warning"}>Click 'send code' to verify your phone number</Typography>}
                  {verifyingPhoneNumber && <Typography variant={'caption'} textAlign={'center'} color={"textSecondary"}>Verifying code...</Typography>}
              </Box>
          </Box>

      </>

  );
}

export default PhoneNumberInput;
