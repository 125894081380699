import Box from "@mui/material/Box";
import {FormControl, InputLabel, MenuItem, Select, TextField, Typography} from "@mui/material";
import React, {useEffect, useState} from "react";
import "./ChartDetailInput.css"

type ChartDetailInputProps = {
    details: {symbol: string, periodicity: number, timeframe: string}
    handleChange: (key: string, value: any) => void;
    flexDirection?: "row" | "column";
    onEdit?: (isEditing: boolean) => void;
    children?: React.ReactNode;
    style?: React.CSSProperties;
}

export function ChartDetailInput(props: ChartDetailInputProps) {
    const {details, handleChange, flexDirection = "row", onEdit, children, style} = props;

    const [isEditing, setIsEditing] = useState(false);
    const [isFocused, setIsFocused] = useState(false);

    useEffect(() => {
        if (onEdit) {
            onEdit(isEditing);
        }

        let timerId: string | number | NodeJS.Timeout | undefined;
        if (!isFocused && isEditing) {
            timerId = setTimeout(() => setIsEditing(false), 200);
        }
        return () => clearTimeout(timerId);
    }, [isFocused, isEditing, onEdit]);

    return <>
        <Box onClick={() => setIsEditing(true)}
             onFocus={() => setIsFocused(true)}
             onBlur={() => setIsFocused(false)}
             tabIndex={0}
             style={style}
        >
            {
                isEditing ?
                        <Box display={"flex"} gap={4} flexDirection={flexDirection}>
                            {/*<FormControl variant="standard" style={{ marginRight: 8}} fullWidth>*/}
                            {/*    <InputLabel>Symbol</InputLabel>*/}
                            {/*    <Select*/}
                            {/*        name={"symbol"}*/}
                            {/*        value={details.symbol}*/}
                            {/*        onChange={(e) => handleChange('symbol', e.target.value)}*/}
                            {/*    >*/}
                            {/*        <MenuItem value="EURUSD">EURUSD</MenuItem>*/}
                            {/*        <MenuItem value="AUDUSD">AUDUSD</MenuItem>*/}
                            {/*        <MenuItem value="GBPUSD">GBPUSD</MenuItem>*/}
                            {/*    </Select>*/}
                            {/*</FormControl>*/}

                            <TextField
                                fullWidth
                                label="Periodicity"
                                variant={"standard"}
                                type={"number"}
                                value={details.periodicity}
                                name={"periodicity"}
                                onChange={(e) => {
                                    e.stopPropagation()
                                    const value = parseInt(e.target.value);
                                    if (!isNaN(value)) {
                                        if (details.timeframe === 'hour' && value >= 1 && value <= 24) {
                                            handleChange('periodicity', value);
                                        } else if (details.timeframe === 'minute' && value >= 1 && value <= 60) {
                                            handleChange('periodicity', value);
                                        }
                                    }
                                }}
                                style={{ marginRight: 8 }}
                            />

                            <FormControl variant="standard" style={{ marginRight: 8}} fullWidth>
                                <InputLabel>Timeframe</InputLabel>
                                <Select
                                    name={"timeframe"}
                                    value={details.timeframe}
                                    onChange={(e) => {
                                        const newTimeframe = e.target.value;
                                        handleChange('timeframe', newTimeframe);

                                        // Modify periodicity to the max of the new timeframe range if out of range
                                        const currentPeriodicity = details.periodicity;
                                        if (!isNaN(currentPeriodicity)) {
                                            if (newTimeframe === 'hour' && (currentPeriodicity < 1 || currentPeriodicity > 24)) {
                                                handleChange('periodicity', '24');
                                            } else if (newTimeframe === 'minute' && (currentPeriodicity < 1 || currentPeriodicity > 60)) {
                                                handleChange('periodicity', '60');
                                            }
                                        }
                                    }}
                                >
                                    <MenuItem value="minute">Minute</MenuItem>
                                    <MenuItem value="hour">Hour</MenuItem>
                                </Select>
                            </FormControl>
                            {children}
                        </Box>
                    : <Box display={"flex"} className={"chart-details-text"} gap={.8} alignItems={"center"}>
                        <Typography variant={"body2"}>{`${details.periodicity} ${details.timeframe}`}</Typography>
                        {children && <Box fontSize=".875rem">{children}</Box>}
                    </Box>
            }

        </Box>
    </>


}
