import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import {Button, ClickAwayListener, Popper} from "@mui/material";
import React, {useState} from "react";
import {useTheme} from "@mui/material/styles";
import Box from "@mui/material/Box";

type FilterButtonProps = {
    label: React.ReactElement | string;
    menu: React.ReactNode;
}

function FilterButton(props: FilterButtonProps) {
    const theme = useTheme();

    const {
        label,
        menu,
    } = props;

    const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);

    const handleClick = (event: React.MouseEvent<HTMLElement>) => {
        if (anchorEl) {
            setAnchorEl(null);
        } else {
            setAnchorEl(event.currentTarget);
        }
    };

    const handleClose = () => {
        if (anchorEl) {
            setAnchorEl(null);
        }
    };

    const open = Boolean(anchorEl);

    return (
        <>
            <ClickAwayListener onClickAway={handleClose} mouseEvent="onMouseUp">
                <Box width={'100%'}>
                    <Button
                        variant="outlined"
                        onClick={handleClick}
                        sx={{
                            '&:hover': {
                                borderColor: 'white',
                                backgroundColor: 'transparent',
                            },
                            color: 'rgba(255, 255, 255, 0.7)', borderColor: 'rgba(255, 255, 255, 0.23)', height: 40, width: '100%', pr: 1, justifyContent: 'space-between' }}
                    >
                        {label}
                        {open ? <ArrowDropUpIcon /> : <ArrowDropDownIcon />}
                    </Button>

                    <Popper
                        open={open}
                        anchorEl={anchorEl}
                        placement={'bottom-start'}
                        sx={{
                            zIndex: 1000,
                        }}
                    >
                        <Box sx={{
                            backgroundColor: theme.palette.background.custom2,
                            borderBottomLeftRadius: 8,
                            borderBottomRightRadius: 8,
                        }}>
                            {menu}
                        </Box>
                    </Popper>
                </Box>
            </ClickAwayListener>
        </>
    )
}

export default FilterButton;
