import {
    Box,
    Button,
    ClickAwayListener,
    MenuItem,
    Popper,
    Select,
    Typography
} from "@mui/material";
import React, {useState} from "react";
import SortComponentParams from "./types/SortComponentParams";
import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import FilterHeaderSection from "../filters/components/FilterHeaderSection";
import {useTheme} from "@mui/material/styles";

function SortSelect(props: SortComponentParams) {

    const {
        backtestSort,
        setBacktestSort
    } = props;

    const theme = useTheme();

    const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);

    const handleClick = (event: React.MouseEvent<HTMLElement>) => {
        if (anchorEl) {
            setAnchorEl(null);
        } else {
            setAnchorEl(event.currentTarget);
        }
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleApply = () => {
        setBacktestSort((prev: any) => ({
            ...prev,
            field: backtestSort.field,
            direction: backtestSort.direction
        }))
        handleClose();
    };

    const getLabel = () => {
       const option = options.find((option) => option.value === backtestSort.field);


       if (option) {
           return <Typography variant="body2" color={"textPrimary"}>Sort: {option.label}</Typography>
       } else {
           return 'Sort By';
       }
    }

    const options = [
        {
            label: "Newest",
            value: 'startTime',
        },
        {
            label: 'Grade',
            value: 'summary.grades.grade',
        },
        {
            label: 'Profit %',
            value: 'summary.maxProfitPercentage',
        },
        {
            label: 'Absolute Drawdown',
            value: 'summary.minAbsoluteDrawdownPercentage',
        },
        {
            label: 'Relative Drawdown',
            value: 'summary.minRelativeDrawdownPercentage',
        },
    ]

    const open = Boolean(anchorEl);
    const id = open ? 'sort-select' : undefined;

    return (
        <>
            <ClickAwayListener onClickAway={handleClose}
                mouseEvent={"onMouseUp"}
            >
                <Box width={'100%'}>
                    <Button
                        aria-describedby={id}
                        variant="outlined"
                        onClick={handleClick}
                        sx={{
                            '&:hover': {
                                borderColor: 'white',
                                backgroundColor: 'transparent',
                            },
                            color: 'rgba(255, 255, 255, 0.7)', borderColor: 'rgba(255, 255, 255, 0.23)',  height: 40, width: '100%', pr: 1, justifyContent: 'space-between' }}
                    >
                        {getLabel()}
                        {open ? <ArrowDropUpIcon /> : <ArrowDropDownIcon />}
                    </Button>

                    <Popper
                        id={id}
                        open={open}
                        anchorEl={anchorEl}
                        placement={'bottom-start'}
                    >
                        <Box sx={{
                            backgroundColor: theme.palette.background.custom2,
                            borderBottomLeftRadius: 8,
                            borderBottomRightRadius: 8,
                        }}>
                            <FilterHeaderSection
                                title={"Sort Backtests"}
                            />

                            <Box p={2}>
                                <Box display="flex" justifyContent="space-between" my={2}>
                                    <Typography variant="body1" color={"textPrimary"}>Sort By</Typography>
                                    <Typography variant="body1" color={"textPrimary"}>Direction</Typography>
                                </Box>

                                <Box display="flex" alignItems="center" justifyContent="space-between" my={2} gap={2}>
                                    <Select
                                        fullWidth
                                        value={backtestSort.field}
                                        onChange={(e) => {
                                            setBacktestSort((prev: any) => ({
                                                ...prev,
                                                field: e.target.value
                                            }))
                                        }}
                                    >
                                        {options.map((option) => (
                                            <MenuItem key={option.value} value={option.value}>
                                                {option.label}
                                            </MenuItem>
                                        ))}
                                    </Select>

                                    <Select
                                        fullWidth
                                        value={backtestSort.direction}
                                        onChange={(e) => {
                                            setBacktestSort((prev: any) => ({
                                                ...prev,
                                                direction: e.target.value
                                            }))
                                        }}
                                    >
                                        <MenuItem value={'asc'}>Ascending</MenuItem>
                                        <MenuItem value={'desc'}>Descending</MenuItem>
                                    </Select>
                                </Box>
                            </Box>

                            <Box p={2}>
                                <Button variant="contained" fullWidth onClick={handleApply}>
                                    Apply
                                </Button>
                            </Box>
                        </Box>
                    </Popper>
                </Box>
            </ClickAwayListener>

        </>
    )
}

export default SortSelect;
