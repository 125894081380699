import {useCallback, useEffect, useRef, useState} from "react";

type PaginatedLoaderParams = {
    queryFunction: (params: { take?: number; skip?: number; }) => Promise<any>;
    take?: number;
    initialSkip?: number;
    initialData?: any[];
}

// Utility function to create a loadMore function
function usePaginatedLoader({
                                queryFunction,
                                take = 30,
                                initialSkip = 0,
                                initialData = [],
                            }: PaginatedLoaderParams) {

    const [data, setData] = useState(initialData);
    const page = useRef(0);
    const count = useRef(0);
    const loading = useRef(true);
    const requestId = useRef(0);
    const abortControllerRef = useRef<AbortController | null>(null);

    const loadMore = useCallback(() => {
        if (loading.current) return; // Avoid multiple requests

        requestId.current += 1; // Increment request ID
        const currentRequestId = requestId.current; // Save current request ID
        loading.current = true; // Start loading

        // Cancel previous request if any
        if (abortControllerRef.current) {
            abortControllerRef.current.abort(); // Cancel the previous request
        }
        abortControllerRef.current = new AbortController(); // Create new AbortController for the new request

        // Call the provided query function
        queryFunction({
            take,
            skip: page.current * take,
        })
            .then((result: any) => {
                // Check if the request is stale
                if (currentRequestId !== requestId.current) return;

                if (!result?.data) {
                    return;
                }

                const newData = result.data.data;
                if (newData.length) {
                    setData(prevData => [...prevData, ...newData]); // Append new data
                }
                count.current = result.data.count; // Set total count
                page.current += 1; // Increment page
            })
            .catch((error: any) => {
                if (error.name === 'AbortError') {
                    console.log('Request aborted'); // Handle request cancellation
                } else {
                    console.error(error);
                }
            })
            .finally(() => {
                loading.current = false; // Finish loading
            });
    }, [queryFunction, take]);

    useEffect(() => {
        if (!queryFunction) return;

        const handler = setTimeout(() => {
            setData([]);
            page.current = 0;
            count.current = 0;
            loading.current = false;
            requestId.current = 0;
            abortControllerRef.current?.abort();
            loadMore();
        }, 300);

        return () => {
            clearTimeout(handler); // Clear the timeout if the queryFunction changes before debounceDelay
        };

    }, [queryFunction, loadMore]); // Reset when queryFunction changes

    return {
        data,
        loadMore,
        totalCount: count.current,
        isLoading: loading.current,
    };
}

export default usePaginatedLoader;
